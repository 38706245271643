import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Container from "../../components/Container/Container";
import {Button, Form, Grid, Input, InputNumber, Modal, Row, Space, Typography} from "antd";
import CurrencySelector from "../../components/CurrencySelector/CurrencySelector";
import {getUser} from "../../store/selectors";
import {connect, useDispatch} from "react-redux";
import usdtCoin from "../../assets/svg/usdt_coin.svg";
import {CURRENCY, TRANSACTION_TYPE, VERIFICATION_STATUS} from "../../utils/constants";
import {getTradeInfo, sendCrypto} from "../../store/actions/trades";
import {ROUTES} from "../../components/AppRouter/AppRouter";
import {useNavigate} from "react-router-dom";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../schemas";
import {isProfileFilled} from "../../utils/helpers";
import useTransaction from "../../hooks/useTransaction";

const sendCurrencies =  [{value: CURRENCY.USDT, label: CURRENCY.USDT, src: usdtCoin}];

const Send = ({isUserVerified, user, userMeta}) => {
	const [tradeData, setTradeData] = useState({
		fee: null,
		course: null,
		min_sum: null,
		max_sum: null,
	});

	const [form] = Form.useForm();
	const payCurrency = Form.useWatch('currency', form);
	const [modal, contextHolder] = Modal.useModal();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const breakpoints = Grid.useBreakpoint();
	const {onFormSubmitSuccess, loading} = useTransaction(user, (currency, sum, to_wallet) => {
		dispatch(sendCrypto(currency, sum, to_wallet))
	}, form);

	const onFinish = useCallback((formData) => {
		onFormSubmitSuccess(TRANSACTION_TYPE.SEND, formData.currency, formData.amount, { wallet: formData.wallet });
	}, [onFormSubmitSuccess]);

	const isProfileCompleted = useMemo(() => {
		return isProfileFilled(userMeta);
	}, [userMeta]);

	useEffect(() => {
		dispatch(getTradeInfo(TRANSACTION_TYPE.SEND, {toState: false})).then(setTradeData);
	}, []);
	console.log({tradeData})
	const modalConfig = useMemo(() => ({
		keyboard: false,
		width: 700,
		centered: true,
		maskClosable: false,
		closable: false,
		footer: [
			<Row justify="end">
				<Button key="submit" onClick={() => {
					navigate(ROUTES.PROFILE);
				}} type="text">OK</Button>
			</Row>
		],
		title: isUserVerified ? 'Please fill in the information in your account' : 'Confirm your account',
		content: isUserVerified
			? 'In order to proceed further, it\'s required to complete your profile information. Please fill out all the necessary fields in your profile.'
			: 'Once your account has been verified, you\'ll be able to buy and sell ' +
			'cryptocurrency with confidence, knowing that your account has been fully vetted and approved.' +
			' If you have any questions or need assistance with the verification process, please don\'t hesitate to contact our support team for help.'
	}), [isProfileCompleted, isUserVerified]);

	useEffect(() => {
		// if (!isUserVerified || !isProfileCompleted) modal.warning(modalConfig);
	}, [])

	return (
		<Container>
			{contextHolder}
			<Typography.Title level={breakpoints.md ? 1 : 3}>Send crypto (TRC20)</Typography.Title>
			<Form validateMessages={validateMessages} disabled={!(isUserVerified && isProfileCompleted)} validateTrigger="onChange" initialValues={{
				currency: sendCurrencies[0].value,
			}} form={form} size={breakpoints.md ? 'large' : 'small'} labelCol={{span: 3}} labelAlign="left" onFinish={onFinish} labelWrap>
				<Form.Item rules={GENERAL_SCHEME.required} label="Pay" name="amount">
					<InputNumber className="w100" placeholder={`${tradeData.min_sum ?? '-/-'} USDT - ${tradeData.max_sum ?? '-/-'} USDT`} addonAfter={<CurrencySelector arr={sendCurrencies} name="currency"/>}/>
				</Form.Item>
				<Form.Item rules={[...FIELDS_SCHEME.wallet, ...GENERAL_SCHEME.required]} label="To wallet" name="wallet">
					<Input maxLength={40} placeholder="Direction wallet"/>
				</Form.Item>
				<Row justify="center">
					<Button loading={loading} type="primary" onClick={form.submit}>Send {payCurrency}</Button>
				</Row>
			</Form>
		</Container>
	);
};
const mapStateToProps = state => ({
	isUserVerified: getUser(state).confirm.sumsub_status === VERIFICATION_STATUS.VERIFIED,
	userMeta: getUser(state).meta,
	user: getUser(state),
})
export default connect(mapStateToProps)(Send);
