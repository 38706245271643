import React, {useCallback, useEffect, useState} from 'react';
import classes from './ResetPassword.module.scss';
import {Button, Form, Grid, Input, message, Row, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import Container from "../../components/Container/Container";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../schemas";
import {API} from "../../http";
import {NEW_ENDPOINTS} from "../../http/endpoints";
import {ROUTES} from "../../components/AppRouter/AppRouter";

const ResetPassword = () => {
	const [email, setEmail] = useState('');

	const [form] = Form.useForm();
	const navigate = useNavigate();
	const breakpoints = Grid.useBreakpoint();
	const [messageApi, contextHolder] = message.useMessage();

	const onEmailFormSubmit = useCallback(async ({email}) => {
		try {
			const {data} = await API.post(NEW_ENDPOINTS.AUTH.RESET_PASS, {email});
			if (data.res === true) {
				setEmail(email);
				sessionStorage.setItem('restoreEmail', email);
			} else {
				let msg = 'Something went wrong';
				if (data.err === 'USER NOT FOUND') msg = 'User not found';
				messageApi.error(msg);
			}
		} catch (e) {
			messageApi.error('Something went wrong');
			throw e;
		}
	}, []);

	const onPassFormSubmit = useCallback(async (formData) => {
		try {
			const {data} = await API.post(NEW_ENDPOINTS.AUTH.RESET_PASS_CONFIRM, {
				code: formData.code,
				email,
				password: formData.newPassword
			});
			if (data.res === true) {
				sessionStorage.removeItem('restoreEmail');
				navigate(ROUTES.SIGN_IN);
			} else {
				let msg = 'Something went wrong';
				if (data.err === 'CODE DOES NOT MATCH') msg = 'Wrong code';
				messageApi.error(msg);
			}
		} catch (e) {
			messageApi.error('Something went wrong');
			throw e;
		}
	}, [email]);

	useEffect(() => {
		(() => {
			const restoreEmail = sessionStorage.getItem('restoreEmail');
			if (restoreEmail) setEmail(restoreEmail);
		})();

		return () => sessionStorage.removeItem('restoreEmail');
	}, [])

	return (
		<Container className={classes.container}>
			{contextHolder}
			<Typography.Title level={breakpoints.md ? 1 : 3}>Restore password</Typography.Title>
			{email ? (
				<Form validateMessages={validateMessages} validateTrigger="onBlur" style={{maxWidth: 410}}
				      className="w100" layout="vertical" form={form} preserve={false} size="large" onFinish={onPassFormSubmit}>
					<Typography.Paragraph type="secondary" style={{textAlign: 'center'}}>
						The recovery code has been sent to <Typography.Link
						href={`mailto:${email}`}>{email}</Typography.Link>. Please check your mailbox and enter the code
						in the field below
					</Typography.Paragraph>
					<Form.Item label="Code" name="code"
					           rules={[{required: true}, {pattern: /\b\d{6}\b/g, message: 'Invalid format'}]}>
						<Input maxLength={6} placeholder="Recovery code"/>
					</Form.Item>
					<Form.Item label="New password" name="newPassword" rules={[...FIELDS_SCHEME.password, ...GENERAL_SCHEME.required]}>
						<Input.Password placeholder="********"/>
					</Form.Item>
					<Form.Item dependencies={['newPassword']} label="Confirm password" name="confirmNewPassword"
					           rules={[...[...FIELDS_SCHEME.password, ...GENERAL_SCHEME.required], ({getFieldValue}) => ({
						           validator(_, value) {
							           if (!value || getFieldValue('newPassword') === value) {
								           return Promise.resolve();
							           }
							           return Promise.reject(new Error('Passwords don\'t match'));
						           },
					           })]}>
						<Input.Password placeholder="********"/>
					</Form.Item>
					<Row justify="center">
						<Button style={{maxWidth: 200}} onClick={form.submit} type="primary" size="large">Change
							password</Button>
					</Row>
				</Form>
			) : (
				<Form validateMessages={validateMessages} validateTrigger="onBlur" style={{maxWidth: 410}}
				      className="w100" layout="vertical" form={form} preserve={false} size="large" onFinish={onEmailFormSubmit}>
					<Form.Item label="Email" name="email" rules={[...FIELDS_SCHEME.email, ...GENERAL_SCHEME.required]}>
						<Input placeholder="email@example.com"/>
					</Form.Item>
					<Row justify="center">
						<Button style={{maxWidth: 200}} onClick={form.submit} type="primary" size="large">Send
							recovery code</Button>
					</Row>
				</Form>
			)}

		</Container>
	);
};

export default ResetPassword;
