import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form, InputNumber, Space, Typography} from "antd";
import CurrencySelector from "../../../../components/CurrencySelector/CurrencySelector";
import usdtCoin from "../../../../assets/svg/usdt_coin.svg";
import euroCoin from "../../../../assets/svg/euro_coin.svg";
import {GENERAL_SCHEME, validateMessages} from "../../../../schemas";
import {formatNumberPrecision} from "../../../../utils/helpers";
import {CURRENCY, TRANSACTION_TYPE} from "../../../../utils/constants";
import useTransaction from "../../../../hooks/useTransaction";

const exchangeToArr = [{value: "USDT", label: "USDT", src: usdtCoin}];
const exchangeFromArr = [{value: "EUR", label: "EUR", src: euroCoin}];

const BuyForm = ({isAvailable, user, getTradeInfo, onSubmit}) => {
    const [tradeData, setTradeData] = useState({
        fee: null,
        course: null,
        min_sum: null,
        max_sum: null,
    });

    const [form] = Form.useForm();
    const {onFormSubmitSuccess, loading} = useTransaction(user, onSubmit, form);
    const receiveCurrency = Form.useWatch('receiveCurrency', form);
    const payCurrency = Form.useWatch('payCurrency', form);

    useEffect(() => {
        if (receiveCurrency && payCurrency) getTradeInfo(TRANSACTION_TYPE.BUY, {toState: false}).then(setTradeData);
    }, [receiveCurrency, payCurrency]);

    const onFinish = useCallback((formData) => {
        onFormSubmitSuccess(TRANSACTION_TYPE.BUY, CURRENCY.USDT, formData.receiveAmount);
    }, [onFormSubmitSuccess]);

    const onFormFieldsChange = useCallback((field, allFields) => {
        const changedField = field[0];

        if (changedField.name[0] === 'payCurrency' || changedField.name[0] === 'receiveCurrency') {
            form.resetFields(['payAmount', 'receiveAmount']);
        } else if (changedField.name[0] === 'payAmount' && changedField.value >= 0) {
            const calculatedReceiveAmount = formatNumberPrecision(changedField.value * tradeData.course, 3);
            form.setFieldValue('receiveAmount', Math.max(calculatedReceiveAmount, tradeData.min_sum));
        } else if (changedField.name[0] === 'receiveAmount' && changedField.value >= 0) {
            const calculatedPayAmount = formatNumberPrecision(changedField.value / tradeData.course, 3);
            form.setFieldValue('payAmount', Math.min(calculatedPayAmount, tradeData.max_sum));
        }
    }, [tradeData]);

    return (
        <Form validateMessages={validateMessages}
              disabled={(tradeData.min_sum === 0 && tradeData.max_sum === 0) || !isAvailable} validateTrigger="onChange"
              onFieldsChange={onFormFieldsChange} initialValues={{
            payCurrency: exchangeFromArr[0].value,
            receiveCurrency: exchangeToArr[0].value,
        }} form={form} size="large" layout="horizontal" labelAlign="left" onFinish={onFinish} labelWrap>
            <Form.Item rules={GENERAL_SCHEME.required} label="Pay" name="payAmount">
                <InputNumber className="w100" max={tradeData.max_sum / tradeData.course}
                             min={tradeData.min_sum / tradeData.course}
                             precision={2}
                             placeholder={`${formatNumberPrecision(tradeData.min_sum / tradeData.course, 2)} - ${formatNumberPrecision(tradeData.max_sum / tradeData.course, 2)}`}
                             addonAfter={<CurrencySelector arr={exchangeFromArr} name="payCurrency"/>}/>
            </Form.Item>
            <Form.Item rules={GENERAL_SCHEME.required} label="Receive" name="receiveAmount">
                <InputNumber className="w100" max={tradeData.max_sum} min={tradeData.min_sum}
                             precision={2} placeholder={`${tradeData.min_sum} - ${tradeData.max_sum}`}
                             addonAfter={<CurrencySelector arr={exchangeToArr} name="receiveCurrency"/>}/>
            </Form.Item>
            <Space className="w100" direction="vertical" align="center">
                <Button loading={loading} type="primary"
                        onClick={form.submit}>Buy {form.getFieldValue('receiveCurrency')}</Button>
                <Typography.Text>Exchange fee: {tradeData.fee}%</Typography.Text>
            </Space>
        </Form>
    );
}

export default BuyForm;
