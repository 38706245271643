import React, {useCallback, useState} from 'react';
import {Layout, Row, Space, Grid, Typography, Drawer, Divider, Button} from "antd";
import classes from './NavBar.module.scss';
import Icon, {CloseOutlined, MenuOutlined} from '@ant-design/icons';
import {LogoFull} from "../../assets/svg/logo";
import {LogoShort} from "../../assets/svg/logo-mobile";
import {ROUTES} from "../AppRouter/AppRouter";
import {Link, useNavigate} from "react-router-dom";
import {getUser} from "../../store/selectors";
import {connect} from "react-redux";
import {usePersistor} from "../../hooks/usePersistor";

const LogoLarge = (props) => <Icon component={LogoFull} {...props} />;
const LogoSmall = (props) => <Icon style={{ width: 30, height: 32 }} component={LogoShort} {...props} />;

const navLinks = [
	// { route: ROUTES.HOME, label: 'Services' },
	{ route: ROUTES.CONTACTS, label: 'Contacts' },
	{ route: ROUTES.SUPPORT, label: 'Support' },
	{ route: ROUTES.PROFILE, label: 'Profile' },
];


const NavBar = ({wallet}) => {
	const [drawerOpen, setDrawerOpen] = useState(false);

	const navigate = useNavigate();
	const breakpoints = Grid.useBreakpoint();
	const persistor = usePersistor();

	const onLinkClick = useCallback((route) => {
	    navigate(route);
		setDrawerOpen(false);
	}, []);

	return (
		<Layout.Header className={classes.container}>
			<Row style={{ height: '100%' }} align="middle">
				{breakpoints.md ? <LogoLarge onClick={() => navigate(ROUTES.HOME)}/> : <LogoSmall onClick={() => navigate(ROUTES.HOME)}/>}
				{Boolean(wallet) && (
					<div style={{ marginLeft: 'auto' }}>
						{breakpoints.md ? (
							<Space size="large">
								{navLinks.map(link => (
									<Typography.Text key={link.route} style={{ cursor: 'pointer' }} onClick={() => navigate(link.route)}>{link.label.toUpperCase()}</Typography.Text>
								))}
							</Space>
						) : (
							<React.Fragment>
								<MenuOutlined onClick={() => setDrawerOpen(true)} className={classes.hamburgerIcon} />
								<Drawer closeIcon={<CloseOutlined style={{ color: '#fff', fontSize: 24 }} />} width="100%" className={classes.drawerBodyStyle} placement="right" onClose={() => setDrawerOpen(false)} open={drawerOpen}>
									<Space className="w100" direction="vertical" size="small">
										{navLinks.map(link => (
											<Typography.Title key={link.route} level={breakpoints.md ? 4 : 5} className={classes.navTitles} onClick={() => onLinkClick(link.route)}>{link.label.toUpperCase()}</Typography.Title>
										))}
										{!breakpoints.md && (
											<Button type="ghost" onClick={() => persistor.purge()} block>Log out</Button>
										)}
									</Space>
								</Drawer>
							</React.Fragment>
						)}

					</div>
				)}
			</Row>
		</Layout.Header>
	);
};
const mapStateToProps = state => ({
	wallet: getUser(state).address,
})
export default connect(mapStateToProps)(NavBar);
