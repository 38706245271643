import {useLocation} from "react-router-dom";
import {Col, Grid, Layout, Row} from "antd";
import {connect, useDispatch} from "react-redux";
import React, {useEffect, useMemo} from "react";
import AppRouter, {ROUTES} from "../AppRouter/AppRouter";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import SideBar from "../SideBar/SideBar";
import {getUser} from "../../store/selectors";

const routesWithSidebar = [ROUTES.FIAT_EXCHANGE, ROUTES.HISTORY, ROUTES.SURVEY, ROUTES.SEND, ROUTES.HOME, ROUTES.EXCHANGE, ROUTES.RECEIVE];

const AppContent = ({user}) => {
	const location = useLocation();
	const breakpoints = Grid.useBreakpoint();

	const isWithSidebar = useMemo(() => {
	    return routesWithSidebar.includes(location.pathname);
	}, [location]);

	return (
		<div className="app-wrapper">
			<Layout className="app-content">
				<NavBar/>
				<Layout.Content>
					<Row gutter={[30, 10]}>
						{isWithSidebar && <Col xs={24} md={6}>
							<SideBar user={user}/>
						</Col>}
						<Col xs={24} md={isWithSidebar ? 18 : 24}><AppRouter/></Col>
					</Row>

				</Layout.Content>
				{![ROUTES.SIGN_IN, ROUTES.SIGN_UP].includes(location.pathname) && (
					<Footer/>
				)}
			</Layout>
		</div>
	)
}
const mapStateToProps = state => ({
	user: getUser(state),
})
export default connect(mapStateToProps)(AppContent);
