import axios from "axios";
import {persistor} from "../store/store";
import {message} from "antd";

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

const suffix = '/api/v3';
const $host = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL + suffix,
	timeout: 20000,
	headers: {
		'Content-Type': 'application/json',
	},
});

class RequestBlocker {
	constructor() {
		this.isBlocked = false;
	}

	block() {
		this.isBlocked = true;
	}

	unblock() {
		this.isBlocked = false;
	}

	isRequestBlocked() {
		return this.isBlocked;
	}
}

export const requestBlocker = new RequestBlocker();

$host.interceptors.request.use(
	(config) => {
		if (requestBlocker.isRequestBlocked()) {
			return Promise.reject(new Error("Requests are blocked due to unauthorized access."));
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

$host.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response && (error.response.status === UNAUTHORIZED || error.response.status === FORBIDDEN)) {
			requestBlocker.block();
			persistor.purge();
			// message.error("Unauthorized access! Please log in again.");
			requestBlocker.unblock();
		}
		return Promise.reject(error);
	}
);

const API = {
	get: async (...params) => {
		try {
			const request = await $host.get(...params);
			return { data: request.data, headers: request.headers, status: request.status };
		} catch (error) {
			console.error({error});
			throw error.response;
		}
	},
	post: async (...params) => {
		try {
			const request = await $host.post(...params);
			return { data: request.data, headers: request.headers, status: request.status };
		} catch (error) {
			console.error({error});
			throw error.response;
		}
	},
	put: async (...params) => {
		try {
			const request = await $host.put(...params);
			return { data: request.data, headers: request.headers, status: request.status };
		} catch (error) {
			console.error(error);
			throw error.response;
		}
	},
	delete: async (...params) => {
		try {
			const request = await $host.delete(...params);
			return { data: request.data, headers: request.headers, status: request.status };
		} catch (error) {
			console.error(error);
			throw error.response;
		}
	},
	setKey: key => {
		$host.defaults.headers.common.token = key;
	},
	getKey: () => {
		return $host.defaults.headers.common.token;
	},
};

export {
	API,
};
