import React, {useCallback, useEffect} from 'react';
import Container from "../../../components/Container/Container";
import {Grid, Space, Typography} from "antd";
import CodeInput from "../../../components/CodeInput/CodeInput";
import {checkConfirmationCode, sendConfirmationCode} from "../actions";
import {connect, useDispatch} from "react-redux";
import {getUserFullInfo} from "../../../store/actions/user";
import {getTradeInfo} from "../../../store/actions/trades";

const ConfirmPhone = () => {
	const dispatch = useDispatch();
	const breakpoints = Grid.useBreakpoint();

	useEffect(() => {
		sendConfirmationCode();
	}, []);

	const checkCode = useCallback((code) => {
		dispatch(checkConfirmationCode('sms', code)).then(() => {
			dispatch(getUserFullInfo());
			try {
				dispatch(getTradeInfo());
			} catch (tradeError) {
				console.error("Error fetching trade info:", tradeError);
			}
		})
	}, []);

	return (
		<Container>
			<Space align="center" className="w100" direction="vertical">
				<Typography.Title level={breakpoints.md ? 1 : 3}>Phone verification</Typography.Title>
				<Typography.Paragraph style={{textAlign: 'center'}} type="secondary">
					We have sent you SMS with code
				</Typography.Paragraph>
				<CodeInput onResend={sendConfirmationCode} onCodeEntered={checkCode}/>
			</Space>
		</Container>
	);
};

export default ConfirmPhone;
