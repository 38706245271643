import React from 'react';
import classes from './PlusIcon.module.scss';
import {PlusOutlined} from "@ant-design/icons";
import {Button, ConfigProvider} from "antd";
import {combineClasses} from "../../utils/helpers";

const PlusIcon = (props) => {
    return (
        <ConfigProvider theme={{
            components: {
                Button: {
                    onlyIconSizeSM: 8,
                    paddingInlineSM: 2,
                    fontSize: 8,
                    lineHeight: 1,
                    controlHeightSM: 15,
                }
            }
        }}>
            <Button {...props} className={combineClasses(classes.container, props.className)} icon={<PlusOutlined/>} size="small" shape="circle" />
        </ConfigProvider>
    );
};
PlusIcon.propTypes = {
    ...Button.propTypes,
}
export default PlusIcon;
