import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Input, Row, Space} from "antd";
import classes from './CodeInput.module.scss';
import {RiDeleteBack2Line} from "react-icons/ri";
import {useTimer} from "../../hooks/useTimer";

const CodeInput = ({codeLength = 6, onResend, onCodeEntered}) => {
	const [code, setCode] = useState("");

	const [timer, resetTimer] = useTimer(60, 1);
	const inputRef = useRef(null);

	const codeDigitsArray = useMemo(() => {
	    return new Array(codeLength).fill(0);
	}, [codeLength]);

	useEffect(() => {
		if (code.length === codeLength) {
			onCodeEntered(code);
			setTimeout(() => setCode(""), 500);
		}
	}, [code])

	useEffect(() => {
		if (inputRef.current) inputRef.current.focus({cursor: 'start'});
	}, []);

	const handleChange = useCallback((e) => {
		const newValue = e.target.value.replace(/[^\d]/g, "");
		if (newValue.length <= codeLength) setCode(newValue);
	}, []);

	const resendCode = useCallback(() => {
	    onResend();
		resetTimer();
	}, []);

	return (
		<div className={classes.container}>
			<Input value={code} rootClassName={classes.input} onChange={handleChange} ref={inputRef} placeholder="Confirmation code"/>
			<Row justify="center">
				<Space>
					{codeDigitsArray.map((item, index) => (
						<div onClick={() => inputRef.current.focus()} className={classes.digit}>{code[index]}</div>
					))}
				</Space>
			</Row>
			<Row justify="center" style={{ marginTop: 20 }}>
				<Button onClick={resendCode} disabled={timer > 0} size="small" type="text" style={{ fontSize: 12, zIndex: 1001 }}>Resend code{timer > 0 && ` (${timer})`}</Button>
			</Row>
		</div>

	);
};

export default CodeInput;
