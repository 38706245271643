export const fundsSource = {
    0: {
        name: 'Salary',
    },
    1: {
        name: 'Self-Employed',
    },
    2: {
        name: 'Social benefits / pension',
    },
    3: {
        name: 'Gifts',
    },
    4: {
        name: 'Inheritance',
    },
    5: {
        name: 'Business ownership income',
    },
    6: {
        name: 'Income from real-estate',
    },
    7: {
        name: 'Loans',
    },
    8: {
        name: 'Insurance payments',
    },
    9: {
        name: 'Lottery or gambling winning',
    },
    10: {
        name: 'Other',
    }
};

export const wealthSource = {
    0: {
        name: 'Employment',
    },
    1: {
        name: 'Business ownership',
    },
    2: {
        name: 'Savings',
    },
    3: {
        name: 'Real Estate',
    },
    4: {
        name: 'Intellectual property',
    },
    5: {
        name: 'Other',
    },
}
