import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "../../pages/Home/Home";
import Profile from "../../pages/Profile/Profile";
import SignIn from "../../pages/SignIn/SignIn";
import SignUp from "../../pages/SignUp/SignUp";
import {getUser} from "../../store/selectors";
import {connect} from "react-redux";
import Contacts from "../../pages/Contacts/Contacts";
import Support from "../../pages/Support/Support";
import Exchange from "../../pages/Exchange/Exchange";
import Send from "../../pages/Send/Send";
import Receive from "../../pages/Receive/Receive";
import TransactionHistory from "../../pages/TransactionHistory/TransactionHistory";
import SignUpBusiness from "../../pages/SignUpBusiness/SignUpBusiness";
import ConfirmEmail from "../../pages/Confirmation/ConfirmEmail/ConfirmEmail";
import ConfirmPhone from "../../pages/Confirmation/ConfirmPhone/ConfirmPhone";
import ResetPassword from "../../pages/ResetPassword/ResetPassword";
import Survey from "../../pages/Survey/Survey";
import FiatExchange from "../../pages/FiatExchange/FiatExchange";

export const ROUTES = Object.freeze({
	HOME: "/",
	SIGN_IN: "/auth/signin",
	SIGN_UP: "/auth/signup",
	// SIGN_UP_BUSINESS: "/auth/signup/business",
	CONFIRM_EMAIL: "/auth/confirm/email",
	CONFIRM_PHONE: "/auth/confirm/phone",
	RESTORE_PASS: '/auth/restore_password',
	PROFILE: '/user/profile',
	CONTACTS: '/contacts',
	SUPPORT: '/support',
	EXCHANGE: '/exchange',
	SEND: '/send',
	RECEIVE: '/receive',
	HISTORY: '/history',
	SURVEY: '/survey',
	FIAT_EXCHANGE: '/fiat_exchange',
});
const authorizedRoutes = Object.freeze([
	{path: ROUTES.HOME, element: <Home/>},
	{path: ROUTES.CONTACTS, element: <Contacts/>},
	{path: ROUTES.SUPPORT, element: <Support/>},
	{path: ROUTES.PROFILE, element: <Profile/>},
	{path: ROUTES.EXCHANGE, element: <Exchange/>},
	{path: ROUTES.SEND, element: <Send/>},
	{path: ROUTES.RECEIVE, element: <Receive/>},
	{path: ROUTES.HISTORY, element: <TransactionHistory/>},
	{path: ROUTES.SURVEY, element: <Survey/>},
	{path: ROUTES.FIAT_EXCHANGE, element: <FiatExchange/>},
]);
const unauthorizedRoutes = Object.freeze([
	{path: ROUTES.SIGN_IN, element: <SignIn/>},
	{path: ROUTES.SIGN_UP, element: <SignUp/>},
	{path: ROUTES.RESTORE_PASS, element: <ResetPassword/>},
	// {path: ROUTES.SIGN_UP_BUSINESS, element: <SignUpBusiness/>},
	{path: ROUTES.CONFIRM_EMAIL, element: <ConfirmEmail/>},
	{path: ROUTES.CONFIRM_PHONE, element: <ConfirmPhone/>},
]);

const AppRouter = ({authKey, cryptoAddress, emailConfirm, phoneConfirm}) => {
	return (
		<Routes>
			{Boolean(authKey) && Boolean(cryptoAddress) && emailConfirm && phoneConfirm ? (
				<React.Fragment>
					{authorizedRoutes.map(route => (
						<Route key={route.path} path={route.path} element={route.element} exact />
					))}
					<Route path="*" element={<Navigate to={ROUTES.HOME} />}/>
				</React.Fragment>

			) : (
				<React.Fragment>
					{unauthorizedRoutes.map(route => (
						<Route key={route.path} path={route.path} element={route.element} exact />
					))}
					<Route path="*" element={<Navigate to={ROUTES.SIGN_IN} />}/>
				</React.Fragment>
			)}
		</Routes>
	);
};

const mapStateToProps = state => ({
	authKey: getUser(state).authKey,
	emailConfirm: getUser(state).confirm.email_confirm,
	phoneConfirm: getUser(state).confirm.phone_confirm,
	cryptoAddress: getUser(state).address,
})
export default connect(mapStateToProps)(AppRouter);
