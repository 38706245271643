import React from 'react';
import './css/App.css';
import {persistor, store} from "./store/store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {ConfigProvider} from "antd";
import {HashRouter} from "react-router-dom";
import {theme} from "./utils/constants";
import AppContent from "./components/AppContent/AppContent";

export const PersistorContext = React.createContext(null);

function App() {
  return (
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <PersistorContext.Provider value={persistor}>
                  <ConfigProvider theme={theme}>
                      <HashRouter>
                          <AppContent/>
                      </HashRouter>
                  </ConfigProvider>
              </PersistorContext.Provider>
          </PersistGate>
      </Provider>
  );
}

export default App;
