import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Container from "../../components/Container/Container";
import {Button, Divider, Form, Grid, message, notification, Row, Space, Typography} from "antd";
import PersonalInformationSection from "./sections/PersonalInformationSection";
import AddressSection from "./sections/AddressSection";
import {useLocation, useNavigate} from "react-router-dom";
import TaxSection from "./sections/TaxSection";
import SelectSection from "./sections/SelectSection";
import FinancialSection from "./sections/FinancialSection";
import AddInfoSection from "./sections/AddInfoSection";
import {API} from "../../http";
import {NEW_ENDPOINTS} from "../../http/endpoints";
import {connect, useDispatch} from "react-redux";
import {AML_STATUS} from "../../utils/constants";
import {ROUTES} from "../../components/AppRouter/AppRouter";
import {getUser} from "../../store/selectors";
import {fetchCountries} from "../../utils/fetchCountries";

const Survey = ({aml_status}) => {
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [formDataInit, setFormDataInit] = useState({});
    const [countries, setCountries] = useState([]);

    const breakpoints = Grid.useBreakpoint();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sendForm = async (formData) => {
        try {
            const {data: { data: { updated, status } }} = await API.post(NEW_ENDPOINTS.USER.AML_SEND, formData);
            dispatch({ type: 'User/SET', payload: { confirm: { aml_status: status } } });
            switch (status) {
                case AML_STATUS.APPROVED:
                    message.success('Your form has been approved');
                    break;
                case AML_STATUS.REJECTED:
                    message.error('Your form has been rejected.');
                    break;
                case AML_STATUS.MANUAL:
                    message.info('Your form is under manual review');
                    break;
                default:
                    message.error('Unknown status');
            }
            return { updated, status };
        } catch (e) {
            console.error(e);
        }
    };


    const onFormFinish = useCallback((name, { values }) => {
        setFormDataInit({...formDataInit, ...values})
        const transformedData = Object.entries({ ...formData, ...values }).reduce((acc, [key, value]) => {
            if (typeof value === 'undefined') return acc;
            if (value && typeof value.format === 'function') {
                acc[key] = value.format('YYYY-MM-DD');
            } else {
                acc[key] = value;
            }

            return acc;
        }, {});
        setFormData(transformedData);
        if (name === 'step5') sendForm(transformedData).then(() => navigate(ROUTES.PROFILE));
        else if (step !== totalSteps - 1) setStep(step + 1);
    }, [step, formData]);

    const goBackStep = () => {
        setStep(step -1)
    }
    const resetStep = () => {
        setStep(0)
    }

    const steps = useMemo(() => ({
        0: {
            title: "Personal Information",
            component: <PersonalInformationSection countries={countries} resetStep={resetStep} formDataInit={formDataInit}/>,
        },
        1: {
            title: "Registered address and contact details",
            component: <AddressSection goBackStep={goBackStep} resetStep={resetStep} formDataInit={formDataInit}/>,
        },
        2: {
            title: "Person Tax Residence",
            component: <TaxSection countries={countries} goBackStep={goBackStep} resetStep={resetStep} formDataInit={formDataInit}/>,
        },
        3: {
            title: "",
            component: <SelectSection countries={countries} citizenship={formData.citizenship} resetStep={resetStep} goBackStep={goBackStep} formDataInit={formDataInit}/>,
        },
        4: {
            title: "Financial information",
            component: <FinancialSection goBackStep={goBackStep} formDataInit={formDataInit} resetStep={resetStep}/>,
        },
        5: {
            title: "Additional information",
            component: <AddInfoSection countries={countries} goBackStep={goBackStep} resetStep={resetStep} formDataInit={formDataInit}/>,
        },
    }), [countries, formData, step, formDataInit]);
    const totalSteps = useMemo(() => Object.keys(steps).length, [steps]);

    useEffect(() => {
        fetchCountries().then(setCountries);
    }, []);

    useEffect(() => {
        if (aml_status !== AML_STATUS.NOT_CHECKED) navigate(ROUTES.PROFILE);
    }, [aml_status]);
 
    return (
        <Container>
            <Typography.Title level={breakpoints.md ? 2 : 4}>Questionnaire</Typography.Title>
            <Divider orientation="center">{steps[step].title}</Divider>
            <Form.Provider onFormFinish={onFormFinish}>
                {steps[step].component}
            </Form.Provider>
        </Container>
    );
};
const mapStateToProps = state => ({
    aml_status: getUser(state).confirm.aml_status
});
export default connect(mapStateToProps)(Survey);
