import React, {useCallback, useState} from 'react';
import {Button, Col, Divider, Form, Grid, Image, Input, InputNumber, QRCode, Row, Space, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import Container from "../../components/Container/Container";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../schemas";
import {getUser} from "../../store/selectors";
import eurIcon from '../../assets/svg/euro_coin.svg';
import {sendFiat} from "../../store/actions/trades";
import siteLogo from '../../assets/svg/logo-mobile.svg';

const FiatExchange = ({user}) => {
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const breakpoints = Grid.useBreakpoint();

    const onFormSubmitSuccess = useCallback(({iban, amount}) => {
        setLoading(true);
        dispatch(sendFiat(iban, amount)).finally(() => {
            setLoading(false);
            form.resetFields();
        });
    }, []);
    const sepaQRDataUrl = encodeURIComponent(`BCD\n002\n1\nSCT\n${user.fiat_topup_bic}\n${user.fiat_topup_name}\n${user.fiat_topup_iban}\n\n\n\n\n${user.fiat_topup}`);

    return (
        <Container>
            <Typography.Title level={breakpoints.md ? 1 : 3}>Fiat Currency</Typography.Title>
            <Divider orientation="left">Deposit</Divider>
            <Space size="large" direction="vertical">
                <Space direction="vertical">
                    <Typography.Text><span style={{ fontWeight: 'bold' }}>Beneficiary:</span> <Typography.Text keyboard copyable>{user.fiat_topup_name ?? '-/-'}</Typography.Text></Typography.Text>
                    <Typography.Text><span style={{ fontWeight: 'bold' }}>IBAN:</span> <Typography.Text keyboard copyable>{user.fiat_topup_iban ?? '-/-'}</Typography.Text></Typography.Text>
                    <Typography.Text><span style={{ fontWeight: 'bold' }}>BIC:</span> <Typography.Text keyboard copyable>{user.fiat_topup_bic ?? '-/-'}</Typography.Text></Typography.Text>
                    <Typography.Text><span style={{ fontWeight: 'bold' }}>Reference:</span> <Typography.Text keyboard copyable>{user.fiat_topup ?? '-/-'}</Typography.Text></Typography.Text>
                </Space>

                <Typography.Paragraph type="secondary">Please transfer the desired amount in EUR to the above bank details. <Typography.Text type="secondary" underline>It's essential to include REFERENCE in the payment description.</Typography.Text> Ensure this text is entered correctly to facilitate a smooth deposit process. Once the transfer is completed, the funds will be credited to your fiat account.</Typography.Paragraph>

                <Row style={{ marginBottom: 20 }} justify="center"><QRCode errorLevel="H" icon={siteLogo} value={sepaQRDataUrl}/></Row>
            </Space>

            <Divider orientation="left">Send</Divider>

            <Form style={{ maxWidth: 600 }} validateMessages={validateMessages} disabled={loading} validateTrigger="onChange" form={form}
                  size="large" layout="vertical" onFinish={onFormSubmitSuccess}>
                <Form.Item rules={[...FIELDS_SCHEME.iban, ...GENERAL_SCHEME.required]} label="IBAN" name="iban">
                    <Input maxLength={34} placeholder="Beneficiary IBAN" size="large"/>
                </Form.Item>
                <Form.Item rules={GENERAL_SCHEME.required} label="Amount" name="amount">
                    <InputNumber addonAfter={<Image preview={false} width={32} height={32} src={eurIcon} alt="currency"/>} className="w100" placeholder="Enter amount" min={0.01}/>
                </Form.Item>

                <Row justify="center">
                    <Button loading={loading} onClick={form.submit}>Send FIAT</Button>
                </Row>
            </Form>
        </Container>
    );
};
const mapStateToProps = state => ({
    user: getUser(state),
})
export default connect(mapStateToProps)(FiatExchange);
