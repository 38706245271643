import React, {useCallback, useEffect, useLayoutEffect} from 'react';
import {Grid, Space, Typography} from "antd";
import Container from "../../../components/Container/Container";
import {getUser} from "../../../store/selectors";
import {connect, useDispatch} from "react-redux";
import CodeInput from "../../../components/CodeInput/CodeInput";
import {checkConfirmationCode, sendConfirmationCode} from "../actions";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../components/AppRouter/AppRouter";

const ConfirmEmail = ({email}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const breakpoints = Grid.useBreakpoint();

	useEffect(() => {
		sendConfirmationCode();
	}, []);

	const checkCode = useCallback((code) => {
		dispatch(checkConfirmationCode('email', code)).then(() => navigate(ROUTES.CONFIRM_PHONE));
	}, [dispatch]);

	return (
		<Container>
			<Space align="center" className="w100" direction="vertical">
				<Typography.Title level={breakpoints.md ? 1 : 3}>Email verification</Typography.Title>
				<Typography.Paragraph style={{textAlign: 'center'}} type="secondary">
					We have sent you an email with verification code to<br/><Typography.Text type="secondary" strong>{email}</Typography.Text>
				</Typography.Paragraph>
				<CodeInput onResend={sendConfirmationCode} onCodeEntered={checkCode}/>
			</Space>
		</Container>
	);
};
const mapStateToProps = state => ({
	email: getUser(state).email,
});

export default connect(mapStateToProps)(ConfirmEmail);
