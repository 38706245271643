import {API} from "../../../http";
import {NEW_ENDPOINTS} from "../../../http/endpoints";
import {message} from "antd";

export const signUp = ({email, phone, password, country, city, zip_code}) => async (dispatch) => {
	try {
		const {data} = await API.post(NEW_ENDPOINTS.AUTH.REG, {login: email, phone, password, country, city, zip_code});
		if (data.token) {
			// success reg
			API.setKey(data.token);
			dispatch({ type: 'User/SET', payload: { email, authKey: data.token } });

		} else {
			switch (data.err) {
				case 'NO UINIQ ': throw new Error('User is already exists');
				default: throw new Error('Something went wrong');
			}
		}
	} catch (e) {
		if (e instanceof Error) message.error(e.message);
		else message.error("Registration Error");
		throw e;
	}
}
