export const fetchCountries = async () => {
    try {
        const response = await fetch('https://restcountries.com/v3.1/currency/eur?fields=name,cca2,cca3,flags');
        const countries = await response.json();

        return countries.sort((a, b) => {
            const nameA = a.name.common.toUpperCase();
            const nameB = b.name.common.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    } catch (error) {
        console.error(error);
        return [];
    }
}
