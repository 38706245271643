import {API} from "../http";
import {getTradeInfo} from "../store/actions/trades";
import {getUserFullInfo, getUserInfo} from "../store/actions/user";

export const initMiddleware = ({dispatch, getState}) => next => async action => {
	if (action.type === 'persist/REHYDRATE') {
		const {payload} = action;
		if (payload) {
			if (payload?.authKey) API.setKey(payload?.authKey);
			if (payload?.meta?.id) {
				for (const fn of [getTradeInfo, getUserFullInfo]) dispatch(fn());
			}
		}
	}
	return next(action);
};
