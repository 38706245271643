import React, {forwardRef, useCallback,} from 'react';
import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select, Space} from "antd";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../../schemas";
import {IS_DEV} from "../../../utils/constants";

const defaultInitialValues = {
    declaredAddress: 'testAddress',
    correspondenceAddress: 'testAddress',
    phone: '32312312',
    email: 'testemail@email.com',
}

const AddressSection = forwardRef(({footer, initialValues, goBackStep, formDataInit, resetStep, ...props}, ref) => {
    const [form] = Form.useForm();

    const resetFields = () => form.resetFields();
    const resetForm = () => {
        resetFields();
        resetStep()
    }

    return (
        <Form ref={ref} initialValues={formDataInit ? formDataInit : initialValues ? initialValues : IS_DEV && defaultInitialValues} validateMessages={validateMessages} layout="vertical" size="large" form={form} validateTrigger="onChange" name="step1" {...props}>
            {/*ADDRESS SECTION*/}
            <Row gutter={20}>
                <Col span={24}>
                    <Form.Item label="Declared address" rules={[...GENERAL_SCHEME.alphaNumericSymbolSpace, ...GENERAL_SCHEME.required]} name="declaredAddress">
                        <Input placeholder="Declared address" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Correspondence (residential physical) address (if differs from Declared address)" rules={GENERAL_SCHEME.alphaNumericSymbolSpace} name="correspondenceAddress">
                        <Input placeholder="Correspondence (residential physical) address (if differs from Declared address)" />
                    </Form.Item>
                </Col>
            </Row>

            {/*EMAIL/PHONE SECTION*/}
            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item label="Telephone" rules={[...FIELDS_SCHEME.phoneNumber, ...GENERAL_SCHEME.required]} name="phone">
                        <Input placeholder="Telephone" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="E-mail" rules={[...FIELDS_SCHEME.email, ...GENERAL_SCHEME.required]} name="email">
                        <Input placeholder="E-mail" />
                    </Form.Item>
                </Col>
            </Row>
            {footer ? footer({ form }) : (
                <Row justify="end">
                    <Space wrap="wrap">
                        <Button type="text" onClick={resetForm}>
                            Reset
                        </Button>
                        {/*<Button onClick={}>*/}
                        {/*    Back*/}
                        {/*</Button>*/}
                        <Button onClick={goBackStep}>
                            Prev
                        </Button>
                        <Button onClick={form.submit}>
                            Next
                        </Button>
                    </Space>
                </Row>
            )}
        </Form>
    );
});

export default AddressSection;
