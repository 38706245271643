import {API} from "../../../http";
import {NEW_ENDPOINTS} from "../../../http/endpoints";
import {message} from "antd";

export const sendConfirmationCode = async () => {
	try {
		await API.post(NEW_ENDPOINTS.CONFIRM.SEND);
		message.success('Code has been sent');
	} catch (e) {
		if (e && typeof e.data === 'string') {
			message.error(`Failed to send verification code (${e.status}: ${e.data})`);
		} else if (e && typeof e.statusText === 'string') {
			message.error(`Failed to send verification code (${e.status}: ${e.statusText})`);
		} else {
			message.error('Failed to send verification code');
		}
		throw e;
	}
}
export const checkConfirmationCode = (type, code) => async (dispatch) => {
	try {
		const {data} = await API.post(NEW_ENDPOINTS.CONFIRM.GET, { type, code });
		if (data.token) {
			API.setKey(data.token);
			dispatch({ type: 'User/SET', payload: { authKey: data.token } });
		} else throw new Error(data);
	} catch (e) {
		if (e instanceof Error) message.error(e.message);
		else message.error("Verification code validation failed");
		throw e;
	}
}
