export const LogoShort = () => (
	<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="13.4121" cy="13.4156" r="10.9756" fill="#4F41F5"/>
		<circle cx="37.8018" cy="13.4156" r="10.9756" fill="#4F41F5"/>
		<circle cx="62.1963" cy="13.4156" r="10.9756" fill="url(#paint0_linear_950_6556)"/>
		<circle cx="13.4121" cy="37.8052" r="10.9756" fill="#4F41F5"/>
		<circle cx="37.8018" cy="37.8052" r="10.9756" fill="url(#paint1_linear_950_6556)"/>
		<circle cx="62.1963" cy="37.8052" r="10.9756" fill="url(#paint2_linear_950_6556)"/>
		<circle cx="86.5908" cy="37.8052" r="10.9756" fill="#4F41F5"/>
		<circle cx="13.4121" cy="62.1948" r="10.9756" fill="#4F41F5"/>
		<circle cx="37.8018" cy="62.1948" r="10.9756" fill="url(#paint3_linear_950_6556)"/>
		<circle cx="62.1963" cy="62.1948" r="10.9756" fill="url(#paint4_linear_950_6556)"/>
		<circle cx="86.5908" cy="62.1948" r="10.9756" fill="#4F41F5"/>
		<circle cx="13.4121" cy="86.5845" r="10.9756" fill="#4F41F5"/>
		<circle cx="37.8018" cy="86.5845" r="10.9756" fill="url(#paint5_linear_950_6556)"/>
		<circle cx="62.1963" cy="86.5845" r="10.9756" fill="#4F41F5"/>
		<circle cx="86.5908" cy="86.5845" r="10.9756" fill="#4F41F5"/>
		<circle cx="86.5908" cy="13.4156" r="10.9756" fill="#4F41F5"/>
		<defs>
			<linearGradient id="paint0_linear_950_6556" x1="51.2207" y1="13.216" x2="73.1719" y2="13.216" gradientUnits="userSpaceOnUse">
				<stop stopColor="#4E3EF4"/>
				<stop offset="0.483929" stopColor="#5DBDF8"/>
				<stop offset="1" stopColor="#B1F6D5"/>
			</linearGradient>
			<linearGradient id="paint1_linear_950_6556" x1="26.8262" y1="37.6056" x2="48.7774" y2="37.6056" gradientUnits="userSpaceOnUse">
				<stop stopColor="#4E3EF4"/>
				<stop offset="0.483929" stopColor="#5DBDF8"/>
				<stop offset="1" stopColor="#B1F6D5"/>
			</linearGradient>
			<linearGradient id="paint2_linear_950_6556" x1="51.2207" y1="37.6056" x2="73.1719" y2="37.6056" gradientUnits="userSpaceOnUse">
				<stop stopColor="#4E3EF4"/>
				<stop offset="0.483929" stopColor="#5DBDF8"/>
				<stop offset="1" stopColor="#B1F6D5"/>
			</linearGradient>
			<linearGradient id="paint3_linear_950_6556" x1="26.8262" y1="61.9953" x2="48.7774" y2="61.9953" gradientUnits="userSpaceOnUse">
				<stop stopColor="#4E3EF4"/>
				<stop offset="0.483929" stopColor="#5DBDF8"/>
				<stop offset="1" stopColor="#B1F6D5"/>
			</linearGradient>
			<linearGradient id="paint4_linear_950_6556" x1="51.2207" y1="61.9953" x2="73.1719" y2="61.9953" gradientUnits="userSpaceOnUse">
				<stop stopColor="#4E3EF4"/>
				<stop offset="0.483929" stopColor="#5DBDF8"/>
				<stop offset="1" stopColor="#B1F6D5"/>
			</linearGradient>
			<linearGradient id="paint5_linear_950_6556" x1="26.8262" y1="86.3849" x2="48.7774" y2="86.3849" gradientUnits="userSpaceOnUse">
				<stop stopColor="#4E3EF4"/>
				<stop offset="0.483929" stopColor="#5DBDF8"/>
				<stop offset="1" stopColor="#B1F6D5"/>
			</linearGradient>
		</defs>
	</svg>
)
