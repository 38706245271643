import React from 'react';
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Divider,
	Form,
	Input, Popover,
	Radio,
	Row,
	Select,
	Space,
	Tooltip,
	Typography
} from "antd";
import {InfoCircleOutlined, MinusCircleOutlined} from "@ant-design/icons";

const popoverContent = (
	<ul>
		<li>The individual has a USA citizenship or a valid residence card (Green Card)</li>
		<li>The individual’s tax residence country is in the USA</li>
		<li>The individual was born in the USA</li>
		<li>The legal entity has a phone with the USA country code The legal entity is registered in the USA</li>
		<li>The residential or correspondence address is in USA (including PO Box)</li>
		<li>A Power of Attorney has been issued to an individual/legal entity with an address in the USA</li>
	</ul>
)
const popoverContent2 = (
	<div style={{ maxWidth: '90%' }}>
		<Typography.Paragraph>A Politically Exposed Person (PEP) is: A natural person who has been or is entrusted with a prominent public function by the state (local or foreign), by a community institution or an international body.</Typography.Paragraph>
		<Typography.Paragraph>An immediate family member of this person including a spouse, partner, children and their spouses and parents. </Typography.Paragraph>
		<Typography.Paragraph>Known close associates of this person who may include any individual known to have joint beneficial ownership of a legal entity or legal arrangement or other close business relations with persons, or who may have sole beneficial ownership of a legal entity or legal arrangement set up for the benefit of this person.</Typography.Paragraph>
	</div>

)

const DirectorsSection = () => {
	const [form] = Form.useForm();
	const directors = Form.useWatch('directors', form);

	return (
		<React.Fragment>
			<Typography.Title level={4}>Directors</Typography.Title>
			<Form layout="vertical" form={form} size="large" name="step4Form">
				<Form.List name="directors">
					{(fields, { add, remove }, { errors }) => (
						<>
							{fields.map((field, index) => (
								<>
									<Row gutter={10} key={field.key}>
										<Col>
											<Typography.Title level={5}>Directors / Authorised signatories</Typography.Title>
											<Form.Item {...field} name={[field.name, 'type']}>
												<Radio.Group>
													<Radio value={0}>Director</Radio>
													<Radio value={1}>Authorised signatory</Radio>
													<Radio value={2}>Signatory on behalf of  corporate director</Radio>
												</Radio.Group>
											</Form.Item>
											<Form.Item {...field} name={[field.name, "fullName"]}>
												<Input placeholder="Full name"/>
											</Form.Item>
											<Form.Item {...field} name={[field.name, "dateOfBirth"]}>
												<DatePicker className="w100" placeholder="Date of birth"/>
											</Form.Item>
											<Form.Item {...field} name={[field.name, "birthCountry"]}>
												<Input placeholder="Country of birth"/>
											</Form.Item>
											<Form.Item {...field} name={[field.name, "birthCitizenship"]}>
												<Input placeholder="Country of citizenship"/>
											</Form.Item>

											<Typography.Title level={5}>Address</Typography.Title>
											<Form.Item {...field} name={[field.name, "directorCountry"]}>
												<Input placeholder="Country" />
											</Form.Item>
											<Form.Item style={{ marginBottom: 0 }}>
												<Row gutter={10}>
													<Col span={12}>
														<Form.Item {...field} name={[field.name, "directorCity"]}>
															<Input placeholder="City" />
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item {...field} name={[field.name, "directorPostCode"]}>
															<Input placeholder="Post code" />
														</Form.Item>
													</Col>
												</Row>
											</Form.Item>
											<Form.Item {...field} name={[field.name, "directorAddress"]}>
												<Input placeholder="Address" />
											</Form.Item>

											<Typography.Title level={5}>ID</Typography.Title>
											<Form.Item {...field} name={[field.name, "idDocument"]}>
												<Input placeholder="Passport / ID number" />
											</Form.Item>
											<Form.Item style={{ marginBottom: 0 }}>
												<Row gutter={10}>
													<Col span={12}>
														<Form.Item {...field} name={[field.name, "expDate"]}>
															<Input placeholder="Expiry date" />
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item {...field} name={[field.name, "idAuthority"]}>
															<Input placeholder="Authority" />
														</Form.Item>
													</Col>
												</Row>
											</Form.Item>

											<Typography.Title level={5}>Contacts</Typography.Title>
											<Form.Item style={{ marginBottom: 0 }}>
												<Row gutter={10}>
													<Col span={8}>
														<Form.Item {...field} name={[field.name, "phoneCode"]} rules={[{ required: true }]}>
															<Select placeholder="Code" options={[{value: 0, label: '123'}, {value: 1, label: '123'}]}/>
														</Form.Item>
													</Col>
													<Col span={16}>
														<Form.Item {...field} name={[field.name, "phoneNumber"]}>
															<Input placeholder="Phone number" />
														</Form.Item>
													</Col>
												</Row>
											</Form.Item>
											<Form.Item {...field} name={[field.name, "directorEmail"]}>
												<Input placeholder="E-mail" />
											</Form.Item>

											<Form.Item {...field} name={[field.name, "personHas"]} label="Person has:">
												<Radio.Group>
													<Radio value={0}>Sole signatory rights</Radio>
													<Radio value={1}>Joint signatory rights</Radio>
												</Radio.Group>
											</Form.Item>
											<Form.Item {...field} name={[field.name, "personRequires"]} label="Person requires internet-bank access:">
												<Radio.Group>
													<Radio value={0}>Full mode</Radio>
													<Radio value={1}>No access required</Radio>
												</Radio.Group>
											</Form.Item>
											<Form.Item {...field} name={[field.name, "isUSA"]} label={
												<Space align="center">
													<Typography.Text>Is the individual a U.S.A. person?</Typography.Text>
													<Popover content={popoverContent} title="USA person meets any of the following criteria:">
														<InfoCircleOutlined style={{ fontSize: 24 }} />
													</Popover>
												</Space>
											}>
												<Radio.Group>
													<Radio value={0}>Yes</Radio>
													<Radio value={1}>No</Radio>
												</Radio.Group>
											</Form.Item>
											<Form.Item {...field} name={[field.name, "isPEP"]} label={
												<Space align="center">
													<Typography.Text>Is the individual a PEP person?</Typography.Text>
													<Popover content={popoverContent2} title="">
														<InfoCircleOutlined style={{ fontSize: 24 }} />
													</Popover>
												</Space>
											}>
												<Radio.Group>
													<Radio value={0}>Yes</Radio>
													<Radio value={1}>No</Radio>
												</Radio.Group>
											</Form.Item>
										</Col>
										<Col>
											{fields.length > 1 ? (
												<MinusCircleOutlined
													style={{ fontSize: 32 }}
													className="dynamic-delete-button"
													onClick={() => remove(field.name)}
												/>
											) : null}
										</Col>
									</Row>
									<Divider/>
								</>
							))}
							<Button type="default" onClick={() => add()}>Add new</Button>
						</>
					)}
				</Form.List>

				{directors?.length && <Button type="primary" onClick={form.submit}>Continue</Button>}
			</Form>
		</React.Fragment>
	);
};

export default DirectorsSection;
