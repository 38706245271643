export const professions = {
    1: {
        name: 'Commissioned Armed forces Officers',
        description: 'Commissioned armed forces officers provide leadership and management to organizational units in the armed forces and/or perform similar tasks to those performed in a variety of civilian occupations outside the armed forces. This group includes all members of the armed forces holding the rank of second lieutenant (or equivalent) or higher. Competent performance in most occupations in this sub-major group requires skills at the fourth ISCO skill level.',

    },
    11: {
        name: 'Commissioned Armed Forces Officers',
        description: 'Commissioned armed forces officers provide leadership and management to organizational units in the armed forces and/or perform similar tasks to those performed in a variety of civilian occupations outside the armed forces. This group includes all members of the armed forces holding the rank of second lieutenant (or equivalent) or higher.',

    },
    111: {
        name: 'Legislators and Senior Officials',
        description: 'Legislators and senior officials determine, formulate, advise on and direct the implementation of policies of national, state, regional or local governments or communities, and of special interest organizations. They make, ratify, amend or repeal laws, public rules and regulations and plan, organize, direct, control and evaluate the overall activities of government departments and agencies, traditional communities and special-interest organizations.',

    },
    1111: {
        name: 'Legislators',
        description: 'Legislators determine, formulate, and direct policies of national, state, regional or local governments and international governmental agencies, and make, ratify, amend or repeal laws, public rules and regulations. They include elected and non-elected members of parliaments, councils and governments.',

    },
    1112: {
        name: 'Senior Government Officials',
        description: 'Senior government officials advise governments on policy matters, oversee the interpretation and implementation of government policies and legislation by government departments and agencies, represent their country abroad and act on its behalf, or carry out similar tasks in intergovernmental organizations. They plan, organize, direct, control and evaluate the overall activities of municipal or local, regional and national government departments, boards, agencies or commissions in accordance with legislation and policies established by government and legislative bodies.',

    },
    1113: {
        name: 'Traditional Chiefs and Heads of Villages',
        description: 'Traditional chiefs and heads of villages perform a variety of legislative, administrative and ceremonial tasks and duties, determined by ancient traditions, as well as by the division of rights and responsibilities \tbetween village chiefs and the local, regional and national authorities.',

    },
    1114: {
        name: 'Senior Officials of Special-interest Organizations',
        description: "Senior officials of special-interest organizations determine, formulate and direct the implementation of policies of special-interest organizations, such as political-party organizations, trade unions, employers' organizations, trade and industry associations, humanitarian or charity organizations, or sports associations, and represent their organizations and act on their behalf.",

    },
    112: {
        name: 'Managing Directors and Chief Executives',
        description: 'Managing directors and chief executives formulate and review the policies and plan, direct, coordinate and evaluate the overall activities of enterprises or organizations (except special-interest organizations and government departments) with the support of other managers, usually within guidelines established by a board of directors or a governing body to whom they are answerable for the operations undertaken and results.',

    },
    1120: {
        name: 'Managing Directors and Chief Executives',
        description: 'Managing directors and chief executives formulate and review the policies and plan, direct, coordinate and evaluate the overall activities of enterprises or organizations (except special-interest organizations and government departments) with the support of other managers, usually within guidelines established by a board of directors or a governing body to whom they are answerable for the operations undertaken and results.',

    },
    12: {
        name: 'Administrative and Commercial Managers',
        description: 'Administrative and commercial managers plan, organize, direct, control and coordinate the financial, administrative, human resource, policy, planning, research and development, advertising, public relations, and sales and marketing activities of enterprises and organizations, or of enterprises that provide such services to other enterprises and organizations. Competent performance in most occupations in this sub-major group requires skills at the fourth ISCO skill level.',

    },
    121: {
        name: 'Business Services and Administration Managers',
        description: 'Business services and administration managers plan, organize, direct, control and coordinate the financial, administrative, human resource, policy and planning activities of organizations, or of enterprises that provide such services to other enterprises and organizations.',

    },
    1211: {
        name: 'Finance Managers',
        description: 'Finance managers plan, direct and coordinate the financial operations of an enterprise or organization, in consultation with senior managers and with managers of other departments or sections, or of enterprises that provide financial services to other enterprises and organizations.',

    },
    1212: {
        name: 'Human Resource Managers',
        description: 'Human resource managers, plan, direct and coordinate policies concerning the personnel, industrial relations and occupational health and safety activities of an enterprise or organization, or of enterprises that provide human resource services to other enterprises and organizations.',

    },
    1213: {
        name: 'Policy and Planning Managers',
        description: 'Policy and planning managers plan, organize, direct and coordinate policy advice and strategic planning activities within government or for non-government organizations and private sector agencies, or manage the activities of enterprises that provide policy and strategic planning services.',

    },
    1219: {
        name: 'Business Services and Administration Managers Not Elsewhere Classified',
        description: 'This unit group covers business services and administration managers not classified elsewhere in Minor Group 121: Business Services and Administration Managers. For instance, the group includes occupations such as facilities manager, cleaning services manager, administrative services manager employed either as the manager of a department of a large enterprise and organization, or of an enterprise that provides such services to other enterprises and organizations.',

    },
    122: {
        name: 'Sales, Marketing and Development Managers',
        description: 'Sales, marketing and development managers plan, organize, direct, control and coordinate the advertising, public relations, research and development, and sales and marketing activities of enterprises and organizations, or of enterprises that provide such services to other enterprises and organizations.',

    },
    1221: {
        name: 'Sales and Marketing Managers',
        description: 'Sales and marketing managers plan, direct and coordinate the sales and marketing activities of an enterprise or organization, or of enterprises that provide sales and marketing services to other enterprises and organizations.',

    },
    1222: {
        name: 'Advertising and Public Relations Managers',
        description: 'Advertising and public relations managers plan, direct and coordinate the advertising, public relations and public information activities of enterprises and organizations or of enterprises that provide related services to other enterprises and organizations.',

    },
    1223: {
        name: 'Research and Development Managers',
        description: 'Research and development managers plan, direct and coordinate the research and development activities of an enterprise or organization or of enterprises that provide related services to other enterprises and organizations.',

    },
    13: {
        name: 'Production and Specialized Services Managers',
        description: 'Production and specialized services managers plan direct and coordinate the production of the goods and the provision of the specialized professional and technical services provided by an enterprise or organization either as the manager of a department or as the general manager of an enterprise or organization that does not have a hierarchy of managers. They are responsible for manufacturing, mining, construction, logistics, information and communications technology operations, for large scale agricultural, forestry and fisheries operations, and for the provision of health, education, social welfare, banking, insurance and other professional and technical services. Competent performance in most occupations in this sub-major group requires skills at the fourth ISCO skill level.',

    },
    131: {
        name: 'Production Managers in Agriculture, Forestry and Fisheries',
        description: 'Production managers in agriculture, forestry and fisheries plan, direct, and coordinate production in large scale agricultural, horticultural, forestry aquaculture and fishery operations such as plantations, large ranches, collective farms and co-operatives to grow and harvest crops, breed and raise livestock, fish and shellfish and to catch and harvest fish and other forms of aquatic life.',

    },
    1311: {
        name: 'Agricultural and Forestry Production Managers',
        description: 'Agricultural and forestry production managers plan, direct, and coordinate production in large scale agricultural, horticultural and forestry operations such as plantations, large ranches, collective farms and agricultural cooperatives to grow and harvest crops, and breed and raise livestock.',

    },
    1312: {
        name: 'Aquaculture and Fisheries Production Managers',
        description: 'Aquaculture and fisheries production managers plan, direct, and coordinate production in large-scale aquaculture and fishery operations to catch and harvest fish and shellfish, and to grow fish shellfish or other forms of aquatic life, as cash crops or for release into freshwater or saltwater.',

    },
    132: {
        name: 'Manufacturing, Mining, Construction and Distribution Managers',
        description: 'Manufacturing, mining, construction, and distribution managers plan, organize, and coordinate the manufacturing, mineral extraction, construction, supply, storage and transportation operations, either as the manager of a department or as the general manager of an enterprise or organization that does not have a hierarchy of managers.',

    },
    1321: {
        name: 'Manufacturing Managers',
        description: 'Manufacturing managers plan, direct and coordinate activities concerned with the production of goods, the production and distribution of electricity gas and water, and the collection, treatment and disposal of waste. They may manage the production departments of large enterprises or be the managers of small manufacturing companies.',

    },
    1322: {
        name: 'Mining Managers',
        description: 'Mining managers plan, direct and coordinate the production of activities of mining, quarrying and oil and gas extraction operations, either as the manager of a department or as the general manager of an enterprise or organization that does not have a hierarchy of managers.',

    },
    1323: {
        name: 'Construction Managers',
        description: 'Construction managers plan, direct and coordinate the construction of civil engineering projects, buildings and dwellings, either as the manager of a department or as the general manager of an enterprise or organization that does not have a hierarchy of managers.',

    },
    1324: {
        name: 'Supply, Distribution and Related Managers',
        description: 'Supply, distribution and related managers plan, direct and coordinate passenger transportation systems and facilities and the supply, transportation, storage and distribution of goods, either as the manager of a department or as the general manager of an enterprise or organization that does not have a hierarchy of managers.',

    },
    133: {
        name: 'Information and Communications Technology Service managers',
        description: 'Information and communications technology service managers plan, direct, and coordinate the acquisition, development, maintenance and use of computer and telecommunication systems, either as the manager of a department or as the general manager of an enterprise or organization that does not have a hierarchy of managers.',

    },
    1330: {
        name: 'Information and Communications Technology Service Managers',
        description: 'Information and communications technology service managers plan, direct and coordinate the acquisition, development, maintenance and use of computer and telecommunication systems, either as the manager of a department or as the general manager of an enterprise or organization that does not have a hierarchy of managers.',

    },
    134: {
        name: 'Professional Services Managers',
        description: 'Professional services managers plan, direct and coordinate the provision of childcare, health, welfare, education and other professional services, and manage the branches of institutions providing financial and insurance services.',

    },
    1341: {
        name: 'Child Care Service Managers',
        description: 'Child care service managers plan, direct, and coordinate and evaluate the provision of care for children in before-school, after-school, vacation and day care centres and services.',

    },
    1342: {
        name: 'Health Service Managers',
        description: 'Health service managers plan, direct, coordinate and evaluate the provision of clinical and community health care services in hospitals, clinics, public health agencies and similar organizations.',

    },
    1343: {
        name: 'Aged Care Service Managers',
        description: 'Aged care service managers plan, direct coordinate and evaluate the provision of residential and personal care services for individuals and families who are in need of such services due to the effects of ageing.',

    },
    1344: {
        name: 'Social Welfare Managers',
        description: 'Social welfare managers plan, direct and coordinate the provision of social and community service programmes such as income support, family assistance, childrenРІР‚в„ўs services and other community programmes and services.',

    },
    1345: {
        name: 'Education Managers',
        description: 'Education managers plan, direct, coordinate and evaluate the educational and administrative aspects of education services, primary and secondary schools, colleges and faculties and departments in universities and other educational institutions.',

    },
    1346: {
        name: 'Financial and Insurance Services Branch managers',
        description: 'Financial and insurance services branch managers plan, direct, and coordinate the branches of institutions that provide financial and insurance services, such as banks, building societies, credit unions and insurance companies. They provide advice and assistance to clients on financial and insurance matters.',

    },
    1349: {
        name: 'Professional Services Managers Not Elsewhere Classified',
        description: 'This unit group covers managers who plan, direct coordinate and evaluate the provision of specialized professional and technical services and are not classified in Minor Group 121: Business Services and Administration Managers, or elsewhere in Minor Group 134: Professional Services Managers. For instance, managers responsible for the provision of policing, corrective, library, legal and fire services are classified here.',

    },
    14: {
        name: 'Hospitality, Retail and Other Services Managers',
        description: 'Hospitality, shop and related services managers plan, organize and direct the operations of establishments which provide accommodation, hospitality, retail and other services. Competent performance in most occupations in this sub-major group requires skills at the third ISCO skill level.',

    },
    141: {
        name: 'Hotel and Restaurant Managers',
        description: 'Hotel and restaurant managers plan, organize and direct the operations of establishments that provide accommodation, meals, beverages and other hospitality services.',

    },
    1411: {
        name: 'Hotel Managers',
        description: 'Hotel managers plan, organize and direct the operations of hotels, motels and similar establishments to provide guest accommodation and other services.',

    },
    1412: {
        name: 'Restaurant Managers',
        description: 'Restaurant managers plan, organize and direct the operations of cafes, restaurants and related establishments to provide dining and catering services.',

    },
    142: {
        name: 'Retail and Wholesale Trade Managers',
        description: 'Retail and wholesale trade managers plan, organize, coordinate and control the operations of establishments that sell goods on a retail or wholesale basis. They are responsible for the budgets, staffing and strategic and operational direction of shops, or of organizational units within shops that sell particular types of product.',

    },
    1420: {
        name: 'Retail and Wholesale Trade Managers',
        description: 'Retail and wholesale trade managers, plan, organize, co-ordinate and control the operations of establishments that sell goods on a retail or wholesale basis. They are responsible for the budgets, staffing and strategic and operational direction of shops, or of organizational units within shops that sell particular types of product.',

    },
    143: {
        name: 'Other Services Managers',
        description: 'Other services managers plan, organize, and control the operations of establishments that provide sporting, cultural, recreational, travel, customer contact and other amenity services.',

    },
    1431: {
        name: 'Sports, Recreation and Cultural Centre Managers',
        description: 'Sports, recreation and cultural centre managers plan, organize and control the operations of establishments that provide sporting, artistic, theatrical and other recreational and amenity services.',

    },
    1439: {
        name: 'Services Managers Not Elsewhere Classified',
        description: 'This unit group covers managers that plan, direct and coordinate the provision of services and are not classified in Sub-major Group 13: Production and Specialized Services Managers or elsewhere in Sub-major Group 14: Hospitality, Retail and Other Services Managers. For instance, managers of travel agencies, conference centres, contact centres and shopping centres are classified here.',

    },
    2: {
        name: 'Non-commissioned Armed Forces Officers',
        description: 'Non-commissioned armed forces officers enforce military discipline and supervise the activities of those employed in Sub-major Group 03: Armed Forces Occupations, Other Ranks, and/or perform similar tasks to those performed in a variety of civilian occupations outside the armed forces. This group includes members of the armed forces holding ranks such as sergeant, warrant officer and sergeant major. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    21: {
        name: 'Non-commissioned Armed Forces Officers',
        description: 'Non-commissioned armed forces officers enforce military discipline and supervise the activities of those employed in Sub-major Group 03: Armed forces Occupations, Other Ranks, and/or perform similar tasks to those performed in a variety of civilian occupations outside the armed forces. This group includes members of the armed forces holding ranks such as sergeant, warrant officer and sergeant major.',

    },
    211: {
        name: 'Physical and Earth Science Professionals',
        description: 'Physical and earth science professionals conduct research; improve or develop concepts, theories and operational methods, or apply scientific knowledge relating to physics, astronomy, meteorology, chemistry, geology and geophysics.',

    },
    2111: {
        name: 'Physicists and Astronomers',
        description: 'Physicists and astronomers conduct research and improve or develop concepts, theories and operational methods concerning matter, space, time, energy, forces and fields and the interrelationship between these physical phenomena. They apply scientific knowledge relating to physics and astronomy in industrial, medical, military or other fields.',

    },
    2112: {
        name: 'Meteorologists',
        description: 'Meteorologists prepare short-term or long-term weather forecasts used in aviation, shipping, agriculture and other areas and for the information of the general public. They conduct research related to the composition, structure and dynamics of the atmosphere.',

    },
    2113: {
        name: 'Chemists',
        description: 'Chemists conduct research, improve or develop concepts, theories and operational methods, or apply scientific knowledge relating to chemistry, to develop new knowledge or products and for quality and process control.',

    },
    2114: {
        name: 'Geologists and geophysicists',
        description: 'Geologists and geophysicists conduct research; improve or develop concepts, theories and operational methods, or apply scientific knowledge relating to geology and geophysics in such fields as oil, gas and mineral exploration and extraction, water conservation, civil engineering, telecommunications and navigation, and assessment and mitigation of the effects of development and waste disposal projects on the environment.',

    },
    212: {
        name: 'Mathematicians, Actuaries and Statisticians',
        description: 'Mathematicians, actuaries and statisticians conduct research, improve or develop mathematical, actuarial and statistical concepts, theories and operational models and techniques; and apply this knowledge to a wide range of tasks in such fields as engineering, business and social and other sciences.',

    },
    2120: {
        name: 'Mathematicians, Actuaries and Statisticians',
        description: 'Mathematicians, actuaries and statisticians conduct research and improve or develop mathematical, actuarial and statistical concepts, theories and operational methods and techniques and advise on or engage in their practical application in such fields as engineering, business and social and other sciences.',

    },
    213: {
        name: 'Life Science Professionals',
        description: 'Life science professionals apply knowledge gained from research into human, animal and plant life and their interactions with each other and the environment to develop new knowledge, improve agricultural and forestry production, and solve human health and environmental problems.',

    },
    2131: {
        name: 'Biologists, Botanists, Zoologists and Related Professionals',
        description: 'Biologists, botanists, zoologists and related professionals study living organisms and their interactions with each other and with the environment, and apply this knowledge to solve human health and environmental problems. They work in diverse fields such as botany, zoology, ecology, marine biology, genetics, immunology, pharmacology, toxicology, physiology, bacteriology and virology.',

    },
    2132: {
        name: 'Farming, Forestry and Fisheries Advisers',
        description: 'Farming, forestry and fisheries advisers study and provide assistance and advice on farm, forestry and fisheries management, including cultivation, fertilization, harvesting, soil erosion and composition, disease prevention, nutrition, crop rotation and marketing. They develop techniques for increasing productivity, and study and develop plans and policies for land and fisheries management.',

    },
    2133: {
        name: 'Environmental Protection Professionals',
        description: 'Environmental protection professionals study and assess the effects on the environment of human activity such as air, water and noise pollution, soil contamination, climate change, toxic waste and depletion and degradation of natural resources. They develop plans and solutions to protect, conserve, restore, minimize and prevent further damage to the environment.',

    },
    214: {
        name: 'Engineering Professionals (excluding Electrotechnology)',
        description: 'Engineering professionals (excluding electrotechnology) design, plan and organize the testing, construction, installation and maintenance of structures, machines and their components, and production systems and plants; and plan production schedules and work procedures to ensure engineering projects are undertaken safely, efficiently and in a cost effective manner.',

    },
    2141: {
        name: 'Industrial and Production Engineers',
        description: 'Industrial and production engineers conduct research and design, organize and oversee the construction, operation and maintenance of industrial production processes and installations. They establish programmes for the coordination of manufacturing activities and assess cost effectiveness and safety.',

    },
    2142: {
        name: 'Civil Engineers',
        description: 'Civil engineers conduct research, advise on, design, and direct construction; manage the operation and maintenance of civil engineering structures; or study and advise on technological aspects of particular materials.',

    },
    2143: {
        name: 'Environmental Engineers',
        description: 'Environmental engineers conduct research, advise on, design and direct implementation of solutions to prevent, control or remedy negative impacts of human activity on the environment utilizing a variety of engineering disciplines. They conduct environmental assessments of construction and civil engineering projects and apply engineering principles to pollution control, recycling and waste disposal.',

    },
    2144: {
        name: 'Mechanical Engineers',
        description: 'Mechanical engineers conduct research; advise on, design and direct production of machines, aircraft, ships, machinery and industrial plant, equipment and systems; advise on and direct their functioning, maintenance and repair; or study and advise on mechanical aspects of particular materials, products or processes.',

    },
    2145: {
        name: 'Chemical Engineers',
        description: 'Chemical engineers conduct research and develop, advise on and direct commercial-scale chemical processes and production of various substances and items such as crude oil, petroleum derivatives, food and drink products, medicines, or synthetic materials. They direct maintenance and repair of chemical plant and equipment and study and advise on chemical aspects of particular materials, products or processes.',

    },
    2146: {
        name: 'Mining Engineers, Metallurgists and Related Professionals',
        description: 'Mining engineers, metallurgists and related professionals conduct research on, design, develop and maintain commercial-scale methods of extracting metals from their ores, or minerals, water, oil or gas from the earth, and of developing new alloys, ceramic and other materials, or study and advise on mining or metallurgical aspects of particular materials, products or processes.',

    },
    2149: {
        name: 'Engineering Professionals Not Elsewhere Classified',
        description: 'This unit group covers engineering professionals not classified elsewhere in Minor Group 214: Engineering Professionals (excluding Electrotechnology) or in Minor Group 215: Electrotechnology Engineers. For instance, the group includes those who conduct research and advise on or develop engineering procedures and solutions concerning workplace safety, biomedical engineering, optics, materials, nuclear power generation and explosives.',

    },
    215: {
        name: 'Electrotechnology Engineers',
        description: 'Electrotechnology engineers conduct research on and design, advise, plan and direct the construction and operation of electronic, electrical and telecommunications systems, components, motors and equipment. They organize and establish control systems to monitor the performance and safety of electrical and electronic assemblies and systems.',

    },
    2151: {
        name: 'Electrical Engineers',
        description: 'Electrical engineers conduct research and advise on, design and direct the construction and operation of electrical systems, components, motors and equipment, and advise on and direct their functioning, maintenance and repair, or study and advise on technological aspects of electrical engineering materials, products and processes.',

    },
    2152: {
        name: 'Electronics Engineers',
        description: 'Electronics engineers conduct research on, design and direct the construction functioning, maintenance and repair of electronic systems, and study and advise on technological aspects of electronic engineering materials, products or processes.',

    },
    2153: {
        name: 'Telecommunications Engineers',
        description: 'Telecommunications engineers conduct research and advise on, design and direct the construction, functioning, maintenance and repair of telecommunication systems and equipment. They study and advise on technological aspects of telecommunication engineering materials, products or processes.',

    },
    216: {
        name: 'Architects, Planners, Surveyors and Designers',
        description: 'Architects, planners, surveyors and designers plan and design landscapes, building exteriors and interiors, products for manufacture, and visual and audio-visual content for the communication of information. They conduct survey work to precisely position geographical features; design, prepare and revise maps; and develop and implement plans and policies for controlling the use of land.',

    },
    2161: {
        name: 'Building Architects',
        description: 'Building architects design commercial, industrial, institutional, residential and recreational buildings and plan and monitor their construction, maintenance and rehabilitation.',

    },
    2162: {
        name: 'Landscape Architects',
        description: 'Landscape architects plan and design landscapes and open spaces for projects such as, parks, schools, institutions, roads, external areas for commercial, industrial and residential sites, and plan and monitor their construction, maintenance and rehabilitation.',

    },
    2163: {
        name: 'Product and Garment Designers',
        description: 'Product and garment designers design and develop products for manufacture and prepare designs and specifications of products for mass, batch and one-off production.',

    },
    2164: {
        name: 'Town and Traffic Planners',
        description: 'Town and traffic planners develop and implement plans and policies for the controlled use of urban and rural land and for traffic systems. They conduct research and provide advice on economic, environmental and social factors affecting land use and traffic flows.',

    },
    2165: {
        name: 'Cartographers and Surveyors',
        description: 'Cartographers and surveyors determine the exact position of, or prepare and revise digital, graphic and pictorial maps, charts or other visual representation of, natural and constructed features and boundaries of land, seas, underground areas and celestial bodies, applying scientific and mathematical principles.',

    },
    2166: {
        name: 'Graphic and Multimedia Designers',
        description: 'Graphic and multimedia designers design visual and audio-visual content for the communication of information using print, film, electronic, digital and other forms of visual and audio media. They create graphics, special effects, animation or other visual images for use in computer games, movies, music videos, print media and advertisements.',

    },
    22: {
        name: 'Health Professionals',
        description: 'Health professionals conduct research; improve or develop concepts, theories and operational methods; and apply scientific knowledge relating to medicine, nursing, dentistry, veterinary medicine, pharmacy, and promotion of health. Competent performance in most occupations in this sub-major group requires skills at the fourth ISCO skill level.',

    },
    221: {
        name: 'Medical Doctors',
        description: 'Medical doctors (physicians) study, diagnose, treat and prevent illness, disease, injury, and other physical and mental impairments in humans through the application of the principles and procedures of modern medicine. They plan, supervise and evaluate the implementation of care and treatment plans by other health care providers, and conduct medical education and research activities.',

    },
    2211: {
        name: 'Generalist Medical Practitioners',
        description: 'Generalist medical practitioners (including family and primary care doctors) diagnose, treat and prevent illness, disease, injury and other physical and mental impairments and maintain general health in humans through application of the principles and procedures of modern medicine. They do not limit their practice to certain disease categories or methods of treatment, and may assume responsibility for the provision of continuing and comprehensive medical care to individuals, families and communities.',

    },
    2212: {
        name: 'Specialist Medical Practitioners',
        description: 'Specialist medical practitioners (medical doctors) diagnose, treat and prevent illness, disease, injury, and other physical and mental impairments in humans, using specialized testing, diagnostic, medical, surgical, physical and psychiatric techniques, through application of the principles and procedures of modern medicine. They specialize in certain disease categories, types of patient or methods of treatment and may conduct medical education and research in their chosen areas of specialization.',

    },
    222: {
        name: 'Nursing and Midwifery Professionals',
        description: 'Nursing and midwifery professionals provide treatment and care services for people who are physically or mentally ill, disabled or infirm, and others in need of care due to potential risks to health including before, during and after childbirth. They assume responsibility for the planning, management and evaluation of the care of patients, including the supervision of other health care workers, working autonomously or in teams with medical doctors and others in the practical application of preventive and curative measures.',

    },
    2221: {
        name: 'Nursing Professionals',
        description: 'Nursing professionals provide treatment, support and care services for people who are in need of nursing care due to the effects of ageing, injury, illness or other physical or mental impairment, or potential risks to health. They assume responsibility for the planning and management of the care of patients, including the supervision of other health care workers, working autonomously or in teams with medical doctors and others in the practical application of preventive and curative measures.',

    },
    2222: {
        name: 'Midwifery Professionals',
        description: 'Midwifery professionals plan, manage, provide and evaluate midwifery care services before, during and after pregnancy and childbirth. They provide delivery care for reducing health risks to women and newborn children, working autonomously or in teams with other health care providers.',

    },
    223: {
        name: 'Traditional and Complementary Medicine Professionals',
        description: 'Traditional and complementary medicine professionals examine patients, prevent and treat illness, disease, injury, and other physical and mental impairments and maintain general health in humans by applying knowledge, skills and practices acquired through extensive study of the theories, beliefs and experiences, originating in specific cultures.',

    },
    2230: {
        name: 'Traditional and Complementary Medicine Professionals',
        description: 'Traditional and complementary medicine professionals examine patients, prevent and treat illness, disease, injury, and other physical and mental impairments and maintain general health in humans by applying knowledge, skills and practices acquired through extensive study of the theories, beliefs and experiences, originating in specific cultures',

    },
    224: {
        name: 'Paramedical Practitioners',
        description: 'Paramedical practitioners provide advisory, diagnostic, curative and preventive medical services for humans more limited in scope and complexity than those carried out by medical doctors. They work autonomously or with limited supervision of medical doctors, and apply advanced clinical procedures for treating and preventing diseases, injuries and other physical or mental impairments common to specific communities.',

    },
    2240: {
        name: 'Paramedical Practitioners',
        description: 'Paramedical practitioners provide advisory, diagnostic, curative and preventive medical services more limited in scope and complexity than those carried out by medical doctors. They work autonomously, or with limited supervision of medical doctors, and apply advanced clinical procedures for treating and preventing diseases, injuries and other physical or mental impairments common to specific communities.',

    },
    225: {
        name: 'Veterinarians',
        description: 'Veterinarians diagnose, prevent and treat diseases, injuries and dysfunctions of animals. They may provide care to a wide range of animals or specialize in the treatment of a particular animal group or in a particular area of specialization, or provide professional services to commercial firms producing biological and pharmaceutical products.',

    },
    2250: {
        name: 'Veterinarians',
        description: 'Veterinarians diagnose, prevent and treat diseases, injuries and dysfunctions of animals. They may provide care to a wide range of animals or specialize in the treatment of a particular animal group or in a particular specialty area, or provide professional services to commercial firms producing biological and pharmaceutical products.',

    },
    226: {
        name: 'Other Health Professionals',
        description: 'Other health professionals provide health services related to dentistry, pharmacy, environmental health and hygiene, occupational health and safety, physiotherapy, nutrition, hearing, speech, vision and rehabilitation therapies. This minor group includes all human health professionals, except doctors, traditional and complementary medicine practitioners, nurses, midwives and paramedical professionals.',

    },
    2261: {
        name: 'Dentists',
        description: 'Dentists diagnose, treat and prevent diseases, injuries and abnormalities of the teeth, mouth, jaws and associated tissues by applying the principles and procedures of modern dentistry. They use a broad range of specialized diagnostic, surgical and other techniques to promote and restore oral health.',

    },
    2262: {
        name: 'Pharmacists',
        description: 'Pharmacists store, preserve, compound, and dispense medicinal products and counsel on the proper use and adverse effects of drugs and medicines following prescriptions issued by medical doctors and other health professionals. They contribute to researching, testing, preparing, prescribing and monitoring medicinal therapies for optimizing human health.',

    },
    2263: {
        name: 'Environmental and Occupational Health and Hygiene Professionals',
        description: 'Environmental and occupational health and hygiene professionals assess, plan and implement programmes to recognize, monitor and control environmental factors that can potentially affect human health, to ensure safe and healthy working conditions and to prevent disease or injury caused by chemical, physical, radiological and biological agents or ergonomic factors.',

    },
    2264: {
        name: 'Physiotherapists',
        description: 'Physiotherapists assess, plan and implement rehabilitative programmes that improve or restore human motor functions, maximize movement ability, relieve pain syndromes, and treat or prevent physical challenges associated with injuries, diseases and other impairments. They apply a broad range of physical therapies and techniques such as movement, ultrasound, heating, laser and other techniques.',

    },
    2265: {
        name: 'Dieticians and Nutritionists',
        description: 'Dieticians and nutritionists assess, plan and implement programmes to enhance the impact of food and nutrition on human health.',

    },
    2266: {
        name: 'Audiologists and Speech Therapists',
        description: 'Audiologists and speech therapists evaluate, manage and treat physical disorders affecting human hearing, speech, communication and swallowing. They prescribe corrective devices or rehabilitative therapies for hearing loss, speech disorders, and related sensory and neural problems and provide counselling on hearing safety and communication performance.',

    },
    2267: {
        name: 'Optometrists and Ophthalmic Opticians',
        description: 'Optometrists and ophthalmic opticians provide diagnosis, management and treatment services for disorders of the eyes and visual system. They counsel on eye care and prescribe optical aids or other therapies for visual disturbance.',

    },
    2269: {
        name: 'Health Professionals Not Elsewhere Classified',
        description: 'This unit group covers health professionals not classified elsewhere in Sub-major Group 22: Health Professionals. For instance, the group includes occupations such as podiatrist, occupational therapist, recreational therapist, chiropractor, osteopath and other professionals providing diagnostic, preventive, curative and rehabilitative health services.',

    },
    23: {
        name: 'Teaching Professionals',
        description: 'Teaching professionals teach the theory and practice of one or more disciplines at different educational levels; conduct research; improve or develop concepts, theories and operational methods pertaining to their particular discipline; and prepare scholarly papers and books. Competent performance in most occupations in this sub-major group requires skills at the fourth ISCO skill level.',

    },
    231: {
        name: 'University and Higher Education Teachers',
        description: 'University and higher education teachers prepare and deliver lectures and conduct tutorials in one or more subjects within a prescribed course of study at a university or other higher educational institution. They conduct research, and prepare scholarly papers and books.',

    },
    2310: {
        name: 'University and Higher Education Teachers',
        description: 'University and higher education teachers prepare and deliver lectures and conduct tutorials in one or more subjects within a prescribed course of study at a university or other higher educational institution. They conduct research, and prepare scholarly papers and books.',

    },
    232: {
        name: 'Vocational education teachers',
        description: 'Vocational education teachers teach or instruct vocational or occupational subjects in adult and further education institutions and to senior students in secondary schools and colleges. They prepare students for employment in specific occupations or occupational areas for which university or higher education is not normally required.',

    },
    2320: {
        name: 'Vocational Education Teachers',
        description: 'Vocational education teachers teach or instruct vocational or occupational subjects in adult and further education institutions and to senior students in secondary schools and colleges. They prepare students for employment in specific occupations or occupational areas for which university or higher education is not normally required.',

    },
    233: {
        name: 'Secondary Education Teachers',
        description: 'Secondary education teachers teach one or more subjects at secondary education level, excluding subjects intended to prepare students for employment in specific occupational areas.',

    },
    2330: {
        name: 'Secondary Education Teachers',
        description: 'Secondary education teachers teach one or more subjects at secondary education level, excluding subjects intended to prepare students for employment in specific occupational areas.',

    },
    234: {
        name: 'Primary School and Early Childhood Teachers',
        description: 'Primary school and early childhood teachers teach a range of subjects at the primary level of education and organize educational activities for children below primary school age.',

    },
    2341: {
        name: 'Primary School Teachers',
        description: 'Primary school teachers teach a range of subjects at the primary education level.',

    },
    2342: {
        name: 'Early Childhood Educators',
        description: 'Early childhood educators promote the social, physical, and intellectual development of children below primary school age through the provision of educational and play activities',

    },
    235: {
        name: 'Other Teaching Professionals',
        description: 'Other teaching professionals conduct research and advise on teaching methods, teach people with learning difficulties or special needs; teach non-native languages for migration and related purposes; give private tuition; teach arts, information technology and other subjects outside the mainstream primary, secondary and higher education systems; and provide other teaching services not classified elsewhere in Sub-major Group 23: Teaching Professionals..',

    },
    2351: {
        name: 'Education Methods Specialists',
        description: "Education methods specialists conduct research and develop or advise on teaching methods, courses and aids. They review and examine teachers' work, the functioning of educational institutions and the results achieved and recommend changes and improvements.",

    },
    2352: {
        name: 'Special Needs Teachers',
        description: 'Special needs teachers teach physically or mentally handicapped children, young persons, or adults, or those with learning difficulties or other special needs. They promote the social, emotional, intellectual and physical development of their students.',

    },
    2353: {
        name: 'Other Language Teachers',
        description: 'Other language teachers teach non-native languages to adults and children who are learning a language for reasons of migration, to fulfil employment requirements or opportunities, to facilitate participation in educational programmes delivered in a foreign language, or for personal enrichment. They work outside the mainstream primary, secondary and higher education systems, or in support of students and teachers within those systems.',

    },
    2354: {
        name: 'Other Music Teachers',
        description: 'Other music teachers teach students in the practice, theory and performance of music outside the mainstream primary, secondary and higher education systems, but may provide private or small group tuition as an extra curricular activity in association with mainstream educational institutions.',

    },
    2355: {
        name: 'Other Arts Teachers',
        description: 'Other arts teachers teach students in the practice, theory and performance of dance, drama, visual and other arts (excluding music) outside the mainstream primary, secondary and higher education systems, but may provide private or small group tuition as an extracurricular activity in association with mainstream educational institutions.',

    },
    2356: {
        name: 'Information Technology Trainers',
        description: 'Information technology trainers develop, schedule and conduct training programmes and courses for computer and other information technology users outside the mainstream primary, secondary and higher education systems.',

    },
    2359: {
        name: 'Teaching Professionals Not Elsewhere Classified',
        description: 'This unit group covers teaching professionals not classified elsewhere in Sub-major Group 23: Teaching Professionals. For instance, the group includes those who provide private tuition in subjects other than foreign languages and the arts, and those who provide educational counselling to students.',

    },
    24: {
        name: 'Business and Administration Professionals',
        description: 'Business and administration professionals perform analytical, conceptual and practical tasks to provide services in financial matters, human resource development, public relations, marketing and sales in the technical, medical, information and communication technology areas; and conduct, reviews of organizational structures, methods and systems as well as quantitative analyses of information affecting investment programmes. Competent performance in most occupations in this sub-major group requires skills at the fourth ISCO skill level.',

    },
    241: {
        name: 'Finance Professionals',
        description: 'Finance professionals plan, develop, organize, administer, invest, manage and conduct quantitative analyses of either financial accounting systems or funds for individuals, establishments and public or private institutions.',

    },
    2411: {
        name: 'Accountants',
        description: 'Accountants plan, organize and administer accounting systems for individuals and establishments. Some occupations classified here examine and analyse the accounting and financial records of individuals and establishments to ensure accuracy and compliance with established accounting standards and procedures.',

    },
    2412: {
        name: 'Financial and Investment Advisers',
        description: 'Financial and investment advisers develop financial plans for individuals and organizations, and invest and manage funds on their behalf.',

    },
    2413: {
        name: 'Financial Analysts',
        description: 'Financial analysts conduct quantitative analyses of information affecting investment programmes of public or private institutions.',

    },
    242: {
        name: 'Administration Professionals',
        description: 'Administration professionals apply various concepts and theories related to improving the effectiveness of organizations and the individuals within the organization.',

    },
    2421: {
        name: 'Management and Organization Analysts',
        description: 'Management and organization analysts assist organizations to achieve greater efficiency and solve organizational problems. They study organizational structures, methods, systems and procedures.',

    },
    2422: {
        name: 'Policy Administration Professionals',
        description: 'Policy administration professionals develop and analyse policies guiding the design, implementation and modification of government and commercial operations and programmes.',

    },
    2423: {
        name: 'Personnel and Careers Professionals',
        description: 'Personnel and careers professionals provide professional business services related to personnel policies such as employee recruitment or development, occupational analysis and vocational guidance.',

    },
    2424: {
        name: 'Training and Staff Development Professionals',
        description: 'Training and staff development professionals plan, develop, implement and evaluate training and development programmes to ensure management and staff acquire the skills and develop the competencies required by organizations to meet organizational objectives.',

    },
    243: {
        name: 'Sales, Marketing and Public Relations Professionals',
        description: 'Sales, marketing and public relations professionals plan, develop, coordinate and implement programmes of information dissemination to promote organizations, goods and services; and represent companies in selling a range of technical, industrial, medical, pharmaceutical and ICT goods and services.',

    },
    2431: {
        name: 'Advertising and Marketing Professionals',
        description: 'Advertising and marketing professionals develop and coordinate advertising strategies and campaigns, determine the market for new goods and services, and identify and develop market opportunities for new and existing goods and services.',

    },
    2432: {
        name: 'Public Relations Professionals',
        description: 'Public relations professionals plan, develop, implement and evaluate information and communication \tstrategies that create an understanding and a favourable view of businesses and other organizations, their goods and services, and their role in the community.',

    },
    2433: {
        name: 'Technical and Medical Sales Professionals (excluding ICT)',
        description: 'Technical and medical sales professionals (excluding ICT) represent companies in selling a range of industrial, medical and pharmaceutical goods and services to industrial, business, professional and other establishments.',

    },
    2434: {
        name: 'Information and Communications Technology Sales Professionals',
        description: 'Information and communications technology (ICT) sales professionals sell, at the wholesale level, a range of computer hardware, software and other information and communications technology goods and services including installations and provide specialized information as required.',

    },
    25: {
        name: 'Information and Communications Technology Professionals',
        description: 'Information and communications technology professionals conduct research, plan, design, write, test, provide advice and improve information technology systems, hardware, software and related concepts for specific applications; develop associated documentation including principles, policies and procedures; and design, develop, control, maintain and support databases and other information systems to ensure optimal performance and data integrity and security.',

    },
    251: {
        name: 'Software and Applications Developers and Analysts',
        description: 'Software and applications developers and analysts conduct research, plan, design, write, test, provide advice on and improve information technology systems such as hardware, software and other applications to meet specific requirements. Competent performance in most occupations in this sub-major group requires skills at the fourth ISCO skill level.',

    },
    2511: {
        name: 'Systems Analysts',
        description: 'Systems analysts conduct research, analyse and evaluate client information technology requirements, procedures or problems, and develop and implement proposals, recommendations and plans to improve current or future information systems.',

    },
    2512: {
        name: 'Software Developers',
        description: 'Software developers research, analyse and evaluate requirements for existing or new software applications and operating systems, and design, develop, test and maintain software solutions to meet these requirements.',

    },
    2513: {
        name: 'Web and Multimedia Developers',
        description: 'Web and multimedia development professionals combine design and technical knowledge to research, analyse, evaluate, design, programme and modify websites and applications that draw together text, graphics, animations, imaging, audio and video displays and other interactive media.',

    },
    2514: {
        name: 'Applications Programmers',
        description: 'Applications programmers write and maintain programmable code outlined in technical instructions and specifications for software applications and operating systems.',

    },
    2519: {
        name: 'Software and Applications Developers and Analysts Not Elsewhere Classified',
        description: 'This unit group covers software and applications developers and analysts not classified elsewhere in Minor Group 251: Software and Applications Developers and Analysts. For instance, the group includes those professionals specializing in quality assurance including software testing.',

    },
    252: {
        name: 'Database and Network Professionals',
        description: 'Database and network professionals design, develop, control, maintain and support the optimal performance and security of information technology systems and infrastructure, including databases, hardware and software, networks and operating systems.',

    },
    2521: {
        name: 'Database Designers and Administrators',
        description: 'Database designers and administrators design, develop, control, maintain and support the optimal performance and security of databases.',

    },
    2522: {
        name: 'Systems Aministrators',
        description: 'Systems administrators develop, control, maintain and support the optimal performance and security of information technology systems.',

    },
    2523: {
        name: 'Computer Network Professionals',
        description: 'Computer network professionals research, analyse, design, test and recommend strategies for network architecture and development. They implement, manage, maintain and configure network hardware and software, and monitor, troubleshoot and optimize performance.',

    },
    2529: {
        name: 'Database and Network Professionals Not Elsewhere Classified',
        description: 'This unit group includes database and network professionals not classified elsewhere in Minor Group 252: Database and Network Professionals. \tFor instance, the group includes information and communications technology security specialists.',

    },
    26: {
        name: 'Legal, Social and Cultural Professionals',
        description: 'Legal, social and cultural professionals conduct research, improve or develop concepts, theories and operational methods; or apply knowledge relating to the law, storage and retrieval of information and artefacts, psychology, social welfare, politics, economics, history, religion, languages, sociology, other social sciences, and arts and entertainment. Competent performance in most occupations in this sub-major group requires skills at the fourth ISCO skill level.',

    },
    261: {
        name: 'Legal Professionals',
        description: 'Legal professionals conduct research on legal problems, advise clients on legal aspects of problems, plead cases or conduct prosecutions in courts of law, preside over judicial proceedings in courts of law and draft laws and regulations, notary, notaries',

    },
    2611: {
        name: 'Lawyers',
        description: 'Lawyers give clients legal advice on a wide variety of subjects, draw up legal documents, represent clients before administrative boards or tribunals and plead cases or conduct prosecutions in courts of justice, or instruct barristers to plead in higher courts of justice.',

    },
    2612: {
        name: 'Judges',
        description: 'Judges preside over civil and criminal proceedings in courts of law.',

    },
    2619: {
        name: 'Legal Professionals Not Elsewhere Classified',
        description: 'This unit group covers legal professionals not classified elsewhere in Minor Group 261: Legal professionals. \tFor instance, the group includes those who perform legal functions other than pleading or prosecuting cases or presiding over judicial proceedings.',

    },
    262: {
        name: 'Librarians, Archivists and Curators',
        description: 'Librarians, archivists and curators develop and maintain the collections of archives, libraries, museums, art galleries, and similar establishments.',

    },
    2621: {
        name: 'Archivists and Curators',
        description: 'Archivists and curators collect, appraise and ensure the safekeeping and preservation of the contents of archives, artefacts and records of historical, cultural, administrative and artistic interest, and of art and other objects. They plan, devise and implement systems for the safekeeping of records and historically valuable documents.',

    },
    2622: {
        name: 'Librarians and Related Information Professionals',
        description: 'Librarians and related information professionals collect, select, develop, organize and maintain library collections and other information repositories, organize and control other library services and provide information for users.',

    },
    263: {
        name: 'Social and Religious Professionals',
        description: 'Social and religious professionals conduct research, improve or develop concepts, theories and operational methods; apply knowledge relating to philosophy, politics, economics, sociology, anthropology, history, psychology, and other social sciences; or provide social services to meet the needs of individuals and families in a community.',

    },
    2631: {
        name: 'Economists',
        description: 'Economists conduct research, monitor data, analyse information and prepare reports and plans to resolve economic and business problems and develop models to analyse, explain and forecast economic behaviour and patterns. They provide advice to business, interest groups and governments to formulate solutions to present or projected economic and business problems.',

    },
    2632: {
        name: 'Sociologists, Anthropologists and Related Professionals',
        description: 'Sociologists, anthropologists and related professionals investigate and describe the structure, origin and evolution of societies and the interdependence between environmental conditions and human activities. They provide advice on the practical application of their findings in the formulation of economic and social policies.',

    },
    2633: {
        name: 'Philosophers, Historians and Political Scientists',
        description: 'Philosophers, historians and political scientists conduct research into the nature of human experience and existence, phases or aspects of human history, and political structures, movements and behaviour. They document and report on findings to inform and guide political and individual actions',

    },
    2634: {
        name: 'Psychologists',
        description: 'Psychologists research into and study the mental processes and behaviour of human beings as individuals or in groups, and apply this knowledge to promote personal, social, educational or occupational adjustment and development.',

    },
    2635: {
        name: 'Social Work and Counselling Professionals',
        description: 'Social work and counselling professionals provide advice and guidance to individuals, families, groups, communities and organizations in response to social and personal difficulties. They assist clients to develop skills and access resources and support services needed to respond to issues arising from unemployment, poverty, disability, addiction, criminal and delinquent behaviour, and marital and other problems.',

    },
    2636: {
        name: 'Religious Professionals',
        description: 'Religious professionals function as perpetuators of sacred traditions, practices and beliefs. They conduct religious services, celebrate or administer the rites of a religious faith or denomination, provide spiritual and moral guidance and perform other functions associated with the practice of a religion.',

    },
    264: {
        name: 'Authors, Journalists and Linguists',
        description: 'Authors, journalists and linguists conceive and create literary works, interpret and communicate news and public affairs through the media; and translate or interpret from one language into another.',

    },
    2641: {
        name: 'Authors and Related Writers',
        description: 'Authors and related writers plan, research and write books, scripts, storyboards, plays, essays, speeches, manuals, specifications and other non-journalistic articles (excluding material for newspapers, magazines and other periodicals) for publication or presentation.',

    },
    2642: {
        name: 'Journalists',
        description: 'Journalists research, investigate, interpret and communicate news and public affairs through newspapers, television, radio and other media.',

    },
    2643: {
        name: 'Translators, Interpreters and Other Linguists',
        description: 'Translators, interpreters and other linguists translate or interpret from one language into another and study the origin, development and structure of languages.',

    },
    265: {
        name: 'Creative and Performing Artists',
        description: 'Creative and performing artists communicate ideas, impressions and facts in a wide range of media to achieve particular effects; interpret a composition such as a musical score or a script to perform or direct the performance; and host the presentation of such performance and other media events.',

    },
    2651: {
        name: 'Visual Artists',
        description: 'Visual artists create and execute works of art by sculpting, painting, drawing, creating cartoons, engraving or using other techniques.',

    },
    2652: {
        name: 'Musicians, Singers and Composers',
        description: 'Musicians, singers and composers write, arrange, conduct and perform musical compositions.',

    },
    2653: {
        name: 'Dancers and Choreographers',
        description: 'Dancers and choreographers conceive and create or perform dances.',

    },
    2654: {
        name: 'Film, Stage and Related Directors and Producers',
        description: 'Film, stage and related directors and producers oversee and control the technical and artistic aspects of motion pictures, television or radio productions and stage shows.',

    },
    2655: {
        name: 'Actors',
        description: 'Actors portray roles in motion pictures, television or radio productions and stage shows.',

    },
    2656: {
        name: 'Announcers on Radio, Television and Other Media',
        description: 'Announcers on radio, television and other media read news bulletins, conduct interviews, and make other announcements or introductions on radio, television, and in theatres and other establishments or media',

    },
    2659: {
        name: 'Creative and Performing Artists Not Elsewhere Classified',
        description: 'This unit group covers all creative and performing artists not classified elsewhere in Minor Group 265: Creative and Performing Artists. For instance, the group includes clowns, magicians, acrobats and other performing artists.',

    },
    3: {
        name: 'Armed Forces Occupations, Other Ranks',
        description: 'Armed forces occupations, other ranks include all conscripted and non-conscripted members of the armed forces except commissioned and non-commissioned officers. They perform specific military tasks and/or perform similar tasks to those performed in a variety of civilian occupations outside the armed forces. Most occupations in this sub-major group require skills at the first ISCO skill level.',

    },
    31: {
        name: 'Armed Forces Occupations, Other Ranks',
        description: 'Armed forces occupations, other ranks include all conscripted and non-conscripted members of the armed forces except commissioned and non-commissioned officers. They perform specific military tasks and/or perform similar tasks to those performed in a variety of civilian occupations outside the armed forces.',

    },
    311: {
        name: 'Physical and Engineering Science Technicians',
        description: 'Physical and engineering science technicians perform technical tasks to aid in research on and the practical application of concepts, principles and operational methods particular to physical sciences including such areas as engineering, technical drawing or economic efficiency of production processes.',

    },
    3111: {
        name: 'Chemical and Physical Science Technicians',
        description: 'Chemical and physical science technicians perform technical tasks to aid in research in chemistry, physics, geology, geophysics, meteorology and astronomy, and in the development of industrial, medical, military and other practical applications of research results.',

    },
    3112: {
        name: 'Civil Engineering Technicians',
        description: 'Civil engineering technicians perform technical tasks in civil engineering research and in the design, construction, operation, maintenance and repair of buildings and other structures such as water supply and wastewater treatment systems, bridges, roads, dams and airports.',

    },
    3113: {
        name: 'Electrical Engineering Technicians',
        description: 'Electrical engineering technicians perform technical tasks to aid in electrical engineering research and in the design, manufacture, assembly, construction, operation, maintenance and repair of electrical equipment, facilities and distribution systems.',

    },
    3114: {
        name: 'Electronics Engineering Technicians',
        description: 'Electronics engineering technicians perform technical tasks to aid in electronic research and in the design, manufacture, assembly, construction, operation, maintenance and repair of electronic equipment.',

    },
    3115: {
        name: 'Mechanical Engineering Technicians',
        description: 'Mechanical engineering technicians perform technical tasks to aid in mechanical engineering research, and in the design, manufacture, assembly, construction, operation, maintenance and repair of machines, components and mechanical equipment.',

    },
    3116: {
        name: 'Chemical Engineering Technicians',
        description: 'Chemical engineering technicians perform technical tasks to aid in chemical engineering research and in the design, manufacture, construction, operation, maintenance and repair of chemical plant.',

    },
    3117: {
        name: 'Mining and metallurgical technicians',
        description: 'Mining and metallurgical technicians perform technical tasks to assist in research and experiments related to metallurgy, in improving methods of extracting solid minerals, oil and gas, and in the design, construction, operation, maintenance and repair of mines and mine installations, of systems for transporting and storing oil and natural gas, and for extraction of metals from ores.',

    },
    3118: {
        name: 'Draughtspersons',
        description: 'Draughtspersons prepare technical drawings, maps and illustrations from sketches, measurements \tand other data, and copy final drawings and paintings onto printing plates.',

    },
    3119: {
        name: 'Physical and Engineering Science Technicians Not Elsewhere Classified',
        description: 'This unit group covers physical and engineering science technicians not classified elsewhere in Minor Group 311: Physical and Engineering Science Technicians. For instance, the unit group includes those who assist scientists and engineers engaged in developing procedures or conducting research on safety, biomedical, environmental or industrial and production engineering.',

    },
    312: {
        name: 'Mining, Manufacturing and Construction Supervisors',
        description: 'Mining, manufacturing and construction supervisors coordinate, supervise, control and schedule the activities of workers in manufacturing, mining and construction operations.',

    },
    3121: {
        name: 'Mining Supervisors',
        description: 'Mining supervisors oversee mining and quarrying operations and directly supervise and coordinate the activities of miners working in underground and surface mines and quarries.',

    },
    3122: {
        name: 'Manufacturing Supervisors',
        description: 'Manufacturing supervisors coordinate and supervise the activities of process control technicians, machine operators, assemblers and other manufacturing labourers.',

    },
    3123: {
        name: 'Construction Supervisors',
        description: 'Construction supervisors, coordinate, supervise and schedule the activities of workers engaged in the construction and repair of buildings and structures.',

    },
    313: {
        name: 'Process Control Technicians',
        description: 'Process control technicians operate and monitor switchboards, computerized control systems, multi-function process control machinery and maintain processing units in electrical power generation and distribution, wastewater, sewage treatment and waste disposal plants, chemical, petroleum and natural gas refineries, in metal processing and other multiple process operations.',

    },
    3131: {
        name: 'Power Production Plant Operators',
        description: 'Power production plant operators operate, monitor and maintain switchboards and related equipment in electrical control centres which control the production and distribution of electrical or other power in transmission networks. The equipment operated includes reactors, turbines, generators and other auxiliary equipment in electrical power generating stations.',

    },
    3132: {
        name: 'Incinerator and Water Treatment Plant Operators',
        description: 'Incinerator and water treatment plant operators monitor and operate computerized control systems and related equipment in solid and liquid waste treatment plants to regulate the treatment and disposal of sewage and wastes, and in water filtration and treatment plants to regulate the treatment and distribution of water.',

    },
    3133: {
        name: 'Chemical Processing Plant Controllers',
        description: 'Chemical processing plant controllers operate and monitor chemical plants and related multi-function process control machinery, and adjust and maintain processing units and equipment which distil, filter, separate, heat or refine chemicals.',

    },
    3134: {
        name: 'Petroleum and Natural Gas Refining Plant Operators',
        description: 'Petroleum and natural gas refining plant operators operate and monitor plants and adjust and maintain processing units and equipment which refine, distil and treat petroleum, petroleum-based products and by-products, or natural gas.',

    },
    3135: {
        name: 'Metal Production Process Controllers',
        description: 'Metal production process controllers operate and monitor multi-function process control machinery and equipment to control the processing of metal converting and refining furnaces, metal-rolling mills, metal heat-treating or metal-extrusion plant.',

    },
    3139: {
        name: 'Process Control Technicians Not Elsewhere Classified',
        description: 'This unit group covers process control technicians not classified elsewhere in Minor Group 313: Process Control Technicians. For instance, the unit group includes those who operate multiple process control equipment in manufacturing assembly lines and paper and pulp production.',

    },
    314: {
        name: 'Life Science Technicians and Related Associate Professionals',
        description: 'Life science technicians and related associate professionals perform a variety of technical tasks to support life science professionals with their research, development, management, conservation and protection work, in areas such as biology, botany, zoology, biotechnology and biochemistry, and to agriculture, fisheries and forestry.',

    },
    3141: {
        name: 'Life Science Technicians (excluding Medical)',
        description: 'Life science technicians (excluding medical) provide technical support to life science professionals undertaking research, analysis and testing of living organisms, and development and application of products and processes resulting from research in areas such as natural resource management, environmental protection, plant and animal biology, microbiology, and cell and molecular biology.',

    },
    3142: {
        name: 'Agricultural Technicians',
        description: 'Agricultural technicians perform tests and experiments, and provide technical and scientific support to agricultural scientists, farmers and farm managers.',

    },
    3143: {
        name: 'Forestry Technicians',
        description: 'Forestry technicians perform technical and supervisory functions in support of forestry research and forest management, harvesting, resource conservation and environmental protection.',

    },
    315: {
        name: 'Ship and Aircraft Controllers and Technicians',
        description: 'Ship and aircraft controllers and technicians command and navigate ships and aircraft; perform technical functions to ensure safe and efficient movement and operations; and develop electrical, electromechanical and computerized air control systems.',

    },
    3151: {
        name: "Ships' Engineers",
        description: 'ShipsРІР‚в„ў engineers control and participate in the operation, maintenance and repair of mechanical, electrical and electronic equipment and machinery on board ship, or perform related supporting functions on shore.',

    },
    3152: {
        name: "Ships' Deck Officers and Pilots",
        description: 'ShipsРІР‚в„ў deck officers and pilots command and navigate ships and similar vessels, and perform related functions on shore.',

    },
    3153: {
        name: 'Aircraft Pilots and Related Associate Professionals',
        description: 'Aircraft pilots and related associate professionals control the operation of mechanical, electrical and electronic equipment, in order to navigate aircraft for transporting passengers, mail and freight, and perform related pre-flight and in-flight tasks.',

    },
    3154: {
        name: 'Air Traffic Controllers',
        description: 'Air traffic controllers direct aircraft movements in airspace and on the ground, using radio, radar and lighting systems, and provide information relevant to the operation of aircraft.',

    },
    3155: {
        name: 'Air Traffic Safety Electronics Technicians',
        description: 'Air traffic safety electronics technicians perform technical tasks concerning the design, installation, management, operation, maintenance and repair of air traffic control and air navigation systems.',

    },
    32: {
        name: 'Health Associate Professionals',
        description: 'Health associate professionals perform technical and practical tasks to support diagnosis and treatment of illness, disease, injuries and impairments in humans and animals, and to support implementation of health care, treatment and referral plans usually established by medical, veterinary, nursing and other health professionals. Competent performance in most occupations in this sub-major group requires skills at the third ISCO skill level.',

    },
    321: {
        name: 'Medical and Pharmaceutical Technicians',
        description: 'Medical and pharmaceutical technicians perform technical tasks to assist in diagnosis and treatment of illness, disease, injuries and impairments.',

    },
    3211: {
        name: 'Medical Imaging and Therapeutic Equipment Technicians',
        description: 'Medical imaging and therapeutic equipment technicians test and operate radiographic, ultrasound and other medical imaging equipment to produce images of body structures for the diagnosis and treatment of injury, disease and other impairments. They may administer radiation treatments to patients under the supervision of a radiologist or other health professional.',

    },
    3212: {
        name: 'Medical and Pathology Laboratory Technicians',
        description: 'Medical and pathology laboratory technicians perform clinical tests on specimens of bodily fluids and tissues in order to obtain information about the health of a patient or cause of death.',

    },
    3213: {
        name: 'Pharmaceutical Technicians and Assistants',
        description: 'Pharmaceutical technicians and assistants perform a variety of tasks associated with dispensing medicinal products under the guidance of a pharmacist or other health professional.',

    },
    3214: {
        name: 'Medical and Dental Prosthetic Technicians',
        description: 'Medical and dental prosthetic technicians design, fit, service and repair medical and dental devices and appliances following prescriptions or instructions established by a health professional. They may service a wide range of support instruments to correct physical medical or dental problems such as neck braces, orthopaedic splints, artificial limbs, hearing aids, arch supports, dentures, and dental crowns and bridges.',

    },
    322: {
        name: 'Nursing and Midwifery Associate Professionals',
        description: 'Nursing and midwifery associate professionals provide basic nursing and personal care for people who are physically or mentally ill, disabled or infirm, and others in need of care due to potential risks to health including before, during and after childbirth. They generally work under the supervision of, and in support of implementation of health care, treatment and referrals plans established by, medical, nursing, midwifery and other health professionals.',

    },
    3221: {
        name: 'Nursing Associate Professionals',
        description: 'Nursing associate professionals provide basic nursing and personal care for people in need of such care due to effects of ageing, illness, injury or other physical or mental impairment. They generally work under the supervision of, and in support of implementation of health care, treatment and referrals plans established by, medical, nursing and other health professionals.',

    },
    3222: {
        name: 'Midwifery Associate Professionals',
        description: 'Midwifery associate professionals provide basic health care and advice before, during and after pregnancy and childbirth. They implement care, treatment and referral plans usually established by medical, midwifery and other health professionals.',

    },
    323: {
        name: 'Traditional and Complementary Medicine Associate Professionals',
        description: 'Traditional and complementary medicine associate professionals prevent, care for and treat human physical and mental illnesses, disorders and injuries using herbal and other therapies based on theories, beliefs and experiences originating in specific cultures. They administer treatments using traditional techniques and medicaments, either acting independently or according to therapeutic care plans established by a traditional medicine or other health professional.',

    },
    3230: {
        name: 'Traditional and Complementary Medicine Associate Professionals',
        description: 'Traditional and complementary medicine associate professionals prevent, care for and treat human physical and mental illnesses, disorders and injuries using herbal and other therapies based on theories, beliefs and experiences originating in specific cultures. They administer treatments using traditional techniques and medicaments, either acting independently or according to therapeutic care plans established by a traditional medicine or other health professional.',

    },
    324: {
        name: 'Veterinary Technicians and Assistants',
        description: 'Veterinary technicians and assistants carry out advisory, diagnostic, preventive and curative veterinary tasks, more limited in scope and complexity than those carried out by veterinarians. They care for animals under treatment and in temporary residence at veterinary facilities and assist veterinarians to perform procedures and operations.',

    },
    3240: {
        name: 'Veterinary Technicians and Assistants',
        description: 'Veterinary technicians and assistants carry out advisory, diagnostic, preventive and curative veterinary tasks more limited in scope and complexity than those carried out by, and with the guidance of, veterinarians. They care for animals under treatment and in temporary residence at veterinary facilities, perform routine procedures and assist veterinarians to perform procedures and operations.',

    },
    325: {
        name: 'Other Health Associate Professionals',
        description: 'Other health associate professionals perform technical tasks and provide support services in dentistry, medical records administration, community health, the correction of reduced visual acuity, physiotherapy, environmental health, emergency medical treatment and other activities to support and promote human health.',

    },
    3251: {
        name: 'Dental Assistants and Therapists',
        description: 'Dental assistants and therapists provide basic dental care services for the prevention and treatment of diseases and disorders of the teeth and mouth, according to care plans and procedures established by a dentist or other oral health professional.',

    },
    3252: {
        name: 'Medical Records and Health Information Technicians',
        description: 'Medical records and health information technicians develop, maintain and implement health records processing, storage and retrieval systems in medical facilities and other health care settings to meet the legal professional, ethical and administrative records-keeping requirements of health services delivery.',

    },
    3253: {
        name: 'Community Health Workers',
        description: 'Community health workers provide health education, referral and follow up, case management, basic preventive health care and home visiting services to specific communities. They provide support and assistance to individuals and families in navigating the health and social services system.',

    },
    3254: {
        name: 'Dispensing Opticians',
        description: 'Dispensing opticians design, fit and dispense optical lenses based on a prescription from an ophthalmologist or optometrist for the correction of reduced visual acuity. They service corrective eyeglasses, contact lenses, low-vision aids and other optical devices.',

    },
    3255: {
        name: 'Physiotherapy Technicians and Assistants',
        description: 'Physiotherapy technicians and assistants provide physical therapeutic treatments to patients in circumstances where functional movement is threatened by injury, disease or impairment. Therapies are usually provided as per rehabilitative plans established by a physiotherapist or other health professional.',

    },
    3256: {
        name: 'Medical Assistants',
        description: 'Medical assistants perform basic clinical and administrative tasks to support patient care under the direct supervision of a medical practitioner or other health professional.',

    },
    3257: {
        name: 'Environmental and Occupational Health Inspectors and Associates',
        description: 'Environmental and occupational health inspectors and associates investigate the implementation of rules and regulations relating to environmental factors that may affect human health, safety in the workplace, and safety of processes for the production of goods and services. They may implement and evaluate programmes to restore or improve safety and sanitary conditions under the supervision of a health professional.',

    },
    3258: {
        name: 'Ambulance Workers',
        description: 'Ambulance workers provide emergency health care to patients who are injured, sick, infirm, or otherwise physically or mentally impaired prior to and during transport to medical facilities.',

    },
    3259: {
        name: 'Health Associate Professionals Not Elsewhere Classified',
        description: 'This unit group covers health associate professionals not classified elsewhere in Sub-major Group 32: Health Associate Professionals. For instance, the group includes occupations such as HIV counsellor, family planning counsellor and other health associate professionals.',

    },
    33: {
        name: 'Business and Administration Associate Professionals',
        description: 'Business and administration associate professionals perform mostly technical tasks connected with the practical application of knowledge relating to financial accounting and transaction matters, mathematical calculations, human resource development, selling and buying financial instruments, specialized secretarial tasks, and enforcing or applying government rules. Also included are workers who provide business services such as customs clearance, conference planning, job placements, buying and selling real estate or bulk commodities, and serving as agents for performers such as athletes and artists. Competent performance in most occupations in this sub-major group requires skills at the third ISCO skill level.',

    },
    331: {
        name: 'Financial and Mathematical Associate Professionals',
        description: 'Financial and mathematical associate professionals place a value on various items and property, maintain records of financial transactions, analyse loan application information and render a decision, buy and sell financial instruments, and perform mathematical and related calculations.',

    },
    3311: {
        name: 'Securities and Finance Dealers and Brokers',
        description: 'Securities and finance dealers and brokers buy and sell securities, stocks, bonds and other financial instruments, and deal on the foreign exchange, on spot, or on futures markets, on behalf of their own company or for customers on a commission basis. They recommend transactions to clients or senior management.',

    },
    3312: {
        name: 'Credit and Loans Officers',
        description: 'Credit and loans officers analyse and evaluate financial information on applications for credit and loans and determine approval or disapproval of the client for the credit or loan, or recommend to management approval or disapproval.',

    },
    3313: {
        name: 'Accounting Associate Professionals',
        description: 'Accounting associate professionals maintain complete records of financial transactions of an undertaking and verify accuracy of documents and records relating to such transactions.',

    },
    3314: {
        name: 'Statistical, Mathematical and Related Associate Professionals',
        description: 'Statistical, mathematical and related associate professionals assist in planning the collection, processing and presentation of statistical, mathematical or actuarial data and in carrying out these operations, usually working under the guidance of statisticians, mathematicians and actuaries.',

    },
    3315: {
        name: 'Valuers and Loss Assessors',
        description: 'Valuers and loss assessors value property and various goods and assess losses covered by insurance policies.',

    },
    332: {
        name: 'Sales and Purchasing Agents and Brokers',
        description: 'Sales and purchasing agents and brokers represent companies, governments and other organizations to buy and sell commodities, goods, insurance, shipping and other services to industrial, professional, commercial or other establishments, or act as independent agents to bring together buyers and sellers of commodities and services.',

    },
    3321: {
        name: 'Insurance Representatives',
        description: 'Insurance representatives advise on and sell life, accident, automobile, liability, endowment, fire, marine and other types of insurance to new and established clients.',

    },
    3322: {
        name: 'Commercial Sales Representatives',
        description: 'Commercial sales representatives represent companies to sell various goods and services to businesses and other organizations and provide product-specific information as required.',

    },
    3323: {
        name: 'Buyers',
        description: 'Buyers buy goods and services for use or resale on behalf of industrial, commercial, government or other establishments and organizations.',

    },
    3324: {
        name: 'Trade Brokers',
        description: 'Trade brokers buy and sell commodities and shipping services, usually in bulk, on behalf of their own company or for customers on a commission basis.',

    },
    333: {
        name: 'Business Services Agents',
        description: 'Business service agents establish contacts to sell various business services such as advertising space in the media; carry out customs clearance activities ensuring that necessary documents are in order; match jobseekers with vacancies; find workers for employers; arrange contracts for the performance of athletes, entertainers and artists, as well as for the publication of books, the production of plays, or the recording, performance and sale of music; and plan and organize conferences and similar events.',

    },
    3331: {
        name: 'Clearing and Forwarding Aents',
        description: 'Clearing and forwarding agents carry out customs clearing procedures and ensure that insurance, export/import licences and other formalities are in order.',

    },
    3332: {
        name: 'Conference and Event Planners',
        description: 'Conference and event planners organize and coordinate services for conferences, events, functions, banquets and seminars.',

    },
    3333: {
        name: 'Employment agents and contractors',
        description: 'Employment agents and contractors match jobseekers with vacancies, find workers for employers and contract labour for particular projects at the request of enterprises and other organizations including government and other institutions, or find places for jobseekers for a commission.',

    },
    3334: {
        name: 'Real Estate Agents and Property Managers',
        description: 'Real estate agents and property managers arrange the sale, purchase, rental and lease of real property, usually on behalf of clients and on a commission basis.',

    },
    3339: {
        name: 'Business Services Agents Not Elsewhere Classified',
        description: 'This unit group covers business services agents not classified elsewhere in Minor Group 333: Business Services Agents. For instance, the group includes those who establish business contacts, sell business services such as advertising space in the media, arrange contracts for performances of athletes, entertainers and artists, for the publication of books, the production of plays, or the recording, performance and sale of music, sell property and goods by auction and who design and organize package and group tours.',

    },
    334: {
        name: 'Administrative and Specialized Secretaries',
        description: 'Administrative and specialized secretaries provide organizational, communication and documentation support services, utilizing specialized knowledge of the business activity of the organization in which they are employed. They take supervisory responsibility for office clerks in the organization.',

    },
    3341: {
        name: 'Office Supervisors',
        description: 'Office supervisors supervise and coordinate the activities of workers in Major Group 4: Clerical Support Workers.',

    },
    3342: {
        name: 'Legal Secretaries',
        description: 'Legal secretaries apply specialized knowledge of legal terminology and procedures in providing support to legal professionals with communication, documentation and internal managerial co-ordination activities, in law offices, legal departments of large firms and governments.',

    },
    3343: {
        name: 'Administrative and Executive Secretaries',
        description: 'Administrative and executive secretaries perform liaison, coordination and organizational tasks in support of managers and professionals and/or prepare correspondence, reports and records of proceedings and other specialized documentation.',

    },
    3344: {
        name: 'Medical Secretaries',
        description: 'Medical secretaries, using specialized knowledge of medical terminology and health care delivery procedures, assist health professionals and other workers by performing a variety of communication, documentation, administrative and internal coordination functions, to support health workers in medical facilities and other health-care related organizations.',

    },
    335: {
        name: 'Government Regulatory Associate Professionals',
        description: 'Government regulatory associate professionals administer, enforce or apply relevant government rules and regulations relating to national borders, taxes and social benefits; investigate facts and circumstances relating to crimes; and issue or examine applications for licences or authorizations in connection with travel, exports and imports of goods, establishment of businesses, erection of buildings and other activities subject to government regulations.',

    },
    3351: {
        name: 'Customs and Border Inspectors',
        description: 'Customs and border inspectors check persons and vehicles crossing national borders to administer and enforce relevant government rules and regulations.',

    },
    3352: {
        name: 'Government Tax and Excise Officials',
        description: 'Government tax and excise officials examine tax returns, bills of sale and other documents to determine the type and amount of taxes, duties and other types of fees to be paid by individuals or businesses, referring exceptional or important cases to accountants, senior government officials or Managers.',

    },
    3353: {
        name: 'Government Social Benefits Officials',
        description: 'Government social benefits officials examine applications for government, financial or service programmes to determine eligibility and amount of benefit or appropriate services, referring exceptional or important cases to senior government officials or managers.',

    },
    3354: {
        name: 'Government Licensing Officials',
        description: 'Government licensing officials examine applications for licences to export or import goods, set up businesses, build houses or other structures, or to obtain passports, determine eligibility of applications for issuing licences or passports, and identify specific conditions or restrictions to be attached to licences issued, referring exceptional or important cases to senior government officials or managers.',

    },
    3355: {
        name: 'Police Inspectors and Detectives',
        description: 'Police inspectors and detectives investigate facts and circumstances relating to crimes committed in order to identify suspected offenders and obtain information not readily available or apparent concerning establishments or the circumstances and behaviour of persons, mostly in order to prevent crimes.',

    },
    3359: {
        name: 'Government Regulatory AssociatePprofessionals Not Elsewhere Classified',
        description: 'This unit group covers government regulatory associate professionals not classified elsewhere. For instance the group includes agricultural, fisheries, forestry, prices, wages and weights and measures inspectors.',

    },
    34: {
        name: 'Legal, Social, Cultural and Related Associate Professionals',
        description: 'Legal, social, cultural and related associate professionals perform technical tasks connected with the practical application of knowledge relating to legal services, social work, culture, food preparation, sport and religion. Competent performance in most occupations in this sub-major group requires skills at the third ISCO skill level.',

    },
    341: {
        name: 'Legal, Social and Religious Associate Professionals',
        description: 'Legal, social and religious associate professionals provide technical and practical services and support functions in legal processes and investigations, social and community assistance programmes and religious activities.',

    },
    3411: {
        name: 'Legal and Related Associate Professionals',
        description: 'Legal and related associate professionals perform support functions in courts of law or in law offices, provide services related to such legal matters as insurance contracts, the transferring of property and the granting of loans and other financial transactions or conduct investigations for clients.',

    },
    3412: {
        name: 'Social Work Associate Professionals',
        description: 'Social work associate professionals administer and implement social assistance programmes and community services and assist clients to deal with personal and social problems.',

    },
    3413: {
        name: 'Religious Associate Professionals',
        description: 'Religious associate professionals provide support to ministers of religion or to a religious community, undertake religious works, preach and propagate the teachings of a particular religion and endeavour to improve well-being through the power of faith and spiritual advice.',

    },
    342: {
        name: 'Sports and Fitness Workers',
        description: 'Sports and fitness workers prepare for and compete in sporting events for financial gain, train amateur and professional sportsmen and women to enhance performance; promote participation and standards in sport; organize and officiate sporting events; and provide instruction, training and supervision for various forms of exercise and other recreational activities.',

    },
    3421: {
        name: 'Athletes and Sports Players',
        description: 'Athletes and sport players participate in competitive sporting events. They train and compete, either individually or as part of a team, in their chosen sport.',

    },
    3422: {
        name: 'Sports Coaches, Instructors and Officials',
        description: 'Sports coaches, instructors and officials work with amateur and professional sportspersons to enhance performance, encourage greater participation in sport, and organize and officiate in sporting events according to established rules.',

    },
    3423: {
        name: 'Fitness and Recreation Instructors and Programme Leaders',
        description: 'Fitness and recreation instructors and programme leaders lead, guide and instruct groups and individuals in recreational, fitness or outdoor adventure activities.',

    },
    343: {
        name: 'Artistic, Cultural and Culinary Associate Professionals',
        description: 'Artistic, cultural and culinary associate professionals combine creative skills and technical and cultural knowledge in taking and processing still photographs; design and decoration of theatre sets, shop displays and the interiors of homes; preparation of objects for display; maintenance of library and gallery collections, records and cataloguing systems; creation of menus and preparation and presentation of food; provision of support for stage, film and television production; and in other areas of artistic and cultural endeavour.',

    },
    3431: {
        name: 'Photographers',
        description: 'Photographers operate still cameras to photograph people, events, scenes, materials, products and other subjects.',

    },
    3432: {
        name: 'Interior Designers and Decorators',
        description: 'Interior designers and decorators plan and design commercial, industrial, public, retail and residential building interiors to produce an environment tailored to a purpose, taking into consideration factors that enhance living and working environments and sales promotion. They coordinate and participate in their construction and decoration.',

    },
    3433: {
        name: 'Gallery, Museum and Library Technicians',
        description: 'Gallery, museum and library technicians prepare artworks, specimens and artefacts for collections, arrange and construct gallery exhibits, and assist librarians to organize and operate systems for handling recorded material and files.',

    },
    3434: {
        name: 'Chefs',
        description: 'Chefs design menus, create dishes and oversee the planning, organization preparation and cooking of meals in hotels, restaurants and other eating places, on board ships, on passenger trains and in private households.',

    },
    3435: {
        name: 'Other Artistic and Cultural Associate Professionals',
        description: 'This unit group covers artistic and cultural associate professionals not classified elsewhere in Minor Group 343: Artistic, Cultural and Culinary Associate Professionals. For instance, those who assist directors or actors with staging of theatrical, motion picture, television or commercial productions are classified here.',

    },
    35: {
        name: 'Information and Communications Technicians',
        description: 'Information and communications technicians provide support for the day-to-day running of computer systems, communications systems and networks and perform technical tasks related to telecommunications, broadcast of image and sound as well as other types of telecommunications signals on land, sea or in aircraft. Competent performance in most occupations in this sub-major group requires skills at the third ISCO skill level.',

    },
    351: {
        name: 'Information and Communications Technology Operations and User Support Technicians',
        description: 'Information and communications technology operations and user support technicians provide support for the day-to-day running of communications systems, computer systems and networks, and provide technical assistance to users',

    },
    3511: {
        name: 'Information and Communications Technology Operations Technicians',
        description: 'Information and communications technology operations technicians support the day-to-day processing, operation and monitoring of information and communications technology systems, peripherlas, hardware, software and related computer equipment to ensure optimal performance and identify any problems.',

    },
    3512: {
        name: 'Information and Communications Technology User Support Technicians',
        description: 'Information and communications technology user support technicians provide technical assistance to users, either directly or by telephone, email or other electronic means, including diagnosing and resolving issues and problems with software, hardware, computer peripheral equipment, networks, databases and the Internet, and providing guidance and support in the deployment, installation and maintenance of systems.',

    },
    3513: {
        name: 'Computer Network and Systems Technicians',
        description: 'Computer network and systems technicians establish, operate and maintain network and other data communications systems.',

    },
    3514: {
        name: 'Web Technicians',
        description: 'Web technicians maintain, monitor and support the optimal functioning of Internet and Intranet websites and web server hardware and software.',

    },
    352: {
        name: 'Telecommunications and Broadcasting Technicians',
        description: 'Telecommunications and broadcasting technicians control technical functioning of equipment to record and edit images and sound and for transmitting radio and television broadcasts of images and sounds, as well as other types of telecommunication signals on land, sea or in aircraft; perform technical tasks connected with telecommunications engineering research and with the design, manufacture, assembly, construction, operation, maintenance and repair of telecommunications systems.',

    },
    3521: {
        name: 'Broadcasting and Audio-visual Technicians',
        description: 'Broadcasting and audio-visual technicians control technical functioning of equipment to record and edit images and sound and for transmitting radio and television broadcasts of images and sounds, as well as other types of telecommunication signals on land, sea or in aircraft.',

    },
    3522: {
        name: 'Telecommunications Engineering Technicians',
        description: 'Telecommunications engineering technicians perform technical tasks connected with telecommunications engineering research, as well as with the design, manufacture, assembly, construction, operation, maintenance and repair of telecommunications systems.',

    },
    4: {
        name: 'Clerical Support Workers',
        description: 'Clerical support workers record, organise, store, compute and retrieve information, and perform a number of clerical duties in connection with money-handling operations, travel arrangements, requests for information, and appointments. Competent performance in most occupations in this major group requires skills at the second ISCO skill level.',

    },
    41: {
        name: 'General and Keyboard Clerks',
        description: 'General and keyboard clerks record, organize, store and retrieve information and perform a wide range of clerical and administrative tasks according to established procedures. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    411: {
        name: 'General Office Clerks',
        description: 'General office clerks perform a range of clerical and administrative tasks according to established procedures.',

    },
    4110: {
        name: 'General Office Clerks',
        description: 'General office clerks perform a range of clerical and administrative tasks according to established procedures.',

    },
    412: {
        name: 'Secretaries (general)',
        description: 'Secretaries (general) use typewriters, personal computers or other word-processing equipment to transcribe correspondence and other documents, check and format documents prepared by other staff, deal with incoming and outgoing mail, screen requests for meetings or appointments, and perform a variety of administrative support tasks.',

    },
    4120: {
        name: 'Secretaries (general)',
        description: 'Secretaries (general) use typewriters, personal computers or other word-processing equipment to transcribe correspondence and other documents, check and format documents prepared by other staff, deal with incoming and outgoing mail, screen requests for meetings or appointments, and perform a variety of administrative support tasks.',

    },
    413: {
        name: 'Keyboard Operators',
        description: 'Keyboard operators input and process text and data, and prepare, edit and generate documents for storage, processing, publication and transmission.',

    },
    4131: {
        name: 'Typists and Word Processing Operators',
        description: 'Typists and word processing operators type, edit and print text using typewriters, personal computers or other word processors, and record oral or written matter in shorthand.',

    },
    4132: {
        name: 'Data Entry Clerks',
        description: 'Data entry clerks enter coded, statistical, financial and other numerical data into electronic equipment, computerized databases, spreadsheets or other data repositories using a keyboard, mouse, optical scanner, speech recognition software or other data entry tools. They enter data into mechanical and electronic devices to perform mathematical calculations.',

    },
    42: {
        name: 'Customer Services Clerks',
        description: 'Customer services clerks deal with clients in connection with money-handling operations, travel arrangements, requests for information, making appointments, operating telephone switchboards, and interviewing for surveys or to complete applications for eligibility for services. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    421: {
        name: 'Tellers, Money Collectors and Related Clerks',
        description: 'Tellers, money collectors and related clerks perform money-handling operations in establishments relating to banking, postal services, betting or gambling, pawning and debt-collecting.',

    },
    4211: {
        name: 'Bank Tellers and Related Clerks',
        description: 'Bank tellers and related clerks deal directly with clients of banks or post offices in connection with receiving, changing and paying out money, or providing mail services.',

    },
    4212: {
        name: 'Bookmakers, Croupiers and Related Gaming Workers',
        description: 'Bookmakers and croupiers and related gambling workers determine odds and receive and pay off bets on results of sporting or other events, or conduct games of chance in gambling establishments. ',

    },
    4213: {
        name: 'Pawnbrokers and Money-lenders',
        description: 'Pawnbrokers and money-lenders lend money against articles deposited as pledges, or against property or other security.',

    },
    4214: {
        name: 'Debt Collectors and Related Workers',
        description: 'Debt collectors and related workers collect payments on overdue accounts and bad cheques and collect charity payments.',

    },
    422: {
        name: 'Client Information Workers',
        description: 'Client information clerks provide or obtain information in person, by telephone or electronic means such as email in connection with making travel arrangements, describing the products or services of an organization, registering and greeting guests and visitors, making appointments, connecting telephone calls and collecting information from survey respondents or applicants for services.',

    },
    4221: {
        name: 'Travel Consultants and Clerks',
        description: 'Travel consultants and clerks provide information about travel destinations, arrange travel itineraries, obtain travel and accommodation reservations and register passengers at check-in and departure.',

    },
    4222: {
        name: 'Contact Centre Information Clerks',
        description: 'Contact centre information clerks provide advice and information to clients, respond to queries regarding a companyРІР‚в„ўs or an organizationРІР‚в„ўs goods, services or policies, and process financial transactions using the telephone or electronic communications media, such as email. They are located in premises that may be remote from clients or other operations of the organizations or companies about whom information is provided.',

    },
    4223: {
        name: 'Telephone Switchboard Operators',
        description: 'Telephone switchboard operators operate telephone communications switchboards and consoles to establish telephone connections, receive caller inquiries and service problem reports, and record and relay messages to staff or clients.',

    },
    4224: {
        name: 'Hotel Receptionists',
        description: 'Hotel receptionists welcome and register guests in hotels and other establishments that provide accommodation services. They assign rooms, issue keys, provide information concerning the services provided, make room reservations, keep a record of rooms available for occupancy and present statements of charges to departing guests and receive payment.',

    },
    4225: {
        name: 'Inquiry Clerks',
        description: 'Inquiry clerks respond to personal, written, electronic mail, and telephone inquiries and complaints about the organizationРІР‚в„ўs goods, services and policies, provide information and refer people to other sources. They are employed in locations which put them in direct contact with clients or with the production of the goods and services provided.',

    },
    4226: {
        name: 'Receptionists (general)',
        description: 'Receptionists (general) receive and welcome visitors, clients or guests and respond to inquiries and requests including arranging for appointments.',

    },
    4227: {
        name: 'Survey and Market Research Interviewers',
        description: 'Survey and market research interviewers interview people and record their responses to survey and market research questions on a range of topics.',

    },
    4229: {
        name: 'Client Information Workers Not Elsewhere Classified',
        description: 'This unit group includes client information workers not included elsewhere in Minor Group 422: Client information workers. For instance, it includes workers who obtain and process information from clients needed to determine eligibility for services.',

    },
    43: {
        name: 'Numerical and Material Recording Clerks',
        description: 'Numerical and material recording clerks obtain, compile and compute accounting, bookkeeping, statistical, financial, and other numerical data, and take charge of cash transactions incidental to business matters. Some occupations classified here keep records of goods produced, purchased, stocked, dispatched, and of materials needed at specified production dates, or keep records of operational aspects and coordinate the timing of passenger and freight transport. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    431: {
        name: 'Numerical Clerks',
        description: 'Numerical clerks obtain, compile and compute accounting, bookkeeping, statistical, financial, and other numerical data, and take charge of cash transactions incidental to business matters.',

    },
    4311: {
        name: 'Accounting and Bookkeeping Clerks',
        description: 'Accounting and bookkeeping clerks compute, classify and record numerical data to keep financial records complete. They perform any combination of routine calculating, posting and verifying duties to obtain primary financial data for use in maintaining accounting records.',

    },
    4312: {
        name: 'Statistical, Finance and Insurance Clerks',
        description: 'Statistical, finance and insurance clerks obtain, compile and compute statistical or actuarial data or perform clerical tasks relating to the transactions of insurance establishments, banks and other financial establishments.',

    },
    4313: {
        name: 'Payroll Clerks',
        description: 'Payroll clerks collect, verify and process payroll information and compute pay and benefit entitlements for employees within a department, company or other establishment.',

    },
    432: {
        name: 'Material Recording and Transport Clerks',
        description: 'Material recording and transport clerks keep records of goods produced, purchased, stocked and dispatched, and of materials needed at specified production dates, or keep records of operational aspects and coordinate the timing of passenger and freight transport.',

    },
    4321: {
        name: 'Stock Clerks',
        description: 'Stock clerks maintain records of goods produced and production materials received, weighed, issued, dispatched or put into stock.',

    },
    4322: {
        name: 'Production Clerks',
        description: 'Production clerks compute quantities of materials required at specified dates for manufacturing, construction and similar production programmes, and prepare and check production operation schedules.',

    },
    4323: {
        name: 'Transport Clerks',
        description: 'Transport clerks keep records of operational aspects and coordinate the timing of train, road and air passenger and freight transport, and prepare reports for management.',

    },
    44: {
        name: 'Other Clerical Support Workers',
        description: 'Other clerical support workers sort and deliver mail, file documents, prepare information for processing, maintain personnel records, check material for consistency with original source material, assist persons who cannot read or write, and perform various other specialized clerical duties. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    441: {
        name: 'Other Clerical Support Workers',
        description: 'Other clerical support workers sort and deliver mail, file documents, prepare information for processing, maintain personnel records, check material for consistency with original source material, assist persons who cannot read or write, and perform various other specialized clerical duties.',

    },
    4411: {
        name: 'Library Clerks',
        description: 'Library clerks issue and receive library materials, sort and shelve books, sound and vision recordings, periodicals, journals, magazines and newspapers and provide general library information to library users.',

    },
    4412: {
        name: 'Mail Carriers and Sorting Clerks',
        description: 'Mail carriers and sorting clerks perform sorting, recording, delivery and other duties in connection with mail services from post offices or related organizations, as well as from or within an establishment.',

    },
    4413: {
        name: 'Coding, Proofreading and Related Clerks',
        description: 'Coding, proofreading and related clerks convert information into codes, verify and correct proofs, and perform a number of miscellaneous clerical duties.',

    },
    4414: {
        name: 'Scribes and Related Workers',
        description: 'Scribes and related workers write letters and complete forms on behalf of persons who are unable to read or write.',

    },
    4415: {
        name: 'Filing and Copying Clerks',
        description: 'Filing and copying clerks file correspondence, cards, invoices, receipts and other records in alphabetical or numerical order or according to the filing system used. They locate and remove material from file when requested and photocopy, scan or fax documents.',

    },
    4416: {
        name: 'Personnel Clerks',
        description: 'Personnel clerks maintain and update personnel records such as information on transfers and promotions, performance evaluations, employee leave taken and accumulated, salaries, qualifications and training.',

    },
    4419: {
        name: 'Clerical Support Workers Not Elsewhere Classified',
        description: 'This unit group covers clerical support workers not classified elsewhere in Major Group 4: Clerical Support Workers. For instance, the group includes, correspondence clerks, press clippers and publication clerks.',

    },
    5: {
        name: 'Service and Sales Workers',
        description: 'Service and sales workers provide personal and protective services related to travel, housekeeping, catering, personal care, or protection against fire and unlawful acts, or demonstrate and sell goods in wholesale or retail shops and similar establishments, as well as at stalls and on markets. Competent performance in most occupations in this major group requires skills at the second ISCO skill level.',

    },
    51: {
        name: 'Personal Service Workers',
        description: 'Personal service workers provide personal services related to travel, housekeeping, catering and hospitality, hairdressing and beauty treatment, animal care grooming and training, companionship and other services of a personal nature. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    511: {
        name: 'Travel Attendants, Conductors and Guides',
        description: 'Travel attendants, conductors and guides provide various personal services in connection with travelling by aircraft, train, ship, bus or other vehicle, and escorting individuals and groups on travel tours, sightseeing visits and excursions.',

    },
    5111: {
        name: 'Travel Attendants and Travel Stewards',
        description: 'Travel attendants and travel stewards ensure the comfort and safety of passengers, serve meals and beverages and render personal services, usually on aircraft and on board ships. They may plan and coordinate housekeeping and social activities on ships.',

    },
    5112: {
        name: 'Transport Conductors',
        description: 'Transport conductors check and issue tickets and ensure the safety and comfort of passengers on trains, trams, buses and other public transport vehicles.',

    },
    5113: {
        name: 'Travel Guides',
        description: 'Travel guides accompany individuals or groups on trips, sightseeing tours and excursions and on tours of places of interest such as historical sites, industrial establishments and theme parks. They describe points of interest and provide background information on interesting features.',

    },
    512: {
        name: 'Cooks',
        description: 'Cooks plan, organize, prepare and cook meals, according to recipes or under the supervision of chefs, in hotels, restaurants and other eating places, on board ships, on passenger trains and in private households.',

    },
    5120: {
        name: 'Cooks',
        description: 'Cooks plan, organize, prepare and cook meals, according to recipes or under the supervision of chefs, in hotels, restaurants and other eating places, on board ships, on passenger trains and in private households.',

    },
    513: {
        name: 'Waiters and Bartenders',
        description: 'Waiters and bartenders serve food and beverages in commercial dining and drinking places, clubs, institutions and canteens, on board ships and on passenger trains.',

    },
    5131: {
        name: 'Waiters',
        description: 'Waiters serve food and beverages at tables in dining and drinking places, clubs, institutions and canteens, on board ships and on passenger trains.',

    },
    5132: {
        name: 'Bartenders',
        description: 'Bartenders prepare, mix and serve alcoholic and non-alcoholic drinks directly to customers over a bar or counter, or through waiters.',

    },
    514: {
        name: 'Hairdressers, Beauticians and Related Workers',
        description: 'Hairdressers, beauticians and related workers cut and dress hair, shave and trim beards, give beauty treatment, apply cosmetics and make-up and give other kinds of treatment to individuals in order to improve their appearance.',

    },
    5141: {
        name: 'Hairdressers',
        description: 'Hairdressers cut, style, colour, straighten and permanently wave hair, shave or trim facial hair and treat scalp conditions.',

    },
    5142: {
        name: 'Beauticians and Related Workers',
        description: 'Beauticians and related workers, give facial and body beauty treatments, apply cosmetics and make-up and give other kinds of treatment to individuals in order to improve their appearance.',

    },
    515: {
        name: 'Building and Housekeeping Supervisors',
        description: 'Building and housekeeping supervisors coordinate, schedule and supervise the work of cleaners and other housekeeping staff in commercial, industrial and residential premises. They take responsibility for housekeeping and caretaking functions in hotels, offices, apartments, houses and private dwellings.',

    },
    5151: {
        name: 'Cleaning and Housekeeping Supervisors in Offices, Hotels and Other Establishments',
        description: 'Cleaning and housekeeping supervisors in offices, hotels and other establishments organize, supervise and carry out housekeeping functions in order to keep clean and tidy the interiors, fixtures and facilities in these establishments.',

    },
    5152: {
        name: 'Domestic Housekeepers',
        description: 'Domestic housekeepers organize, supervise and carry out housekeeping functions in private households and small accommodation establishments with or without the support of subordinate staff.',

    },
    5153: {
        name: 'Building Caretakers',
        description: 'Building caretakers take care of apartment houses, hotels, offices, churches and other buildings and maintain them and associated grounds in a clean and orderly condition. They may supervise other workers and contractors depending on the size and nature of the building concerned.',

    },
    516: {
        name: 'Other Personal Services Workers',
        description: 'Other personal services workers recount past and predict future events in personsРІР‚в„ў lives, provide companionship and other personal services, groom, train and care for animals, provide embalming and funeral services and train people to drive vehicles.',

    },
    5161: {
        name: 'Astrologers, Fortune-tellers and Related Workers',
        description: 'Astrologers, fortune-tellers and related workers recount past and predict future events in personsРІР‚в„ў lives by practicing astrology, on the basis of characteristics of the clientsРІР‚в„ў palms, samples of playing cards drawn or other techniques.',

    },
    5162: {
        name: 'Companions and Valets',
        description: 'Companions and valets provide companionship and attend to various needs of the client or employer.',

    },
    5163: {
        name: 'Undertakers and Embalmers',
        description: 'Undertakers and embalmers arrange funerals and perform various tasks in the disposal of human bodies.',

    },
    5164: {
        name: 'Pet Groomers and Animal Care Workers',
        description: 'Pet groomers and animal care workers feed, handle, train and groom animals and assist veterinarians, animal health technologists and technicians in veterinary facilities, animal shelters, breeding and boarding kennels, zoos, laboratories, retail pet shops, riding schools, dog training schools pet grooming and similar establishments.',

    },
    5165: {
        name: 'Driving Instructors',
        description: 'Driving instructors teach people how to drive motor vehicles.',

    },
    5169: {
        name: 'Personal Services Workers Not Elsewhere Classified',
        description: 'This unit group covers personal service workers not classified elsewhere in Sub-major Group 51: Personal Service Workers. For instance, those who provide services and companionship as dancing partners, social escorts and night-club hostesses or hosts are classified here.',

    },
    52: {
        name: 'Sales Workers',
        description: 'Sales workers sell and demonstrate goods in wholesale or retail shops, at stalls and markets, door-to-door, via telephone or customer contact centres. They may record and accept payment for goods and services purchased, and may operate small retail outlets. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    521: {
        name: 'Street and Market Salespersons',
        description: 'Street and market salespersons sell goods from stalls on markets or in streets and prepare and sell hot or cold food and beverages ready for immediate consumption in streets and public places.',

    },
    5211: {
        name: 'Stall and Market Salespersons',
        description: 'Stall and market salespersons sell various goods from stalls in open-air or covered markets or from stalls in streets or other open spaces.',

    },
    5212: {
        name: 'Street Food Salespersons',
        description: 'Street food salespersons prepare and sell, or sell previously prepared, hot or cold foods and beverages ready for immediate consumption in streets and public places such as stations, cinemas or theatres.',

    },
    522: {
        name: 'Shop Aalespersons',
        description: 'Shop salespersons sell a range of goods and services directly to the public or on behalf of retail and wholesale establishments. They explain functions and qualities of these goods and services, and may operate small shops or supervise the activities of shop sales assistants and cashiers.',

    },
    5221: {
        name: 'Shopkeepers',
        description: 'Shopkeepers operate small retail shops either independently or with support from a small number of others.',

    },
    5222: {
        name: 'Shop Supervisors',
        description: 'Shop supervisors supervise and coordinate the activities of shop sales assistants, checkout operators and other workers in retail and wholesale shops such as supermarkets and department stores.',

    },
    5223: {
        name: 'Shop Sales Assistants',
        description: 'Shop sales assistants sell a range of goods and services directly to the public or on behalf of retail and wholesale establishments, and explain the functions and qualities of these goods and services.',

    },
    523: {
        name: 'Cashiers and Ticket Clerks',
        description: 'Cashiers and ticket clerks operate cash registers, optical price scanners, computers or other equipment to record and accept payment for the purchase of goods, services and admissions in settings such as stores, restaurants and ticket offices.',

    },
    5230: {
        name: 'Cashiers and Ticket Clerks',
        description: 'Cashiers and ticket clerks operate cash registers, optical price scanners, computers or other equipment to record and accept payment for the purchase of goods, services and admissions in settings such as stores, restaurants and ticket offices.',

    },
    524: {
        name: 'Other Sales Workers',
        description: 'Other sales workers display, demonstrate, display and sell goods, food and services, usually to the general public, in contexts other than sales in markets, streets and shops. This group includes sales workers not classified in Minor Groups 521: Street and Market Salespersons, 522: Shop Salespersons and 523: Cashiers and Ticket Clerks.',

    },
    5241: {
        name: 'Fashion and Other Models',
        description: 'Fashion and other models wear and display clothing and accessories and pose for photographs, film and video, advertising, still photography or for artistic creation.',

    },
    5242: {
        name: 'Sales Demonstrators',
        description: 'Sales demonstrators demonstrate goods at commercial premises, exhibitions and private homes.',

    },
    5243: {
        name: 'Door-to-door Salespersons',
        description: 'Door-to-door salespersons describe, demonstrate and sell goods and services and solicit business for establishments by approaching or visiting potential customers, usually residents in private homes, by going from door to door.',

    },
    5244: {
        name: 'Contact Centre Salespersons',
        description: 'Contact centre salespersons contact existing and prospective customers, using the telephone or other electronic communications media, to promote goods and services, obtain sales and arrange sales visits. They may work from a customer contact centre or from non-centralised premises.',

    },
    5245: {
        name: 'Service Station Attendants',
        description: 'Service station attendants sell fuel, lubricants and other automotive products and provide services such as fuelling, cleaning, lubricating and performing minor repairs to motor vehicles.',

    },
    5246: {
        name: 'Food Service Counter Attendants',
        description: 'Food service counter attendants serve customers at food counters and finish preparation of simple food items in restaurants, cafes, hotels, fast food outlets, cafeterias, hospitals and other settings.',

    },
    5249: {
        name: 'Sales Workers Not Elsewhere Classified',
        description: 'This unit group includes sales workers not classified elsewhere in Sub -major Group 52: Sales Workers.',

    },
    53: {
        name: 'Personal Care Workers',
        description: 'Personal care workers provide care, supervision and assistance for children, patients and elderly, convalescent or disabled persons in institutional and residential settings. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    531: {
        name: "Child Care Workers and Teachers' Aides",
        description: "Child care workers and teachers' aides provide care and supervision for children in schools, residential homes and child care facilities.",

    },
    5311: {
        name: 'Child Care Workers',
        description: 'Child care workers provide care and supervision for children in residential homes and in before-school, after-school, vacation and day care centres.',

    },
    5312: {
        name: "Teachers' Aides",
        description: "Teachers' aides perform non-teaching duties to assist teaching staff, and provide care and supervision for children in schools and pre-schools.",

    },
    532: {
        name: 'Personal Care Workers in Health Services',
        description: 'Personal care workers in health services provide personal care and assistance with mobility and activities of daily living to patients and elderly, convalescent and disabled people in health care and residential settings.',

    },
    5321: {
        name: 'Health Care Assistants',
        description: 'Health care assistants provide direct personal care and assistance with activities of daily living to patients and residents in a variety of health care settings such as hospitals, clinics and residential nursing care facilities. They generally work in implementation of established care plans and practices, and under the direct supervision of medical, nursing or other health professionals or associate professionals.',

    },
    5322: {
        name: 'Home-based Personal Care Workers',
        description: 'Home-based personal care workers provide routine personal care and assistance with activities of daily living to persons who are in need of such care due to effects of ageing, illness, injury, or other physical or mental condition in private homes and other independent residential settings.',

    },
    5329: {
        name: 'Personal Care Workers in Health Services Not Elsewhere Classified',
        description: 'This unit group covers providers of routine health and personal care support services not classified elsewhere in Minor Group 532: Personal Care Workers in Health Services. For instance, the group includes occupations such as dental aide, sterilization aide, hospital orderly, medical imaging assistant and pharmacy aide.',

    },
    54: {
        name: 'Protective Services Workers',
        description: 'Protective services workers protect individuals and property against fire and other hazards, maintain law and order and enforce laws and regulations. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    541: {
        name: 'Protective Services Workers',
        description: 'Protective services workers protect individuals and property against fire and other hazards, maintain law and order and enforce laws and regulations.',

    },
    5411: {
        name: 'Fire Fighters',
        description: 'Fire fighters prevent, fight and extinguish fires and assist in other emergencies, protect life and property and conduct rescue efforts.',

    },
    5412: {
        name: 'Police Officers',
        description: 'Police officers maintain law and order, patrolling public areas, enforcing laws and regulations and arresting suspected offenders.',

    },
    5413: {
        name: 'Prison Guards',
        description: 'Prison guards watch over and maintain order among inmates of prisons, reformatories or penitentiaries.',

    },
    5414: {
        name: 'Security Guards',
        description: 'Security guards patrol or monitor premises to guard property against theft and vandalism. They control access to establishments and maintain order and enforce regulations at public events and within establishments.',

    },
    5419: {
        name: 'Protective Services Workers Not Elsewhere Classified',
        description: 'This unit group covers protective services workers not classified elsewhere in Minor Group 541: Protective Services Workers. For instance, the unit group includes lifeguards, crossing guards and animal control officers.',

    },
    6: {
        name: 'Skilled Agricultural, Forestry and Fishery Workers',
        description: 'Skilled agricultural, forestry and fishery workers grow and harvest field or tree and shrub crops, gather wild fruits and plants, breed, tend or hunt animals, produce a variety of animal husbandry products; cultivate, conserve and exploit forests; breed or catch fish; and cultivate or gather other forms of aquatic life in order to provide food, shelter and income for themselves and their households. Competent performance in most occupations in this major group requires skills at the second ISCO skill level.',

    },
    61: {
        name: 'Market-oriented Skilled Agricultural Workers',
        description: 'Market-oriented skilled agricultural workers plan, organize and perform farming operations to grow and harvest field or tree and shrub crops and produce a variety of animals and animal products for sale or delivery on a regular basis to wholesale buyers, marketing organisations or at markets. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    611: {
        name: 'Market Gardeners and Crop Growers',
        description: 'Market gardeners and crop growers plan, organize and perform operations to grow and harvest field crops, to grow fruit and other tree and shrub crops, to grow garden vegetables and medicinal and other plants; and to produce horticultural and horticultural nurseries products, for sale or delivery on a regular basis to wholesale buyers, marketing organisations or at markets.',

    },
    6111: {
        name: 'Field Crop and Vegetable Growers',
        description: 'Field crop and vegetable growers plan, organize and perform farming operations to grow and harvest various types of field crop such as wheat and other cereals, rice, beetroot, sugar-cane, ground-nuts, tobacco, reed or other field crops and potatoes, cabbages or other field vegetables, for sale or delivery on a regular basis to wholesale buyers, marketing organizations or at markets.',

    },
    6112: {
        name: 'Tree and Shrub Crop Growers',
        description: 'Tree and shrub crop growers plan, organize and perform farming operations to grow and harvest trees and shrubs, such as fruit and nut trees, tea and coffee bushes, grape vines, berry-bearing bushes, cocoa trees and rubber trees, and to collect sap, for sale or delivery on a regular basis to wholesale buyers, marketing organizations or at markets.',

    },
    6113: {
        name: 'Gardeners, Horticultural and Nursery Growers',
        description: 'Gardeners, horticultural and nursery growers plan, organize and perform operations to cultivate and maintain trees, shrubs, flowers and other plants in parks and private gardens, and to produce saplings, bulbs and seeds or grow vegetables and flowers by intensive cultivation techniques, for sale or delivery on a regular basis to wholesale buyers, marketing organizations or at markets.',

    },
    6114: {
        name: 'Mixed Crop Growers',
        description: 'Mixed crop growers plan, organize and perform farming operations to grow and harvest specific combinations of field crops, field vegetables, tree and shrub crops, and garden, horticultural and nursery products, for sale or delivery to wholesale buyers, marketing organizations or at markets.',

    },
    612: {
        name: 'Animal Producers',
        description: 'Animal producers plan, organize and perform farming operations to breed and raise domesticated animals, poultry, insects and non-domesticated animals for the production of meat, dairy products, honey, skins, textiles and other products, or for use as working, sporting or recreational animals, for sale or delivery to wholesale buyers, marketing organizations or at markets.',

    },
    6121: {
        name: 'Livestock and Dairy Producers',
        description: 'Livestock and dairy producers plan, organize and perform farming operations to breed and raise domesticated animals (excluding poultry), such as cattle, sheep, pigs, goats, horses and camels, for the production of meat, milk and other dairy products, skins and wool or for use as working, sporting or recreational animals, for sale or delivery to wholesale buyers, marketing organizations or at markets.',

    },
    6122: {
        name: 'Poultry Producers',
        description: 'Poultry producers plan, organize and perform farming operations to breed and raise chickens, turkeys, geese, ducks and other poultry to produce meat, eggs and breeding stock for sale or delivery to wholesale buyers, marketing organizations or at markets.',

    },
    6123: {
        name: 'Apiarists and Sericulturists',
        description: 'Apiarists and sericulturists plan, organize and perform operations to breed, raise and tend insects such as honey bees, silkworms, and other species to produce honey, beeswax, silk and other products for sale or delivery to wholesale buyers, marketing organizations or at markets.',

    },
    6129: {
        name: 'Animal Producers Not Elsewhere Classified',
        description: 'This unit group covers market-oriented animal producers not classified elsewhere in Minor Group 612: Animal producers. For instance, the group includes those engaged in breeding, raising and tending non-domesticated mammals, game and other birds (except poultry), snails, snakes and other reptiles, as well as various insects and animals used for laboratory tests, for sale or delivery on a regular basis to wholesale buyers, marketing organizations, zoos and circuses, or at markets.',

    },
    613: {
        name: 'Mixed Crop and Animal Producers',
        description: 'Mixed crop and animal producers plan, organize and perform farming operations to grow and harvest field, tree and various other crops, as well as to breed, raise and tend animals and to produce a variety of animal husbandry products, for sale or delivery to wholesale buyers, marketing organizations or at markets.',

    },
    6130: {
        name: 'Mixed Crop and Animal Producers',
        description: 'Mixed crop and animal producers plan, organize and perform farming operations to grow and harvest field, tree and various other crops, as well as to breed, raise and tend animals and to produce a variety of animal husbandry products, for sale or delivery to wholesale buyers, marketing organizations or at markets.',

    },
    62: {
        name: 'Market-Oriented Skilled Forestry, Fishery and Hunting Workers',
        description: 'Market-oriented skilled forestry, fishery and hunting workers plan, organize and perform operations to cultivate, conserve and exploit natural and plantation forests, breed and raise fish, harvest and catch fish; and hunt and trap animals, for sale or delivery on a regular basis to wholesale buyers, marketing organizations or at markets. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    621: {
        name: 'Forestry and Related Workers',
        description: 'Forestry and related workers plan, organize and perform operations to cultivate, conserve and exploit natural and plantation forests.',

    },
    6210: {
        name: 'Forestry and Related Workers',
        description: 'Forestry and related workers plan, organize and perform operations to cultivate, conserve and exploit natural and plantation forests.',

    },
    622: {
        name: 'Fishery Workers, Hunters and Trappers',
        description: 'Fishery workers, hunters and trappers breed and raise fish, harvest and catch fish, and hunt and trap animals, for sale or delivery on a regular basis to wholesale buyers, marketing organizations or at markets.',

    },
    6221: {
        name: 'Aquaculture Workers',
        description: 'Aquaculture workers breed and raise fish and cultivate mussels, oysters and other forms of aquatic life, for sale or delivery on a regular basis to wholesale buyers, marketing organizations or at markets.',

    },
    6222: {
        name: 'Inland and Coastal Waters Fishery Workers',
        description: 'Inland and coastal waters fishery workers, alone or as members of fishing-vessel crews, catch fish or gather other forms of aquatic life in inland or coastal waters for sale or delivery on a regular basis to wholesale buyers, marketing organizations or at markets.',

    },
    6223: {
        name: 'Deep-sea Fishery Workers',
        description: 'Deep-sea fishery workers, as skippers or members of fishing vessel crews, catch deep-sea fish for sale or delivery to wholesale buyers, marketing organizations or at markets.',

    },
    6224: {
        name: 'Hunters and Trappers',
        description: 'Hunters and trappers catch and kill mammals, birds or reptiles mainly for meat, skin, feathers and other products for sale or delivery on a regular basis to wholesale buyers, marketing organizations or at markets.',

    },
    63: {
        name: 'Subsistence Farmers, Fishers, Hunters and Gatherers',
        description: 'Subsistence farmers, fishers, hunters and gatherers grow and harvest field or tree and shrub crops, vegetables and fruit, gather wild fruits, medicinal and other plants, tend or hunt animals, catch fish and gather various forms of aquatic life in order to provide food, shelter and, in some cases, a minimum of cash income for themselves and their households. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    631: {
        name: 'Subsistence Crop Farmers',
        description: 'Subsistence crop farmers grow and harvest field or tree and shrub crops, vegetables and fruit, in order to provide food, shelter and, in some cases, a minimum of cash income for themselves and their households.',

    },
    6310: {
        name: 'Subsistence Crop Farmers',
        description: 'Subsistence crop farmers grow and harvest field or tree and shrub crops, vegetables and fruit, in order to provide food, shelter and, in some cases, a minimum of cash income for themselves and their households.',

    },
    632: {
        name: 'Subsistence Livestock Farmers',
        description: 'Subsistence livestock farmers breed, raise and tend livestock in order to provide food, shelter and, in some cases, a minimum of cash income for themselves and their households.',

    },
    6320: {
        name: 'Subsistence Livestock Farmers',
        description: 'Subsistence livestock farmers breed, raise and tend livestock in order to provide food, shelter and, in some cases, a minimum of cash income for themselves and their households.',

    },
    633: {
        name: 'Subsistence Mixed Crop and Livestock Farmers',
        description: 'Subsistence mixed crop and livestock farmers grow and harvest field or tree and shrub crops, vegetables and fruit, gather wild fruits, medicinal and other plants, tend or hunt animals, and/or catch fish and gather various forms of aquatic life in order to provide food, shelter and, in some cases, a minimum of cash income for themselves and their households.',

    },
    6330: {
        name: 'Subsistence Mixed Crop and Livestock Farmers',
        description: 'Subsistence mixed crop and livestock farmers grow and harvest field or tree and shrub crops, vegetables and fruit; gather wild fruits and medicinal and other plants; tend or hunt animals; and/or catch fish and gather various forms of aquatic life in order to provide food, shelter and, in some cases, a minimum of cash income for themselves and their households.',

    },
    634: {
        name: 'Subsistence Fishers, Hunters, Trappers and Gatherers',
        description: 'Subsistence fishers, hunters, trappers and gatherers gather wild fruits, medicinal and other plants, hunt and trap animals, catch fish and gather various forms of aquatic life in order to provide food, shelter and, in some cases, a minimum of cash income for themselves and their households.',

    },
    6340: {
        name: 'Subsistence Fishers, Hunters, Trappers and Gatherers',
        description: 'Subsistence fishers, hunters, trappers and gatherers gather wild fruits, medicinal and other plants; hunt and trap animals; catch fish and gather various forms of aquatic life in order to provide food, shelter and, in some cases, a minimum of cash income for themselves and their households.',

    },
    7: {
        name: 'Craft and Related Trades Workers',
        description: 'Craft and related trades workers apply specific technical and practical knowledge and skills in the fields to construct and maintain buildings; form metal; erect metal structures; set machine tools or make, fit, maintain and repair machinery, equipment or tools; carry out printing work; and produce or process foodstuffs, textiles and wooden, metal and other articles, including handicraft goods. Competent performance in most occupations in this major group requires skills at the second ISCO skill level.\nThe work is carried out by hand and by hand-powered and other tools which are used to reduce the amount of physical effort and time required for specific tasks, as well as to improve the quality of the products. The tasks call for an understanding of all stages of the production process, the materials and tools used, and the nature and purpose of the final product.',

    },
    71: {
        name: 'Building and Related Trades Workers (excluding Electricians)',
        description: 'Building and related trades workers construct, maintain and repair buildings, erect and repair foundations, walls and structures of brick, stone and similar materials; shape and finish stone for building and other purposes. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.\nThe work is carried out by hand and by hand-powered and other tools which are used to reduce the amount of physical effort and time required for specific tasks, as well as to improve the quality of the products. The tasks call for an understanding of the work organization, the materials and tools used, and the nature and purpose of the final product. ',

    },
    711: {
        name: 'Building Frame and Related Trades Workers',
        description: 'Building frame and related trades workers construct, maintain and repair buildings; erect and repair foundations, walls and structures of brick, stone and similar materials; and shape and finish stone for building and other purposes; and perform miscellaneous construction and building maintenance tasks.',

    },
    7111: {
        name: 'House Builders',
        description: 'House builders erect, maintain and repair houses and similar small buildings using either traditional or modern techniques and materials.',

    },
    7112: {
        name: 'Bricklayers and Related Workers',
        description: 'Bricklayers and related workers lay bricks, pre-cut stones and other types of building blocks in mortar to construct and repair walls, partitions, arches and other structures.',

    },
    7113: {
        name: 'Stonemasons, Stone Cutters, Splitters and Carvers',
        description: 'Stonemasons, stone cutters, splitters and carvers cut and shape hard and soft stone blocks and slabs for the construction and maintenance of stone structures and monumental masonry, and carve designs and figures in stone.',

    },
    7114: {
        name: 'Concrete Placers, Concrete Finishers and Related Workers',
        description: 'Concrete placers, concrete finishers and related workers erect reinforced concrete frameworks and structures, make forms for moulding concrete, reinforce concrete surfaces, cement openings in walls or casings for wells, finish and repair cement surfaces and carry out terrazzo work.',

    },
    7115: {
        name: 'Carpenters and Joiners',
        description: 'Carpenters and joiners cut, shape, assemble, erect, maintain and repair various types of structures and fittings made from wood and other materials.',

    },
    7119: {
        name: 'Building Frame and Related Trades Workers Not Elsewhere Classified',
        description: 'This unit group covers building frame and related trades workers not classified elsewhere in Minor Group 711: Building Frame and Related Trades Workers. For instance, the unit group includes steeplejacks, scaffolders and demolition workers.',

    },
    712: {
        name: 'Building Finishers and Related Trades Workers',
        description: 'Building finishers and related trades workers cover, apply or install, maintain and repair roofs, floors, walls, insulation systems, glass in windows or other frames, as well as plumbing, piping and electrical systems in buildings and other structures.',

    },
    7121: {
        name: 'Roofers',
        description: 'Roofers build and repair roofs on all types of buildings using one or more kinds of material.',

    },
    7122: {
        name: 'Floor Layers and Tile Setters',
        description: 'Floor layers and tile setters install, maintain and repair flooring, and cover floors, walls and other surfaces with carpets, tiles or mosaic panels for decorative or other purposes.',

    },
    7123: {
        name: 'Plasterers',
        description: 'Plasterers install, maintain and repair plasterboard in buildings, and apply decorative and protective coverings of plaster, cement and similar material to the interiors and exteriors of structures.',

    },
    7124: {
        name: 'Insulation Workers',
        description: 'Insulation workers apply and repair insulating materials to buildings, boilers, pipes or refrigeration and air-conditioning equipment.',

    },
    7125: {
        name: 'Glaziers',
        description: 'Glaziers measure, cut, finish, fit and install flat glass and mirrors.',

    },
    7126: {
        name: 'Plumbers and Pipe Fitters',
        description: 'Plumbers and pipe fitters assemble, install, repair and maintain pipe systems, drains, gutters, ducts and related fittings and fixtures for water, gas, drainage, sewerage, heating cooling and ventilation systems, and for hydraulic and pneumatic equipment.',

    },
    7127: {
        name: 'Air Conditioning and Refrigeration Mechanics',
        description: 'Air conditioning and refrigeration mechanics assemble, install, maintain and repair air conditioning and refrigeration systems and equipment.',

    },
    713: {
        name: 'Painters, Building Structure Cleaners and Related Trades Workers',
        description: 'Painters, building structure cleaners and related trades workers prepare surfaces and apply paint and similar materials to buildings and other structures, vehicles or various manufactured articles. They cover interior walls and ceilings with wallpaper, clean chimneys and exterior surfaces of buildings and other structures.',

    },
    7131: {
        name: 'Painters and Related Workers',
        description: 'Painters and related workers prepare surfaces of buildings and other structures for painting, apply protective and decorative coats of paint or similar materials, or cover interior walls and ceilings of buildings with wallpaper or other finishes.',

    },
    7132: {
        name: 'Spray Painters and Varnishers',
        description: 'Spray painters and varnishers operate spray painting and varnishing equipment to apply protective coatings to manufactured items or structures.',

    },
    7133: {
        name: 'Building Structure Cleaners',
        description: 'Building structure cleaners clean exterior surfaces of buildings and other structures, and remove soot from chimneys.',

    },
    72: {
        name: 'Metal, Machinery and Related Trades Workers',
        description: 'Metal, machinery and related trades workers cast, weld, forge and, by other methods, form metal; erect, maintain and repair heavy metal structures; engage in machine-tool setting as well as in fitting, maintaining and repairing machinery, including engines, vehicles, or they produce tools and various non-precious-metal articles. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.\nThe work is carried out by hand and by hand-powered and other tools which are used to reduce the amount of physical effort and time required for specific tasks, as well as to improve the quality of the products. The tasks call for an understanding of the work organisation, the materials and tools used, and the nature and purpose of the final product.',

    },
    721: {
        name: 'Sheet and Structural Metal Workers, Moulders and Welders, and Related Workers',
        description: 'Sheet and structural metal workers, moulders and welders, and related workers, make moulds and cores for casting metal, weld and cut metal parts, make and repair articles of sheet metal, and install, erect, maintain and repair heavy metal structures, tackle, cable-cars and related equipment.',

    },
    7211: {
        name: 'Metal Moulders and Coremakers',
        description: 'Metal moulders and coremakers make moulds and cores for casting metal.',

    },
    7212: {
        name: 'Welders and Flame Cutters',
        description: 'Welders and flame cutters weld and cut metal parts using gas flame, electric arc and other sources of heat to melt and cut, or to melt and fuse metal.',

    },
    7213: {
        name: 'Sheet Metal Workers',
        description: 'Sheet metal workers make, install and repair articles and parts of articles made out of sheet metal such as sheet steel, copper, tin, brass, aluminium, zinc or galvanised iron.',

    },
    7214: {
        name: 'Structural Metal Preparers and Erectors',
        description: 'Structural metal preparers and erectors assemble, erect and dismantle structural metal frames of buildings and other structures.',

    },
    7215: {
        name: 'Riggers and Cable Splicers',
        description: 'Riggers and cable splicers assemble rigging gear to move and position equipment and structural components, or install and maintain cables, ropes and wires on construction sites, buildings or other structures.',

    },
    722: {
        name: 'Blacksmiths, Toolmakers and Related Trades Workers',
        description: 'Blacksmiths, toolmakers and related trades workers, hammer and forge bars, rods or ingots of iron, steel and other metals to make and repair various kinds of tools, equipment and other articles; set machine tools for operators or set and operate various machine tools; and polish and sharpen surfaces.',

    },
    7221: {
        name: 'Blacksmiths, Hammersmiths and Forging Press Workers',
        description: 'Blacksmiths, hammersmiths and forging press workers hammer and forge bars, rods, ingots and plates of iron, steel or other metals, and draw wire to make and repair various kinds of tools, metal articles, pieces of equipment, and agricultural and related implements.',

    },
    7222: {
        name: 'Toolmakers and Related Workers',
        description: 'Toolmakers and related workers make and repair custom-made and specialised tools, sports guns, locks, dies, patterns, machinery components and other metal articles using hand and machine tools to work metal to fine tolerances.',

    },
    7223: {
        name: 'Metal Working Machine Tool Setters and Operators',
        description: 'Metal working machine tool setters and operators set and/or operate various machine tools, working to fine tolerances.',

    },
    7224: {
        name: 'Metal Polishers, Wheel Grinders and Tool Sharpeners',
        description: 'Metal polishers, wheel grinders and tool sharpeners polish and grind metal surfaces and sharpen tools.',

    },
    723: {
        name: 'Machinery Mechanics and Repairers',
        description: 'Machinery mechanics and repairers fit, install, maintain and repair engines, vehicles, agricultural or industrial machinery and similar mechanical equipment.',

    },
    7231: {
        name: 'Motor Vehicle Mechanics and Repairers',
        description: 'Motor vehicle mechanics and repairers fit, install, maintain, service and repair engines and the mechanical and related equipment of passenger cars, delivery trucks, motorcycles and other motor vehicles.',

    },
    7232: {
        name: 'Aircraft Engine Mechanics and Repairers',
        description: 'Aircraft engine mechanics and repairers fit, service, repair and overhaul aircraft engines and assemblies, such as airframes, hydraulic and pneumatic systems.',

    },
    7233: {
        name: 'Agricultural and Industrial Machinery Mechanics and Repairers',
        description: 'Agricultural and industrial machinery mechanics and repairers fit, install, examine, service and repair engines, agricultural and industrial machinery and mechanical equipment, except motor vehicle, aircraft and electric motors.',

    },
    7234: {
        name: 'Bicycle and Related Repairers',
        description: 'Bicycle and related repairers fit, maintain, service and repair the mechanical and related equipment of bicycles, rickshaws, baby carriages, wheelchairs and similar non-motorized transport equipment.',

    },
    73: {
        name: 'Handicraft and Printing Workers',
        description: 'Handicraft and printing workers combine artistic and manual skills to design, produce, maintain and decorate precision instruments, musical instruments, jewellery and other precious-metals, pottery, porcelain and glassware, items made of wood or textile, leather or related materials, and printed products such as books, newspapers and magazines. They apply traditional and/or recently developed techniques to carve, mould, assemble, weave and decorate various articles; to compose and set type prior to printing; to set up and operate printing presses; to bind and finish printed products; and to prepare stencils and operate screen printing equipment. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.\n\nThe work may be carried out by hand or involve the use of hand tools, hand-held power tools and in some cases the set-up and operation of machinery and machine tools. The tasks call for an understanding of the work organisation, the materials and tools used, and the nature and purpose of the final product.',

    },
    731: {
        name: 'Handicraft Workers',
        description: 'Handicraft workers combine artistic and manual skills to design, make, repair, adjust, maintain and decorate precision instruments, musical instruments, jewellery and other precious metals, pottery and porcelain ware. They apply traditional and/or recently developed techniques to carve, mould, assemble, weave and decorate various glass, ceramics, textile, straw, stone, wood and leather articles.',

    },
    7311: {
        name: 'Precision-instrument Makers and Repairers',
        description: 'Precision-instrument makers and repairers make, calibrate, repair, maintain, adjust and install mechanical watches and clocks, and nautical, meteorological, optical and other precision instruments and equipment, and set them for correct performance.',

    },
    7312: {
        name: 'Musical Instrument Makers and Tuners',
        description: 'Musical instrument makers and tuners make, assemble, repair, adjust and restore musical instruments and tune them to the required pitch with hand or power tools. They usually specialize in one type of instrument, such as stringed instruments, brass instruments, reed instruments, pianos, or percussion instruments.',

    },
    7313: {
        name: 'Jewellery and Precious Metal Workers',
        description: 'Jewellery and precious metal workers design, fabricate, adjust, repair or appraise jewellery, ceremonial or religious items, gold, silver, other precious metals, or gems. They cut, file, polish and set precious and semi-precious stones including gems and diamonds and engrave designs on jewellery and precious metal articles. They cut and polish diamonds for industrial purposes.',

    },
    7314: {
        name: 'Potters and Related Workers',
        description: 'Potters and related workers prepare pottery, porcelain ware, sanitary ware, bricks, tiles and abrasive wheels by hand or by machine.',

    },
    7315: {
        name: 'Glass Makers, Cutters, Grinders and Finishers',
        description: 'Glass makers, cutters, grinders and finishers blow, mould, press, cut, trim, grind and polish glass, and shape molten glass according to patterns.',

    },
    7316: {
        name: 'Sign Writers, Decorative Painters, Engravers and Etchers',
        description: 'Sign writers, decorative painters, engravers and etchers decorate articles made of wood, metal, textiles, glass, ceramics and other materials. They plan, lay out and paint letters, figures, monograms and designs to make signs and engrave and etch ornamental and floral designs on glass and other articles.',

    },
    7317: {
        name: 'Handicraft Workers in Wood, Basketry and Related Materials',
        description: 'Handicraft workers in wood, basketry and related materials apply traditional techniques such as seasoning, impregnation to prepare wood, straw, rattan, reeds, clay, shells and other materials, and carve, mould, assemble, weave or paint and decorate various articles for personal or household use or for decorative purposes. Basketry weavers, brush makers and related workers select and prepare materials such as bristles, nylon, fibre, bass, whisker and wire to make wicker furniture, brushes and brooms, and weave various kinds of baskets.',

    },
    7318: {
        name: 'Handicraft Workers in Textile, Leather and Related Materials',
        description: 'Handicraft workers in textile, leather and related materials apply traditional techniques and patterns to produce woven fabrics, knitted, embroidered, woven and other garments and articles for household use, as well as traditional footwear, handbags, belts and other accessories.',

    },
    7319: {
        name: 'Handicraft Workers Not Elsewhere Classified',
        description: 'This unit group covers handicraft workers who perform traditional handicrafts not classified elsewhere. For instance, the group includes traditional handicraft workers in non-precious metals and stone.',

    },
    732: {
        name: 'Printing Trades Workers',
        description: 'Printing tradesРІР‚в„ў workers compose and set type prior to printing, set up and operate printing presses, bind and finish printed products, and prepare stencils and operate screen printing equipment.',

    },
    7321: {
        name: 'Pre-press Technicians',
        description: 'Pre-press technicians proof, format, set and compose text and graphics into a form suitable for use in various printing processes and representation in other visual media.',

    },
    7322: {
        name: 'Printers',
        description: 'Printers set up and operate digital, letterpress, lithographic, flexographic, gravure, newspaper and other printing presses.',

    },
    7323: {
        name: 'Print Finishing and Binding Workers',
        description: 'Print finishing and binding workers bind books and other publications, and finish printed products by hand or machine.',

    },
    74: {
        name: 'Electrical and Electronics Trades Workers',
        description: 'Electrical and electronics trades workers install, fit and maintain electrical wiring systems and machinery and other electrical apparatus, electrical transmission and supply lines and cables, and electronic and telecommunications equipment and systems. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.\n\nThe work is carried out by hand and by hand-powered and other tools which are used to reduce the amount of physical effort and time required for specific tasks, as well as to improve the quality of the products. The tasks call for an understanding of the work organisation, the materials and tools used, and the nature and purpose of the final product.',

    },
    741: {
        name: 'Electrical Equipment Installers and Repairers',
        description: 'Electrical equipment installers and repairers install, fit and maintain electrical wiring systems and related equipment, electrical machinery and other electrical apparatus and electrical transmission and supply lines and cables.',

    },
    7411: {
        name: 'Building and Related Electricians',
        description: 'Building and related electricians install, maintain and repair electrical wiring systems and related equipment and fixtures.',

    },
    7412: {
        name: 'Electrical Mechanics and Fitters',
        description: 'Electrical mechanics and fitters fit, adjust, install and repair electrical machinery and other electrical apparatus and equipment in buildings, factories, motor vehicles, workshops, or other places.',

    },
    7413: {
        name: 'Electrical Line Installers and Repairers',
        description: 'Electrical line installers and repairers install, repair and join electrical transmission and supply cables and related equipment.',

    },
    742: {
        name: 'Electronics and Telecommunications Installers and Repairers',
        description: 'Electronics and telecommunications installers and repairers fit, maintain, adjust and repair electronic equipment such as commercial and office machines, electronic instruments and control systems; install, repair and maintain telecommunications equipment, data transmission equipment, cables and antennae; and repair, fit and maintain computers.',

    },
    7421: {
        name: 'Electronics Mechanics and Servicers',
        description: 'Electronics mechanics and servicers fit, maintain, adjust and repair electronic equipment such as commercial and office machines and electronic instruments and control systems.',

    },
    7422: {
        name: 'Information and Communications Technology Installers and Servicers',
        description: 'Information and communications technology (ICT) installers and servicers install, repair and maintain telecommunications equipment, data transmission equipment, cables, antennae and conduits and repair, fit and maintain computers.',

    },
    75: {
        name: 'Food Processing, Woodworking, Garment and Other Craft and Related Trades Workers',
        description: 'Food processing, woodworking, garment and other craft and related trades workers treat and process agricultural and fisheries raw materials into food and other products, and produce and repair goods made of wood, textiles, fur, leather or other materials. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.\n\nThe work is carried out by hand and by hand-powered and other tools which are used to reduce the amount of physical effort and time required for specific tasks, as well as to improve the quality of the products. The tasks call for an understanding of the work organization, the materials and tools used, and the nature and purpose of the final product.',

    },
    751: {
        name: 'Food Processing and Related Trades Workers',
        description: 'Food processing and related trades workers slaughter animals; treat and prepare them and related food items for human and animal consumptions; make various kinds of bread, cakes and other flour products; process and preserve fruit, vegetables and related foods; taste and grade various food products and beverages; or prepare tobacco and make tobacco products. ',

    },
    7511: {
        name: 'Butchers, Fishmongers and Related Food Preparers',
        description: 'Butchers, fishmongers and related food preparers slaughter animals, clean, cut and dress meat and fish, remove bones and prepare related food items, or preserve meat, fish and other foods and food products by drying, salting or smoking.',

    },
    7512: {
        name: 'Bakers, Pastry-cooks and Confectionery Makers',
        description: 'Bakers, pastry-cooks and confectionery makers make various kinds of bread, cakes and other flour products, as well as handmade chocolate and sugar confectionery.',

    },
    7513: {
        name: 'Dairy Products Makers',
        description: 'Dairy products makers process butter and various types of cheese, cream or other dairy products.',

    },
    7514: {
        name: 'Fruit, Vegetable and Related Preservers',
        description: 'Fruit, vegetable and related preservers process or preserve fruit, nuts and related foods in various ways including cooking, drying, salting, or juice or oil extraction.',

    },
    7515: {
        name: 'Food and Beverage Tasters and Graders',
        description: 'Food and beverage tasters and graders inspect, taste and grade various types of agricultural products, food and beverages.',

    },
    7516: {
        name: 'Tobacco Preparers and Tobacco Products Makers',
        description: 'Tobacco preparers and tobacco products makers prepare tobacco leaves and make various tobacco products.',

    },
    752: {
        name: 'Wood Treaters, Cabinet-makers and Related Trades Workers',
        description: 'Wood treaters, cabinet-makers and related trades workers preserve and treat wood; make, decorate and repair wooden furniture, vehicles and other wooden products and components and set-up, operate and tend wood seasoning and preserving equipment and woodworking equipment, machines and machine tools.',

    },
    7521: {
        name: 'Wood Treaters',
        description: 'Wood treaters season, preserve and treat wood and lumber manually or using wood treatment equipment such as kilns and tanks.',

    },
    7522: {
        name: 'Cabinet-makers and Related Workers',
        description: 'Cabinet-makers and related workers make, decorate and repair wooden furniture, carts and other vehicles, wheels, parts, fittings, patterns, models and other wooden products using woodworking machines, machine tools and specialized hand tools.',

    },
    7523: {
        name: 'Woodworking Machine Tool Setters and Operators',
        description: 'Woodworking machine tool setters and operators set-up, operate and monitor automatic or semi-automatic woodworking machines, such as precision sawing, shaping, planing, boring, turning and woodcarving machines to fabricate or repair wooden parts for furniture, fixtures and other wooden products.',

    },
    753: {
        name: 'Garment and Related Trades Workers',
        description: 'Garment and related trades workers fit, alter and repair tailored clothing; design, and make textile and fur garments and leather or fur products; repair, renovate and decorate garments, gloves and other textile products; create patterns for garments; install, repair and replace upholstery of furniture, fixtures, orthopaedic appliances and furnishings of automobiles; trim, scrape, tan, buff and dye animal hides, pelts or skins; and modify and repair footwear and leather articles.',

    },
    7531: {
        name: 'Tailors, Dressmakers, Furriers and Hatters',
        description: 'Tailors, dressmakers, furriers and hatters fabricate, fit, alter and repair tailored or hand-made clothing. They produce made-to-measure clothing such as suits, overcoats and dresses from textile fabrics, light leather, fur and other material, or make hats or wigs according to customerРІР‚в„ўs and clothing manufacturerРІР‚в„ўs specifications.',

    },
    7532: {
        name: 'Garment and Related Patternmakers and Cutters',
        description: 'Garment and related patternmakers and cutters create precision master patterns for the production of garments, other textile, leather or fur products. They mark, cut, shape and trim textile, light leather and other materials according to blueprints or specifications in the manufacture of garments, hats and caps, gloves and miscellaneous products.',

    },
    7533: {
        name: 'Sewing, Embroidery and Related Workers',
        description: 'Sewing, embroidery and related workers sew together, repair, renovate and decorate garments, gloves and other products of textile, fur, light leather and other materials and fabricate tents sails, awnings and tarpaulins. They work mainly by hand using a needle and thread but may perform some tasks using a sewing machine.',

    },
    7534: {
        name: 'Upholsterers and Related Workers',
        description: 'Upholsterers and related workers install, repair and replace upholstery of furniture, fixtures, orthopaedic appliances, seats, panels, convertible and vinyl tops and other furnishings of automobiles, railway coaches, aircraft, ships and similar items with fabric, leather, rexine or other upholstery material. They also make and repair cushions, quilts and mattresses.',

    },
    7535: {
        name: 'Pelt Dressers, Tanners and Fellmongers',
        description: 'Pelt dressers, tanners and fellmongers trim, scrape, clean, tan, buff and dye animal hides, pelts or skins to produce leather stock and finished furs for making garments and other products.',

    },
    7536: {
        name: 'Shoemakers and Related Workers',
        description: 'Shoemakers and related workers make, modify and repair standard, custom or orthopaedic footwear and natural or synthetic leather articles, such as luggage, handbags and belts, (except for leather garments, hats and gloves), or participate in the manufacture of shoes and related goods. They decorate, reinforce or finish shoes, luggage, handbags and belts.',

    },
    754: {
        name: 'Other Craft and Related Workers',
        description: 'Other craft and related workers work under the surface of water, using underwater breathing apparatus; position, assemble, and detonate explosives; inspect and test, raw materials and manufactured components and products; remove unwanted organisms to prevent damage to crops and buildings and other structures. This group includes other trade and craft occupations not classified elsewhere in Major Group 7: Craft and Related Trades Workers.',

    },
    7541: {
        name: 'Underwater Divers',
        description: 'Underwater divers work under the surface of water, with or without the aid of underwater breathing apparatus, to inspect, install, repair and remove equipment and structures, conduct tests or experiments, rig explosives, photograph structures or marine life, collect various forms of aquatic life for commercial or research purposes, and find and recover missing items and persons.',

    },
    7542: {
        name: 'Shotfirers and Blasters',
        description: 'Shotfirers and blasters position, assemble, and detonate explosives at mining, quarrying and demolition sites.',

    },
    7543: {
        name: 'Product Graders and Testers (except Foods and Beverages)',
        description: 'Product graders and testers (except foods and beverages) inspect, test, sort, sample and weigh raw materials, manufactured components and non-comestible goods produced or sold, to ensure compliance with quality standards and to identify defects, wear, and deviations from specifications, and to grade and classify them according to their quality.',

    },
    7544: {
        name: 'Fumigators and Other Pest and Weed Controllers',
        description: 'Fumigators and other pest and weed controllers use chemicals to remove harmful insects, small animals, wild plants and other unwanted organisms, to prevent damage to crops and to buildings and other structures and their surroundings, and to prevent health risks.',

    },
    7549: {
        name: 'Craft and Related Workers not Elsewhere Classified',
        description: 'This group covers craft and trade occupations not classified elsewhere in Major Group 7: Craft and Related Trades Workers. For instance, the group includes those who mould, slice, grind and polish optical lenses and those who arrange flowers for display.',

    },
    8: {
        name: 'Plant and Machine Operators, and Assemblers',
        description: 'Plant and machine operators, and assemblers operate and monitor industrial and agricultural machinery and equipment on the spot or by remote control; drive and operate trains, motor vehicles and mobile machinery and equipment; or assemble products from component parts according to strict specifications and procedures. Competent performance in most occupations in this major group requires skills at the second ISCO skill level.\n\nThe work mainly calls for experience with and an understanding of industrial and agricultural machinery and equipment as well as an ability to cope with machine-paced operations and to adapt to technological innovations.',

    },
    81: {
        name: 'Stationary Plant and Machine Operators',
        description: 'Stationary plant and machine operators monitor and operate stationary industrial plant, machinery and equipment, or for which mobility is not an integral part of operation. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.\n\nThe work mainly calls for experience with and an understanding of the industrial plant, machinery or equipment being operated and monitored. Ability to cope with machine-paced operations and to adapt to innovations in machinery and equipment is often required.',

    },
    811: {
        name: 'Mining and Mineral Processing Plant Operators',
        description: 'Mining and mineral processing plant operators operate and monitor plant and machinery and operate hand tools to extract rock and minerals from the earth, process minerals and stone, drill wells and bores, and manufacture and finish cement and stone products.',

    },
    8111: {
        name: 'Miners and Quarriers',
        description: 'Miners and quarriers operate plant, machinery and hand tools to extract rock, mineral ores and other deposits from underground and surface mines and quarries.',

    },
    8112: {
        name: 'Mineral and Stone Processing Plant Operators',
        description: 'Mineral and stone processing plant operators operate and monitor machinery and equipment for processing rocks, minerals and stone to recover refined products for immediate use or further processing.',

    },
    8113: {
        name: 'Well Drillers and Borers and Related Workers',
        description: 'Well drillers and borers and related workers position, assemble and operate drilling machinery and related equipment to sink wells, extract rock samples, liquids and gases or for a variety of other purposes.',

    },
    8114: {
        name: 'Cement, Stone and Other Mineral Products Machine Operators',
        description: 'Cement, stone and other mineral products machine operators monitor and operate machines for manufacturing and finishing precast concrete, bitumen and stone products, and making cast stone for building purposes.',

    },
    812: {
        name: 'Metal Processing and Finishing Plant Operators',
        description: 'Metal processing and finishing plant operators, monitor and operate single-function process control machinery and equipment to control the conversion, processing and finishing of mineral ores and metals.',

    },
    8121: {
        name: 'Metal Processing Plant Operators',
        description: 'Metal processing plant operators, monitor, operate, adjust and maintain single-function process machinery and equipment to process and convert mineral ores and refine, harden, roll and extrude metals.',

    },
    8122: {
        name: 'Metal Finishing, Plating and Coating Machine Operators',
        description: 'Photographic-products machine operators operate and monitor equipment which makes photographic film and paper, and which processes exposed photographic film and makes prints.',

    },
    813: {
        name: 'Chemical and Photographic Products Plant and Machine Operators',
        description: 'Chemical and photographic products plant and machine operators monitor and operate machines which process a variety of chemicals and other ingredients to produce pharmaceuticals, toiletries, explosives and photographic or other chemical products.',

    },
    8131: {
        name: 'Chemical Products Plant and Machine Operators',
        description: 'Chemical products plant and machine operators operate and monitor units and machinery to blend, mix, process and package a wide range of chemical products.',

    },
    8132: {
        name: 'Photographic Products Machine Operators',
        description: 'Photographic products machine operators operate and monitor equipment which makes photographic film and paper, and which processes exposed photographic film and makes prints.',

    },
    814: {
        name: 'Rubber, Plastic and Paper Products Machine Operators',
        description: 'Rubber, plastic and paper products machine operators monitor and operate machines which knead and blend rubber and rubber compounds and produce various components and products from natural and synthetic rubber and plastics, or produce different paper products from paper, paperboard, cardboard and similar materials.',

    },
    8141: {
        name: 'Rubber Products Machine Operators',
        description: 'Rubber-products machine operators monitor and operate machines which knead and blend rubber and rubber compounds and produce various components and products from natural and synthetic rubber, such as moulded footwear, domestic articles, insulating materials, industrial accessories, or tyres.',

    },
    8142: {
        name: 'Plastic Products Machine Operators',
        description: 'Products machine operators monitor and operate machines which knead and blend compounds to obtain plastic materials and which make various plastic components and articles.',

    },
    8143: {
        name: 'Paper Products Machine Operators',
        description: 'Paper products machine operators monitor and operate machines which produce boxes, envelopes, bags and other goods from paper, paperboard, cardboard and similar materials.',

    },
    815: {
        name: 'Textile, Fur and Leather Products Machine Operators',
        description: 'Textile, fur and leather products machine operators monitor and operate various types of machines which prepare, process and treat fibres, yarn, thread, leather or fur; produce, modify and repair footwear, garments and manufacture or dry clean textiles, fur or light leather articles.',

    },
    8151: {
        name: 'Fibre Preparing, Spinning and Winding Machine Operators',
        description: 'Fibre-preparing, spinning and winding machine operators operate and monitor machines which prepare fibres, and spin, double, twist and wind yarn and thread from natural textile fibres. They twist two or more strands of yarn to prepare single stronger, smoother, uniform and heavier strands, and treat textiles to make them stiff and water resistant.',

    },
    8152: {
        name: 'Weaving and Knitting Machine Operators',
        description: 'Weaving and knitting machine operators set up, operate and monitor weaving and knitting machines which process yarn or thread into woven, non-woven and knitted products such as cloth, lace, carpets, industrial fabric, hosiery and knitted garments or to quilt and embroider fabric.',

    },
    8153: {
        name: 'Sewing Machine Operators',
        description: 'Sewing machine operators monitor and operate sewing machines to make, repair, darn and renovate textile, fur, synthetic or leather garments or embroider ornamental designs on garments or other materials. They operate buttonhole making and eyelet holing machines to cut holes, stitch around holes, stitch buttons and fix eyelets to garments.',

    },
    8154: {
        name: 'Bleaching, Dyeing and Fabric Cleaning Machine Operators',
        description: 'Bleaching, dyeing and fabric cleaning machine operators operate and monitor machines that bleach, shrink, dye and otherwise treat fibres, yarn or cloth.',

    },
    8155: {
        name: 'Fur and Leather Preparing Machine Operators',
        description: 'Fur and leather preparing machine operators monitor and operate various machines that prepare leather or treat fur- or wool-bearing pelts. They trim, scrape, clean, tan, buff and dye animal hides, pelts or skins to produce leather stock and finished furs.',

    },
    8156: {
        name: 'Shoemaking and Related Machine Operators',
        description: 'Shoemaking and related machine operators monitor and operate machines which produce and repair standard or special footwear, handbags and other accessories, mainly made of leather.',

    },
    8157: {
        name: 'Laundry Machine Operators',
        description: 'Laundry machine operators operate laundry, dry cleaning, pressing and fabric treatment machines in laundries and dry-cleaning establishments.',

    },
    8159: {
        name: 'Textile, Fur and Leather Products Machine Operators Not Elsewhere Classified',
        description: 'This unit group covers textile, fur and leather products machine operators not classified elsewhere in Minor Group 815: Textile, Fur and Leather Products Machine Operators. For instance, the group includes those engaged in operating and monitoring machines which make hats, tents, mattresses or miscellaneous articles such as braids or other trimmings.',

    },
    816: {
        name: 'Food and Related Products Machine Operators',
        description: 'Food and related machine operators set, operate and attend machinery used to slaughter animals, trim meat from carcasses; bake, freeze, heat, crush, mix, blend and otherwise process foodstuffs, beverages and tobacco leaves.',

    },
    8160: {
        name: 'Food and Related Products Machine Operators',
        description: 'Food and related machine operators set, operate and attend machinery used to slaughter animals, trim meat from carcasses, and bake, freeze, heat, crush, mix, blend and otherwise process foodstuffs, beverages and tobacco leaves.',

    },
    817: {
        name: 'Wood Processing and Papermaking Plant Operators',
        description: 'Wood processing and papermaking plant operators monitor, operate, and control machinery to saw wood, cut veneer, make plywood, produce pulp and paper, and to otherwise prepare wood, pulp and paper for further use.',

    },
    8171: {
        name: 'Pulp and Papermaking Plant Operators',
        description: 'Pulp and papermaking plant operators operate and monitor equipment to process wood, pulp and other cellulose materials in the production of pulp and to produce coat and finish paper.',

    },
    8172: {
        name: 'Wood Processing Plant Operators',
        description: 'Wood processing plant operators monitor, operate, and control lumber mill equipment for sawing timber logs into rough lumber, cutting veneer, making plywood and particle board, and otherwise preparing wood for further use.',

    },
    818: {
        name: 'Other Stationary Plant and Machine Operators',
        description: 'This unit group includes stationary plant and machine operators not classified elsewhere in Sub-major Group 81: Stationary Plant and Machine Operators. The group includes, for instance, operators of machines which make silicon chips and splice cables and ropes.',

    },
    8181: {
        name: 'Glass and Ceramics Plant Operators',
        description: 'Glass and ceramics plant operators operate and monitor kilns, furnaces and other machinery and equipment used in the manufacture of glass, ceramics, porcelain, tiles or bricks. They operate machines to anneal, harden or decorate glass and ceramics.',

    },
    8182: {
        name: 'Steam Engine and Boiler Operators',
        description: 'Steam engine and boiler operators maintain and operate various types of steam engines, boilers, turbines and auxiliary equipment to provide power and other utility services for commercial, industrial and institutional buildings, at work sites and aboard ships or self-propelled vessels.',

    },
    8183: {
        name: 'Packing, Bottling and Labelling Machine Operators',
        description: 'Packing, bottling and labelling machine operators monitor and operate machines which weigh, pack and label various products, or fill different containers with products.',

    },
    8189: {
        name: 'Stationary Plant and Machine Operators Not Elsewhere Classified',
        description: 'This unit group includes stationary plant and machine operators not classified elsewhere in Sub-major Group 81: Stationary Plant and Machine Operators. The group includes, for instance, operators of machines which make silicon chips and splice cables and ropes.',

    },
    82: {
        name: 'Assemblers',
        description: 'Assemblers assemble prefabricated parts or components to form subassemblies, products and equipment, according to procedures strictly laid down. The products worked on may be moved from one worker to the next along assembly lines. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    821: {
        name: 'Assemblers',
        description: 'Assemblers assemble prefabricated parts or components to form subassemblies, products and equipment, according to procedures strictly laid down. The products worked on may be moved from one worker to the next along assembly lines.',

    },
    8211: {
        name: 'Mechanical Machinery Assemblers',
        description: 'Mechanical machinery assemblers assemble, according to procedures strictly laid down, component parts of mechanical machinery such as engines, motor vehicles, turbines and aircraft.',

    },
    8212: {
        name: 'Electrical and Electronic Equipment Assemblers',
        description: 'Electrical and electronic equipment assemblers assemble or modify, according to strictly laid down procedures, components of electrical, electromechanical and electronic equipment.',

    },
    8219: {
        name: 'Assemblers Not Elsewhere Classified',
        description: 'Assemblers not elsewhere classified assemble, according to procedures strictly laid down, various products that do not include electronic, electrical or mechanical components.',

    },
    83: {
        name: 'Drivers and Mobile Plant Operators',
        description: 'Drivers and mobile plant operators drive and tend trains and motor vehicles, or drive, operate and monitor industrial and agricultural machinery and equipment, or execute deck duties on board ship and other water-borne craft. Competent performance in most occupations in this sub-major group requires skills at the second ISCO skill level.',

    },
    831: {
        name: 'Locomotive Engine Drivers and Related Workers',
        description: 'Locomotive engine drivers and related workers drive, or assist in driving, locomotive engines to transport passengers and freight, take charge of and safeguard railway freight trains during runs; control the movement of railway traffic by operating signals, switch rolling stock and make up trains in railway yards; make up trains for hauling in mines and control their movement.',

    },
    8311: {
        name: 'Locomotive Engine Drivers',
        description: 'Locomotive engine drivers drive, or assist in driving, locomotive engines to transport passengers and freight.',

    },
    8312: {
        name: 'Railway Brake, Signal and Switch Operators',
        description: 'Railway brakers, signallers and shunters take charge of and safeguard railway freight trains during runs, control the movement of railway traffic by operating signals, switch rolling stock and make up trains in railway yards, make up trains for hauling in mines and control their movement.',

    },
    832: {
        name: 'Car, Van and Motorcycle Drivers',
        description: 'Car, van and motorcycle drivers drive and tend motorcycles, motorized tricycles, cars or vans to transport passengers, materials or goods.',

    },
    8321: {
        name: 'Motorcycle Drivers',
        description: 'Motorcycle drivers drive and tend motorcycles or motorized tricycles equipped to transport materials, goods or passengers.',

    },
    8322: {
        name: 'Car, Taxi and Van Drivers',
        description: 'Car, taxi and van drivers drive and tend motor cars and vans to transport passengers, mail or goods.',

    },
    833: {
        name: 'Heavy Truck and Bus Drivers',
        description: 'Heavy truck and bus drivers drive and tend heavy trucks, lorries, buses or street tramcars to transport goods, liquids, heavy materials, mail or passengers.',

    },
    8331: {
        name: 'Bus and Tram Drivers',
        description: 'Bus and tram drivers drive and tend buses or street tramcars to transport passengers, mail or goods.',

    },
    8332: {
        name: 'Heavy Truck and Lorry Drivers',
        description: 'Heavy-truck and lorry drivers drive and tend heavy motor vehicles to transport goods, liquids and heavy materials over short or long distances.',

    },
    834: {
        name: 'Mobile Plant Operators',
        description: 'Mobile plant operators drive, tend, operate and monitor special-purpose motorized machinery or equipment used for clearing or preparing land; digging, moving and spreading earth, rock and similar materials; and lifting or moving heavy objects.',

    },
    8341: {
        name: 'Mobile Farm and Forestry Plant Operators',
        description: 'Mobile farm and forestry plant operators drive, tend, operate and monitor one or more types of special-purpose motorized, mobile machinery or equipment used in agricultural, horticultural and forestry operations.',

    },
    8342: {
        name: 'Earthmoving and Related Plant Operators',
        description: 'Earthmoving and related plant operators operate machines to excavate, grade, level, smooth and compact earth or similar materials.',

    },
    8343: {
        name: 'Crane, hoist and related plant operators',
        description: 'Crane, hoist and related plant operators operate and monitor stationary and mobile cranes and other hoisting equipment.',

    },
    8344: {
        name: 'Lifting Truck Operators',
        description: 'Lifting-truck operators drive, operate and monitor lifting trucks or similar vehicles to transport, lift and stack pallets with goods.',

    },
    835: {
        name: "Ships' Deck Crews and Related Workers",
        description: 'ShipРІР‚в„ўs deck crews and related workers carry out deck duties on board ships and similar duties on board other water-borne craft.',

    },
    8350: {
        name: "Ships' Deck Crews and Related Workers",
        description: 'ShipРІР‚в„ўs deck crews and related workers carry out deck duties on board ships and similar duties on board other water-borne craft.',

    },
    9: {
        name: 'Elementary Occupations',
        description: 'Elementary occupations involve the performance of simple and routine tasks which may require the use of hand-held tools and considerable physical effort. Most occupations in this major group require skills at the first ISCO skill level.',

    },
    91: {
        name: 'Cleaners and Helpers',
        description: 'Cleaners and helpers perform various tasks in private households, hotels, offices, hospitals and other establishments, as well as in aircraft, trains coaches, trams and similar vehicles, in order to keep the interiors and fixtures clean, and launder and press garments and textiles by hand. Most occupations in this sub-major group require skills at the first ISCO skill level.',

    },
    911: {
        name: 'Domestic, Hotel and Office Cleaners and Helpers',
        description: 'Domestic, hotel and office cleaners and helpers sweep, vacuum clean, wash, polish, take care of household linen, purchase household supplies; perform various tasks in order to keep clean and tidy the interiors and fixtures of hotels, offices and other establishments, as well as of aircraft, trains, buses and similar vehicles.',

    },
    9111: {
        name: 'Domestic Cleaners and Helpers',
        description: 'Domestic cleaners and helpers sweep, vacuum clean, wash and polish, take care of household linen, purchase household supplies, prepare food, serve meals and perform various other domestic duties.',

    },
    9112: {
        name: 'Cleaners and Helpers in Offices, Hotels and Other Establishments',
        description: 'Cleaners and helpers in offices, hotels and other establishments perform various cleaning tasks in order to keep clean and tidy the interiors and fixtures of hotels, offices and other establishments, as well as of aircraft, trains, buses and similar vehicles.',

    },
    912: {
        name: 'Vehicle, Window, Laundry and Other Hand Cleaning Workers',
        description: 'Vehicle, window, laundry and other hand cleaning workers clean windows, showcases or other surfaces of buildings or vehicles; and press, launder or dry-clean linen and other textiles by hand.',

    },
    9121: {
        name: 'Hand Launderers and Pressers',
        description: 'Hand launderers and pressers launder, press or dry-clean garments, linen and other textiles by hand.',

    },
    9122: {
        name: 'Vehicle Cleaners',
        description: 'Vehicle cleaners wash, clean and polish the exterior and interior of vehicles.',

    },
    9123: {
        name: 'Window Cleaners',
        description: 'Window cleaners wash and polish windows and other glass fittings.',

    },
    9129: {
        name: 'Other Cleaning Workers',
        description: 'This unit group includes cleaning workers not classified elsewhere. The group includes, for instance, those who clean surfaces, materials and objects, such as carpets, walls, swimming pools and cooling towers, using specialized cleaning equipment and chemicals.',

    },
    92: {
        name: 'Agricultural, Forestry and Fishery Labourers',
        description: 'Agricultural, forestry and fishery labourers perform simple and routine tasks in the production of crops and livestock, cultivation and maintenance of gardens and parks, exploitation and conservation of forests, and conduct of aquaculture and fisheries operations. Most occupations in this sub-major group require skills at the first ISCO skill level.',

    },
    921: {
        name: 'Agricultural, Forestry and Fishery Labourers',
        description: 'Agricultural, forestry and fishery labourers perform simple and routine tasks in the production of crops and livestock, cultivation and maintenance of gardens and parks, exploitation and conservation of forests, and conduct of aquaculture and fisheries operations.',

    },
    9211: {
        name: 'Crop Farm Labourers',
        description: 'Crop farm labourers perform simple and routine tasks on farms in the production of crops such as fruit, nuts, grains and vegetables.',

    },
    9212: {
        name: 'Livestock Farm Labourers',
        description: 'Livestock farm labourers perform simple and routine tasks in farm production of animals, including poultry and insects.',

    },
    9213: {
        name: 'Mixed Crop and Livestock Farm Labourers',
        description: 'Mixed crop and livestock farm labourers perform simple and routine tasks in farm production of both crops and animals.',

    },
    9214: {
        name: 'Garden and Horticultural Labourers',
        description: 'Garden and horticultural labourers perform simple and routine tasks in operations to cultivate and maintain trees, shrubs, flowers and other plants in parks and private gardens, to produce saplings, bulbs and seeds, or grow vegetables and flowers by intensive cultivation techniques.',

    },
    9215: {
        name: 'Forestry Labourers',
        description: 'Forestry labourers perform simple and routine tasks to cultivate and maintain natural and plantation forests, and log, fell and saw trees.',

    },
    9216: {
        name: 'Fishery and Aquaculture Labourers',
        description: 'Fishery and aquaculture labourers perform simple and routine tasks to cultivate, catch and harvest fish and seafood in aquaculture and inland, coastal and deep sea fishing operations.',

    },
    93: {
        name: 'Labourers in Mining, Construction, Manufacturing and Transport',
        description: 'Labourers in mining, construction, manufacturing and transport perform simple and routine manual tasks in mining, quarrying, civil engineering, building, manufacturing, transport and storage operations, and operate human-powered and animal-drawn vehicles and machinery. Most occupations in this sub-major group require skills at the first ISCO skill level.',

    },
    931: {
        name: 'Mining and Construction Labourers',
        description: 'Mining and construction labourers perform simple and routine manual tasks in mining, quarrying, civil engineering and building operations.',

    },
    9311: {
        name: 'Mining and Quarrying Labourers',
        description: 'Mining and quarrying labourers perform routine tasks in mining and quarrying operations.',

    },
    9312: {
        name: 'Civil Engineering Labourers',
        description: 'Civil engineering labourers perform routine tasks in connection with the building and maintenance of roads, railways, dams and other civil engineering projects.',

    },
    9313: {
        name: 'Building Construction Labourers',
        description: 'Building construction labourers perform routine tasks in connection with building construction and demolition work.',

    },
    932: {
        name: 'Manufacturing Labourers',
        description: 'Manufacturing labourers perform a variety of simple and routine manual tasks in manufacturing to assist the work of machine operators and assemblers.',

    },
    9321: {
        name: 'Hand Packers',
        description: 'Hand packers weigh, pack and label materials and products by hand.',

    },
    9329: {
        name: 'Manufacturing Labourers Not Elsewhere Classified',
        description: 'Manufacturing labourers not elsewhere classified assist the work of machine operators and assemblers and perform a variety of simple and routine manual tasks in manufacturing, excluding packing and labelling finished products.',

    },
    933: {
        name: 'Transport and Storage Labourers',
        description: 'Transport and storage labourers propel cycles and similar vehicles and drive animal-drawn vehicles to transport passengers or goods, drive animal-drawn machinery, handle freight and baggage, and stock shelves.',

    },
    9331: {
        name: 'Hand and Pedal Vehicle Drivers',
        description: 'Hand and pedal vehicle drivers propel cycles, hand carts and similar vehicles to deliver messages and transport passengers or goods.',

    },
    9332: {
        name: 'Drivers of Animal-drawn Vehicles and Machinery',
        description: 'Drivers of animal-drawn vehicles and machinery drive animal-drawn vehicles to transport passengers or goods, as well as animal-drawn machinery usually in connection with farming.',

    },
    9333: {
        name: 'Freight Handlers',
        description: 'Freight handlers carry out tasks such as packing, carrying, loading and unloading furniture and other household items, or loading and unloading ship and aircraft cargoes and other freight, or carrying and stacking goods in various warehouses.',

    },
    9334: {
        name: 'Shelf Fillers',
        description: 'Shelf fillers stock shelves and display areas and keep stock clean and in order in supermarkets and other retail and wholesale shops.',

    },
    94: {
        name: 'Food Preparation Assistants',
        description: 'Food preparation assistants prepare and cook to order a small variety of pre-cooked food or beverages, clear tables, clean kitchen areas and wash dishes. Most occupations in this sub-major group require skills at the first ISCO skill level.',

    },
    941: {
        name: 'Food Preparation Assistants',
        description: 'Food preparation assistants prepare and cook to order small variety of pre-cooked food or beverages, clear tables, clean kitchen areas and wash dishes.',

    },
    9411: {
        name: 'Fast Food Preparers',
        description: 'Fast food preparers prepare and cook to order a limited range of foods or beverages that involve simple preparation processes and a small number of ingredients. They may take orders from customers and serve at counters or tables.',

    },
    9412: {
        name: 'Kitchen Helpers',
        description: 'Kitchen helpers clear tables, clean kitchen areas, wash dishes, prepare ingredients and perform other duties to assist workers who prepare or serve food and beverages.',

    },
    95: {
        name: 'Street and Related Sales and Service Workers',
        description: 'Street and related sales and service workers sell goods (excluding food) for immediate consumptions, and provide a variety of services on streets and in other public places such as stations. Most occupations in this sub-major group require skills at the first ISCO skill level.',

    },
    951: {
        name: 'Street and Related Service Workers',
        description: 'Street and related service workers provide a variety of services on streets and in other public places, including cleaning shoes, washing car windows, running errands, looking after property, and providing other on-the-spot street services.',

    },
    9510: {
        name: 'Street and Related Service Workers',
        description: 'Street and related service workers provide a variety of services on streets and in other public places, including cleaning shoes, washing car windows, running errands, handing out leaflets, looking after property, and providing other on-the-spot street services.',

    },
    952: {
        name: 'Street Vendors (excluding Food)',
        description: 'Street vendors (excluding food) sell a usually limited range of goods (excluding food for immediate consumption) in streets and public places such as stations, cinemas or theatres.',

    },
    9520: {
        name: 'Street Vendors (excluding Food)',
        description: 'Street vendors (excluding food) sell a usually limited range of goods (excluding food for immediate consumption) in streets and public places such as stations, cinemas or theatres.',

    },
    96: {
        name: 'Refuse Workers and Other Elementary Workers',
        description: 'Refuse workers and other elementary workers collect, process and recycle garbage from buildings, yards, streets and other public places. They keep streets and other public places clean and tidy, deliver and carry messages and packages and perform odd jobs for private households or establishments. Most occupations in this sub-major group require skills at the first ISCO skill level.',

    },
    961: {
        name: 'Refuse Workers',
        description: 'Refuse workers collect, process and recycle garbage from buildings, yards, streets and other public places, or keep streets and other public places clean.',

    },
    9611: {
        name: 'Garbage and Recycling Collectors',
        description: 'Garbage collectors collect and remove rubbish and items for recycling from buildings, yards, streets and other places.',

    },
    9612: {
        name: 'Refuse Sorters',
        description: 'Refuse sorters identify, collect and sort discarded items suitable for recycling at dump sites and recycling enterprises or in buildings, streets and other public places.',

    },
    9613: {
        name: 'Sweepers and Related Labourers',
        description: 'Sweepers and related labourers sweep and clean streets, parks, airports, stations and other public places.',

    },
    962: {
        name: 'Other Elementary Workers',
        description: 'Other elementary workers deliver and carry messages and packages, perform a variety of simple maintenance and repair tasks, collect money and stock vending machines, read meters; collect water and firewood; and collect and issue tickets for parking or events.',

    },
    9621: {
        name: 'Messengers, Package Deliverers and Luggage Porters',
        description: 'Messengers, package deliverers and luggage porters carry and deliver messages, packages and other items on foot, within an establishment or between establishments, to households and elsewhere, or carry luggage, especially at hotels, stations and airports.',

    },
    9622: {
        name: 'Odd Job Persons',
        description: 'Odd job persons clean, paint and maintain buildings, grounds and facilities, and undertake simple repairs.',

    },
    9623: {
        name: 'Meter Readers and Vending-machine Collectors',
        description: 'Meter readers and vending-machine collectors stock vending machines and collect money from them or from parking meters and other coin-boxes, or read electricity, gas or water meters.',

    },
    9624: {
        name: 'Water and Firewood Collectors',
        description: 'Water and firewood collectors collect water and firewood and transport them on foot or using hand or animal carts.',

    },
    9629: {
        name: 'Elementary Workers Not Elsewhere Classified',
        description: 'This unit group covers elementary workers not classified elsewhere in Major Group 9: Elementary Occupations. For instance, the group includes those who issue and collect parking or admission tickets, provide personal items to patrons or customers in cloakrooms, and assist patrons at entertainment events.',

    },
    0: {
        name: 'Armed Forces Occupations',
        description: 'Armed forces occupations include all jobs held by members of the armed forces. Members of the armed forces are those personnel who are currently serving in the armed forces, including auxiliary services, whether on a voluntary or compulsory basis, and who are not free to accept civilian employment and are subject to military discipline. Included are regular members of the army, navy, air force and other military services, as well as conscripts enrolled for military training or other service for a specified period.',

    },
    110: {
        name: 'Commissioned Armed Forces Officers',
        description: 'Commissioned armed forces officers provide leadership and management to organizational units in the armed forces and/or perform similar tasks to those performed in a variety of civilian occupations outside the armed forces. This group includes all members of the armed forces holding the rank of second lieutenant (or equivalent) or higher.',

    },
    210: {
        name: 'Non-commissioned Armed Forces Officers',
        description: 'Non-commissioned armed forces officers enforce military discipline and supervise the activities of those employed in Sub-major Group 03: Armed Forces Occupations, Other Ranks, and/or perform similar tasks to those performed in a variety of civilian occupations outside the armed forces. This group includes members of the armed forces holding ranks such as sergeant, warrant officer and sergeant major.',

    },
    310: {
        name: 'Armed Forces Occupations, Other Ranks',
        description: 'Armed forces occupations, other ranks include all conscripted and non-conscripted members of the armed forces except commissioned and non-commissioned officers. They perform specific military tasks and/or perform similar tasks to those performed in a variety of civilian occupations outside the armed forces.',

    }
}
