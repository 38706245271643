import React, {forwardRef, useCallback, useRef} from 'react';
import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, Radio, Row, Select, Space, Typography} from "antd";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../../schemas";
import {IS_DEV} from "../../../utils/constants";
import PersonalInformationSection from "./PersonalInformationSection";
import AddressSection from "./AddressSection";
import TaxSection from "./TaxSection";
import {fundsSource, wealthSource} from "../data/selects";

const defaultInitialValues = {
    ltRelationship: 'No relationship',
    fundsSource: "0",
    wealthSource: "5",
    isBeneficialOwner: true,
}

const SelectSection = forwardRef(({citizenship, initialValues, footer, countries, goBackStep, formDataInit, resetStep, ...props}, ref) => {
    const [form] = Form.useForm();
    const personalInfoFormRef = useRef(null);
    const addressFormRef = useRef(null);
    const taxFormRef = useRef(null);
    const resetFields = () => form.resetFields();
    const isBeneficialOwner = Form.useWatch('isBeneficialOwner', form);

    const resetForm = () => {
        resetFields();
        resetStep()
    }

    const onFormFinish = async () => {
        const forms = [
            form,
            personalInfoFormRef.current,
            addressFormRef.current,
            taxFormRef.current,
        ].filter(Boolean);

        try {
            const formData = await Promise.all(forms.map(f => f.validateFields()));
            const mergedData = formData.slice(1)
                .filter(Boolean)
                .reduce((acc, current) => ({ ...acc, ...current }), {});

            if (Object.keys(mergedData).length > 0) {
                const newFields = Object.entries(mergedData).map(([key, value]) => {
                    return {
                        name: [`beneficialOwner_${key}`],
                        value: value,
                    };
                });
                form.setFields(newFields);
            }

            form.submit();
        } catch (error) {
            // console.error(error);
        }
    };

    return (
        <>
            <Form ref={ref} initialValues={formDataInit ? formDataInit : initialValues ? initialValues : IS_DEV && defaultInitialValues} validateMessages={validateMessages} layout="vertical" size="large" form={form} validateTrigger="onChange" name="step3" {...props}>
                {citizenship !== 'LT' && (
                    <Form.Item label="Relationship with Lithuania (for non-residents only)" rules={GENERAL_SCHEME.required} name="ltRelationship">
                        <Select placeholder="Relationship with Lithuania (for non-residents only)" options={[
                            { value: 'Employment in Lithuania', label: 'Employment in Lithuania' },
                            { value: 'Ownership of real estate in Lithuania', label: 'Ownership of real estate in Lithuania' },
                            { value: 'Ownership of the company registered in Lithuania', label: 'Ownership of the company registered in Lithuania' },
                            { value: 'Residence permit in Lithuania', label: 'Residence permit in Lithuania' },
                            { value: 'Studying in Lithuania', label: 'Studying in Lithuania' },
                            { value: 'No relationship', label: 'No relationship' },
                        ]} />
                    </Form.Item>
                )}

                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item label="Source of funds (please, specify)" rules={GENERAL_SCHEME.required} name="fundsSource">
                            <Select placeholder="Source of funds (please, specify)" options={Object.entries(fundsSource).map(([key, object]) => ({ value: key, label: object.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Source of Wealth" rules={GENERAL_SCHEME.required} name="wealthSource">
                            <Select placeholder="Source of Wealth" options={Object.entries(wealthSource).map(([key, object]) => ({ value: key, label: object.name }))} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="PEP Status" name="pepStatus">
                    <Radio.Group>
                        <Radio value={"PEP"}>PEP</Radio>
                        <Radio value={"Close family member"}>Close family member</Radio>
                        <Radio value={"Close associate"}>Close associate</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item rules={GENERAL_SCHEME.required} label="Is customer the beneficial owner of funds in account?" name="isBeneficialOwner">
                    <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                {isBeneficialOwner === false && (
                    <>
                        <Form.Item name="beneficialOwner_firstName" hidden />
                        <Form.Item name="beneficialOwner_lastName" hidden />
                        <Form.Item name="beneficialOwner_middleName" hidden />
                        <Form.Item name="beneficialOwner_dateOfBirth" hidden />
                        <Form.Item name="beneficialOwner_countryOfBirth" hidden />
                        <Form.Item name="beneficialOwner_citizenship" hidden />
                        <Form.Item name="beneficialOwner_nationality" hidden />
                        <Form.Item name="beneficialOwner_idNumber" hidden />
                        <Form.Item name="beneficialOwner_idCountry" hidden />
                        <Form.Item name="beneficialOwner_idAuthority" hidden />
                        <Form.Item name="beneficialOwner_idIssueDate" hidden />
                        <Form.Item name="beneficialOwner_idExpiryDate" hidden />
                        <Form.Item name="beneficialOwner_declaredAddress" hidden />
                        <Form.Item name="beneficialOwner_correspondenceAddress" hidden />
                        <Form.Item name="beneficialOwner_phone" hidden />
                        <Form.Item name="beneficialOwner_email" hidden />
                        <Form.Item name="beneficialOwner_taxCountry" hidden />
                        <Form.Item name="beneficialOwner_taxNumber" hidden />
                        <Form.Item name="beneficialOwner_noTIN" hidden />
                        <Form.Item name="beneficialOwner_isUSTaxpayer" hidden />
                    </>
                )}
            </Form>
            {isBeneficialOwner === false && (
                <>
                    <Divider orientation="center">Beneficial owner</Divider>
                    <PersonalInformationSection ref={personalInfoFormRef} footer={_ => null} countries={countries}/>
                    <AddressSection ref={addressFormRef} footer={_ => null} />
                    <TaxSection ref={taxFormRef} footer={_ => null} countries={countries}/>
                </>
            )}

            {footer ? footer({ form }) : (
                <Row justify="end">
                    <Space wrap="wrap">
                        <Button size="large" type="text" onClick={resetForm}>
                            Reset
                        </Button>
                        {/*<Button onClick={}>*/}
                        {/*    Back*/}
                        {/*</Button>*/}
                        <Button size="large" onClick={goBackStep}>
                            Prev
                        </Button>
                        <Button size="large" onClick={onFormFinish}>
                            Next
                        </Button>
                    </Space>
                </Row>
            )}
        </>
    );
});

export default SelectSection;
