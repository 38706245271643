import React, {forwardRef, useCallback} from 'react';
import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Radio, Row, Select, Space} from "antd";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../../schemas";
import {IS_DEV} from "../../../utils/constants";

const defaultInitialValues = {
    fundPlan: "Test",
    cryptoTurnover: 0,
    fiatTurnover: 0,
    fiatIncomingQuantity: 0,
    fiatOutcomingQuantity: 0,
}

const FinancialSection = forwardRef(({initialValues, footer, goBackStep, formDataInit, resetStep, ...props}, ref) => {
    const [form] = Form.useForm();

    const resetFields = () => form.resetFields();
    const resetForm = () => {
        resetFields();
        resetStep()
    }
    return (
        <Form ref={ref} initialValues={formDataInit ? formDataInit : initialValues ? initialValues : IS_DEV && defaultInitialValues} validateMessages={validateMessages} layout="vertical" size="large" form={form} validateTrigger="onChange" name="step4">
            <Row gutter={20}>
                <Col xs={24} md={12}>
                    <Form.Item label="What is the expected monthly turnover in cryptocurrencies?" rules={GENERAL_SCHEME.required} name="cryptoTurnover">
                        <InputNumber className="w100" addonAfter="USDT" min={1} max={100000} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="What is the expected monthly turnover in FIAT?" rules={GENERAL_SCHEME.required} name="fiatTurnover">
                        <InputNumber className="w100" addonAfter="€" min={1} max={100000} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} md={12}>
                    <Form.Item label="What is the expected quantity of incoming FIAT transactions?" rules={GENERAL_SCHEME.required} name="fiatIncomingQuantity">
                        <InputNumber className="w100" min={1} max={100000} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="What is the expected quantity of outcoming FIAT transactions?" rules={GENERAL_SCHEME.required} name="fiatOutcomingQuantity">
                        <InputNumber className="w100" min={1} max={100000} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="fundPlan" label="How do you plan to fund your account?" rules={[...GENERAL_SCHEME.alphaNumericSpace, ...GENERAL_SCHEME.required]}>
                <Input placeholder="How do you plan to fund your account?" maxLength={100} />
            </Form.Item>

            {footer ? footer({ form }) : (
                <Row justify="end">
                    <Space wrap="wrap">
                        <Button type="text" onClick={resetForm}>
                            Reset
                        </Button>
                        {/*<Button onClick={}>*/}
                        {/*    Back*/}
                        {/*</Button>*/}
                        <Button onClick={goBackStep}>
                            Prev
                        </Button>
                        <Button onClick={form.submit}>
                            Next
                        </Button>
                    </Space>
                </Row>
            )}
        </Form>
    );
});

export default FinancialSection;
