export const formatNumberPrecision = (number, precision = 4) => {
	const stringNumber = String(number);
	const decimalIndex = stringNumber.indexOf('.') + 1;

	if (decimalIndex === 0) {
		// Число не содержит десятичную часть, возвращаем исходное число
		return number;
	}

	return Number(stringNumber.substring(0, decimalIndex + precision));
}
export const isProfileFilled = (meta) => {
	const keysToCheck = ['first_name', 'last_name', 'actual_residential_address'];
	return meta ? keysToCheck.every(key => meta[key] != null) : false;
}

export const retryOperation = async (operation, maxRetries, delay) => {
	for (let i = 0; i < maxRetries; i++) {
		try {
			return await operation();
		} catch (err) {
			if (i === maxRetries - 1) throw err;
			await new Promise(res => setTimeout(res, delay));
		}
	}
};

export const combineClasses = (...classesAndConditions) => {
	const combinedClasses = [];

	classesAndConditions.forEach(item => {
		if (Array.isArray(item)) {
			const [className, condition] = item;
			if (condition) {
				combinedClasses.push(className);
			}
		} else if (typeof item === 'string') {
			combinedClasses.push(item);
		}
	});

	return combinedClasses.join(' ');
}
