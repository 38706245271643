import {ENDPOINTS, NEW_ENDPOINTS} from "../../http/endpoints";
import {API} from "../../http";
import snsWebSdk from '@sumsub/websdk';
import {message} from "antd";
import dayjs from "dayjs";

export const getUserInfo = ({toState = true} = {}) => async dispatch => {
	try {
		const { data: { data } } = await API.get(NEW_ENDPOINTS.USER.INFO);
		if (toState) dispatch({ type: 'User/SET', payload: data });
		return data;

	} catch (e) {
		throw e;
	}
};

export const getAccountMetaInfo = ({toState = true} = {}) => async (dispatch) => {
	try {
		const {data} = await API.get(NEW_ENDPOINTS.USER.ACCOUNT_INFO);
		if (toState) dispatch({ type: 'User/SET', payload: { meta: data } });
		return data;
	} catch (e) {
		throw e;
	}
}
export const getUserFullInfo = ({toState = true} = {}) => async (dispatch) => {
	const [userInfoResult, accountInfoResult] = await Promise.allSettled([
		dispatch(getUserInfo({ toState: false })),
		dispatch(getAccountMetaInfo({ toState: false }))
	]);

	let userInfo = null;
	let accountInfo = null;

	if (userInfoResult.status === "fulfilled") userInfo = userInfoResult.value;
	if (accountInfoResult.status === "fulfilled") accountInfo = accountInfoResult.value;

	if (userInfo && accountInfo) {
		if (toState) {
			dispatch({ type: 'User/SET', payload: { meta: accountInfo, ...userInfo } });
		}
		return { ...userInfo, meta: accountInfo };
	} else if (userInfo) {
		if (toState) {
			dispatch({ type: 'User/SET', payload: userInfo });
		}
		return userInfo;
	} else if (accountInfo) {
		if (toState) {
			dispatch({ type: 'User/SET', payload: { meta: accountInfo } });
		}
		return { meta: accountInfo };
	} else {
		throw new Error("Failed to fetch both user info and account meta info");
	}
}
export const sendContactForm = (formData, formType) => async (dispatch, getState) => {
	const {user} = getState(), email = user.email;
	try {
		await API.post(NEW_ENDPOINTS.INFO.HELP, {...formData, email, type: formType});
		message.success('Your form has been successfully submitted!');
	} catch (e) {
		message.error("Failed to submit the form");
		throw e;
	}
}
export const updateProfile = (formData) => async (dispatch) => {
	try {
		const dataToSend = {
			first_name: formData.first_name,
			last_name: formData.last_name,
			date_of_birth: formData.date_of_birth && dayjs(formData.date_of_birth).format('DD.MM.YYYY'),
			actual_residential_address: formData.actual_residential_address,
			profession: formData.profession,
			occupation: formData.occupation,
		};

		const filteredData = Object.entries(dataToSend)
			.filter(([, value]) => value !== null && value !== undefined)
			.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
		await API.post(NEW_ENDPOINTS.USER.ACCOUNT_INFO, filteredData);
		try { await dispatch(getAccountMetaInfo()) } catch (e) {}
		message.success('Profile was updated!');
	} catch (e) {
		message.error("Profile update failed");
		throw e;
	}
}

export const fetchTransactionHistory = ({toState = true} = {}) => async (dispatch) => {
	try {
		const {data} = await API.post(NEW_ENDPOINTS.ORDER.ORDER);
		if (toState) dispatch({ type: 'User/SET', payload: { transactions: data.reverse() } });
		return data.reverse();
	} catch (e) {
		message.error("Failed to retrieve transaction list");
		throw e;
	}
}

export const resetPassword = async (password) => {
	try {
		await API.post(NEW_ENDPOINTS.USER.RESET_PASSWORD, { password });
	} catch (e) {
		message.error("Password change error");
		throw e;
	}
}

export const verifyAccount = () => async (dispatch) => {
	try {
		const {data} = await API.get(NEW_ENDPOINTS.USER.SUMSUB_TOKEN);
		let snsWebSdkInstance1 = snsWebSdk.init(
			data,
			// token update callback, must return Promise
			// Access token expired
			// get a new one and pass it to the callback to re-initiate the WebSDK
			() => Promise.resolve("sbx:F0lWS7ga8ylbuQuQB4FbXwmc")
		)
			.withConf({
				lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
			})
			.withOptions({ addViewportTag: false, adaptIframeHeight: true})
			// see below what kind of messages WebSDK generates
			.on('idCheck.stepCompleted', (payload) => {
				console.log('stepCompleted', payload);
			})
			.on('idCheck.onError', (error) => {
				console.log('onError', error)
			})
			.on('idCheck.onApplicantSubmitted', () => {
				dispatch(getUserFullInfo());
			})
			.build();
		// you are ready to go:
		// just launch the WebSDK by providing the container element for it
		snsWebSdkInstance1.launch('#sumsub-websdk-container')
	} catch (e) {
		message.error('Something went wrong');
		throw e;
	}
}
