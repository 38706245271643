import React from 'react';
import Container from "../../components/Container/Container";
import {Grid, Input, message, QRCode, Row, Typography} from "antd";
import {getUser} from "../../store/selectors";
import {connect} from "react-redux";
import {CopyOutlined} from "@ant-design/icons";

const Receive = ({wallet}) => {
	const breakpoints = Grid.useBreakpoint();

	const onCopy = () => {
		if (wallet) {
			navigator.clipboard.writeText(wallet);
			message.success('The wallet number has been copied')
		}
	}

	return (
		<Container>
			<Typography.Title level={breakpoints.md ? 1 : 3}>Receive crypto</Typography.Title>
			<Typography.Paragraph>The input below contains your wallet number, which can be used to receive cryptocurrency payments.</Typography.Paragraph>
			<Input style={{ marginBottom: 10 }} suffix={<CopyOutlined onClick={onCopy} />} size="large" value={wallet} placeholder="Your wallet"/>
			<Typography.Paragraph type="secondary">
				Your wallet number is a unique identifier that is associated with your digital wallet, and it allows
				others to send cryptocurrency to you. Depending on the type of cryptocurrency you are using,
				your wallet number may be a series of letters, numbers, or a combination of both. It's important
				to keep your wallet number private and secure, as it can be used to access your funds. If you are
				planning on receiving cryptocurrency payments, make sure to share your wallet number only with trusted
				individuals or entities.</Typography.Paragraph>

			<Row style={{ marginTop: 20 }} justify="center">
				<QRCode
					style={{ marginBottom: 16 }}
					errorLevel="H"
					value={wallet}
				/>
			</Row>
		</Container>
	);
};
const mapStateToProps = state => ({
	wallet: getUser(state).address,
})
export default connect(mapStateToProps)(Receive);
