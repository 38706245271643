import React, { useCallback, useState } from 'react';
import { Modal, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {calculateFees} from "../store/actions/trades";
import {formatNumberPrecision} from "../utils/helpers";
import {TRANSACTION_TYPE} from "../utils/constants";

const useTransaction = (user, onSubmit, form) => {
    const [loading, setLoading] = useState(false);

    const onFormSubmitSuccess = useCallback((tType, tCurrency, tAmount, {wallet = '', fee_use_eur = false} = {}) => {
        setLoading(true);
        calculateFees(tType, tCurrency, tAmount, fee_use_eur)
            .then(({ fee, fee_eur, fee_crypto }) => {
                const currencyFee = (tType === TRANSACTION_TYPE.BUY || fee_use_eur) ? fee_eur : fee_crypto,
                    totalAmount = formatNumberPrecision(
                    Number(tAmount) + (Number(tAmount) * (+fee ?? 0)) / 100 + currencyFee,
                    2
                ), compareBalance = (tType === TRANSACTION_TYPE.BUY ? user.balance_fiat : user.balance_usdt) ?? 0,
                    hasEnoughBalance = totalAmount <= (compareBalance ?? 0);

                if (hasEnoughBalance) {
                    const confirmModal = Modal.confirm({
                        title: 'Confirmation of Transaction',
                        icon: <ExclamationCircleFilled />,
                        content: (
                            <Typography.Paragraph>
                                The total cost of the transaction, including the fee, will be <Typography.Text strong>{totalAmount} {tCurrency}</Typography.Text>. Do you want to proceed?
                            </Typography.Paragraph>
                        ),
                        onOk() {
                            return onSubmit(tCurrency, tAmount, wallet)
                                .finally(() => {
                                    form?.resetFields();
                                    confirmModal.destroy();
                                });
                        },
                        onCancel() {},
                        okButtonProps: {
                            type: 'text'
                        },
                        cancelButtonProps: {
                            type: 'text'
                        },
                        cancelText: 'Cancel',
                        okText: 'Confirm',
                    });
                } else {
                    Modal.warning({
                        title: 'Insufficient Funds',
                        content: (
                            <Typography.Paragraph>
                                You do not have enough funds to perform this operation. You need <Typography.Text strong>{totalAmount} {tCurrency}</Typography.Text>.
                            </Typography.Paragraph>
                        ),
                        onOk() {
                            form?.resetFields();
                        },
                        okButtonProps: {
                            type: 'text'
                        },
                        okText: 'Cancel',
                    });
                }
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            })
    }, [user, onSubmit]);

    return { onFormSubmitSuccess, loading };
};

export default useTransaction;
