import React from 'react';
import classes from './Container.module.scss';

const Container = ({children, className, ...props}) => {
	return (
		<div className={`${classes.container} ${className ? className : ''}`} {...props}>
			{children}
		</div>
	);
};

export default Container;
