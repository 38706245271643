import React, {forwardRef, useCallback} from 'react';
import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, Radio, Row, Select, Space} from "antd";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../../schemas";
import moment from "moment/moment";
import classes from "../Survey.module.scss";
import {IS_DEV} from "../../../utils/constants";

const defaultInitialValues = {
    taxCountry: 'RU',
    taxNumber: '12312',
    noTIN: true,
    isUSTaxpayer: false,
}

const TaxSection = forwardRef(({countries, initialValues, goBackStep, footer, formDataInit, resetStep, ...props}, ref) => {
    const [form] = Form.useForm();

    const resetFields = () => form.resetFields();
    const resetForm = () => {
        resetFields();
        resetStep()
    }

    return (
        <Form ref={ref} initialValues={formDataInit ? formDataInit : initialValues ? initialValues : IS_DEV && defaultInitialValues} validateMessages={validateMessages} layout="vertical" size="large" form={form} validateTrigger="onChange" name="step2">
            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item label="Tax Residence Country" rules={GENERAL_SCHEME.required} name="taxCountry">
                        <Select placeholder="Tax Residence Country" options={countries.map(country => ({
                            value: country.cca2,
                            label: <div className={classes.countryOptionRow}><img src={country.flags.svg} className={classes.flagIcon} alt="" /> {country.name.common}</div>
                        }))} filterOption={(input, option) =>
                            option.label.props.children[2].toLowerCase().startsWith(input.toLowerCase())
                        } allowClear showSearch />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Taxpayer Identification Number" rules={[...GENERAL_SCHEME.numeric, ...GENERAL_SCHEME.required]} name="taxNumber">
                        <Input placeholder="Taxpayer Identification Number" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item className="no-margin-bottom" valuePropName="checked" label="" name="noTIN">
                        <Checkbox>Country doesn't issue TIN</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item rules={GENERAL_SCHEME.required} label="Is Client a US Taxpayer?" name="isUSTaxpayer">
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            {footer ? footer({ form }) : (
                <Row justify="end">
                    <Space wrap="wrap">
                        <Button type="text" onClick={resetForm}>
                            Reset
                        </Button>
                        {/*<Button onClick={}>*/}
                        {/*    Back*/}
                        {/*</Button>*/}
                        <Button onClick={goBackStep}>
                            Prev
                        </Button>
                        <Button onClick={form.submit}>
                            Next
                        </Button>
                    </Space>
                </Row>
            )}
        </Form>
    );
});

export default TaxSection;
