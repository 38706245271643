import {NEW_ENDPOINTS} from "../../../http/endpoints";
import {API} from "../../../http";
import {getAccountMetaInfo, getUserFullInfo, getUserInfo} from "../../../store/actions/user";
import {message} from "antd";
import {getTradeInfo} from "../../../store/actions/trades";
import {batch} from "react-redux";

export const login = ({login, password}) => async (dispatch) => {
	try {
		const {data} = await API.post(NEW_ENDPOINTS.AUTH.LOGIN, {login, password});
		if (data.token) {
			// success login
			API.setKey(data.token);

			const userInfo = await dispatch(getUserInfo({ toState: false }));
			const metaInfo = await dispatch(getAccountMetaInfo({ toState: false }));

			dispatch({type: 'User/SET', payload: {email: login, authKey: data.token, ...userInfo, meta: metaInfo}});
			try {
				dispatch(getTradeInfo());
			} catch (tradeError) {
				console.error("Error fetching trade info:", tradeError);
			}
		} else {
			switch (data.err) {
				case 'BADPASS':
					throw new Error('Login or password is invalid');
				case 'NO USER':
					throw new Error('User doesn`t exist');
				default:
					throw new Error('Something went wrong');
			}
		}
	} catch (e) {
		throw e;
	}
}
