import {useCallback, useEffect, useState} from "react";

export const useTimer = (initialValue, interval) => {
	const [timeLeft, setTimeLeft] = useState(initialValue);

	useEffect(() => {
		if (timeLeft <= 0) return;

		const timerId = setInterval(() => {
			setTimeLeft((prevTimeLeft) => prevTimeLeft - interval);
		}, interval * 1000);

		return () => {
			clearInterval(timerId);
		};
	}, [timeLeft, interval]);

	const resetTimer = useCallback(() => {
		setTimeLeft(initialValue);
	}, [initialValue]);

	return [timeLeft, resetTimer];
};
