import {Form, Image, Select, Space, Typography} from "antd";
import React from "react";

const CurrencySelector = ({name, arr}) => {
	return (
		<Form.Item name={name} style={{ marginBottom: 0 }}>
			<Select style={{width: 160}} options={arr.map(i => ({
				label: (
					<Space align="center">
						<Typography.Text>{i.label}</Typography.Text>
						<Image preview={false} width={32} height={32} src={i.src} alt="currency"/>
					</Space>
				),
				value: i.value,
			}))}/>
		</Form.Item>
	)
}

export default CurrencySelector;
